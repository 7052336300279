import { financeRequest } from '../../utils/request'

class FinanceCalculatorApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      rvCalculation: '/rv/calculation',
      termsCalculation: '/loan-offers/calculation/terms',
      termCalculation: (term) => `/loan-offers/calculation/terms/${term}`,
      loanOffersCalculation: '/loan-offers/calculation/offer',
    }
    this.#abortControllers = {}
  }

  calculateRv = async (data, prefix = 'calculateRV') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.post(this.#endPoints.rvCalculation, data, {
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  calculateTerms = async (data, prefix = 'calculateTerms') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.post(this.#endPoints.termsCalculation, data, {
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }

  calculateTerm = async (term, data, prefix = 'calculateTerm') => {
    const termPrefix = `${prefix}-${term}`
    this.#abortControllers[termPrefix]?.abort()
    this.#abortControllers[termPrefix] = new AbortController()
    const response = await this.#api.post(this.#endPoints.termCalculation(term), data, {
      signal: this.#abortControllers[termPrefix]?.signal,
    })
    return response
  }

  calculateOffer = async (data, prefix = 'calculateOffer') => {
    this.#abortControllers[prefix]?.abort()
    this.#abortControllers[prefix] = new AbortController()
    const response = await this.#api.post(this.#endPoints.loanOffersCalculation, data, {
      signal: this.#abortControllers[prefix]?.signal,
    })
    return response
  }
}

const financeCalculatorApiService = new FinanceCalculatorApiService({ api: financeRequest })

export default financeCalculatorApiService
