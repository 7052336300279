import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import financeCalculatorApiService from '../../../api/financeCalculatorApiService/financeCalculatorApiService'

export const calculateRV = createAsyncThunk(
  'financing/calculateRV',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await financeCalculatorApiService.calculateRv(data)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const calculateTerms = createAsyncThunk(
  'financing/calculateTerms',
  async ({ terms, data }, { rejectWithValue }) => {
    try {
      const termsResponses = await Promise.all(
        terms.map((term) => {
          return financeCalculatorApiService.calculateTerm(term, data)
        }),
      )
      const termsData = termsResponses.map((res) => res.data)
      return termsData
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const calculateCustomPackageTerms = createAsyncThunk(
  'financing/calculateCustomPackageTerms',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await financeCalculatorApiService.calculateOffer(data)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
