export const CAR_WORKFLOW_NAMESPACE = {
  CAR_WORKFLOW: 'car_workflow',
  AUCTION_WORKFLOW: 'auction_workflow',
  DEAL_WORKFLOW: 'deal_workflow',
}

export const policyWorkflowStates = {
  new: 'new',
  linked_to_dealer: 'linked_to_dealer',
  restitution_started: 'restitution_started',
  restitution_in_progress: 'restitution_in_progress',
  closed: 'closed',
  closed_due_to_end_of_contract: 'closed_due_to_end_of_contract',
  cancelled: 'cancelled',
  closed_by_dealer: 'closed_by_dealer',
}

export const policyWorkflowStateNames = {
  [policyWorkflowStates.new]: 'New',
  [policyWorkflowStates.linked_to_dealer]: 'Ready for restitution',
  [policyWorkflowStates.restitution_started]: 'Requested restitution',
  [policyWorkflowStates.restitution_in_progress]: 'Restitution in progress',
  [policyWorkflowStates.closed]: 'Closed',
  [policyWorkflowStates.cancelled]: 'Cancelled',
}

export const policyWorkflowStateNameKeys = {
  [policyWorkflowStates.new]: 'policy_workflow_new',
  [policyWorkflowStates.linked_to_dealer]: 'policy_workflow_linked_to_dealer',
  [policyWorkflowStates.restitution_started]: 'policy_workflow_restitution_started',
  [policyWorkflowStates.restitution_in_progress]: 'policy_workflow_restitution_in_progress',
  [policyWorkflowStates.closed]: 'policy_workflow_closed',
  [policyWorkflowStates.closed_due_to_end_of_contract]:
    'policy_workflow_closed_due_to_end_of_contract',
  [policyWorkflowStates.cancelled]: 'policy_workflow_cancelled',
  [policyWorkflowStates.closed_by_dealer]: 'Closed by dealer',
}
