import { adminRequest } from '../../utils/request'

class ProductProvidersApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      list: '/reference/product-provider/product-providers',
    }
  }

  getDealerProducts = async (params) => {
    const response = await this.#api.get(this.#endPoints.list, {
      params,
    })
    return response
  }
}

const productProvidersApiService = new ProductProvidersApiService({ api: adminRequest })

export default productProvidersApiService
