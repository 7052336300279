export const fileTypeEnums = {
  foreshortening: 'foreshortening',
  pilot_lamp: 'pilot_lamp',
  damage: 'damage',
  control_point_document: 'control_point_document',
  avatar: 'avatar',
  trade_license: 'trade_license',
  car_appraisal_pdf_result: 'car_appraisal_pdf_result',
  invoice: 'invoice',
  customer_file: 'customer_file',
  loan_deal_attachment: 'loan_deal_attachment',
  insurance_deal_attachment: 'insurance_deal_attachment',
  warranty_certificate: 'warranty_certificate',
  rvi_policy_import: 'rvi_policy_import',
  rvi_policy_restitution_details_vehicle_registration_card:
    'rvi_policy_restitution_details_vehicle_registration_card',
  rvi_policy_restitution_details_appraisal_document:
    'rvi_policy_restitution_details_appraisal_document',
  rvi_policy_restitution_details_sales_photo: 'rvi_policy_restitution_details_sales_photo',
  default: 'default',
}

export const vehicleExpertiseEnums = {
  dealership: 'dealership',
  expertCenter: 'expertCenter',
}
export const vehicleExpertiseNames = {
  [vehicleExpertiseEnums.dealership]: 'Dealership',
  [vehicleExpertiseEnums.expertCenter]: 'Expert Center',
}

export const CAR_ENUMS = {
  CAR_BODY: 'car_body',
  CAR_TYPE: 'car_type',
  CAR_DRIVE: 'car_drive',
  CAR_ENGINE: 'car_engine',
  CAR_EURO_NORM: 'car_euro_norm',
  CAR_GEARBOX: 'car_gearbox',
  CAR_ORIGIN: 'car_origin',
  CAR_RIMS: 'car_rims',
  CAR_STATE: 'car_state',
  CAR_UPHOLSTERY: 'car_upholstery',
}

export const CALCULATION_METHOD = {
  CALCULATION_PRICE_METHOD_UNSPECIFIED: 'CALCULATION_PRICE_METHOD_UNSPECIFIED',
  CALCULATION_PRICE_METHOD_NET: 'CALCULATION_PRICE_NET',
  CALCULATION_PRICE_METHOD_GROSS: 'CALCULATION_PRICE_METHOD_GROSS',
}

export const CREDIT_TYPE = {
  CREDIT_TYPE_UNSPECIFIED: 'CREDIT_TYPE_UNSPECIFIED',
  CREDIT_TYPE_CLASSICAL: 'CREDIT_TYPE_CLASSICAL',
  CREDIT_TYPE_BALLOON: 'CREDIT_TYPE_BALLOON',
}

export const DEALER_PRODUCT_CLASSIFICATION = {
  DEALER_PRODUCT_CLASSIFICATION_UNSPECIFIED: 'DEALER_PRODUCT_CLASSIFICATION_UNSPECIFIED',
  DEALER_PRODUCT_CLASSIFICATION_CASCO: 'DEALER_PRODUCT_CLASSIFICATION_CASCO',
  DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY:
    'DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY',
  DEALER_PRODUCT_CLASSIFICATION_PPI: 'DEALER_PRODUCT_CLASSIFICATION_PPI',
}
