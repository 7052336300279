import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCustomerAppraisal } from '../../../store/features/customerAppraisal/customerAppraisal.actions'
import UIModal from '../../../ui/UIModal/UIModal'
import { Stack } from '@mui/material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'

const DeleteCustomerAppraisalModal = ({ open, setOpen, data, onClose }) => {
  const isLoading = useSelector((state) => state.customerAppraisal.delete.isLoading)
  const dispatch = useDispatch()

  const handleDelete = async () => {
    const res = await dispatch(deleteCustomerAppraisal({ customerAppraisalId: data?.id }))
    if (deleteCustomerAppraisal.fulfilled.match(res)) {
      if (onClose) onClose()
      setOpen(false)
    }
  }

  return (
    <UIModal open={open} setOpen={setOpen}>
      {isLoading && <UIPreloader text="Deleting appraisal..." />}
      <Stack gap="30px">
        <Stack gap="12px">
          <UITextHeadlineSmall
            text="Delete customer appraisal"
            sx={{ pr: { xs: '30px', md: 0 } }}
          />
          <UITextRegular text="Are you sure you want to permanently delete this customer appraisal?" />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} gap="10px" sx={{ '& button': { flex: 1 } }}>
          <ButtonHollow onClick={() => setOpen(false)}>Cancel</ButtonHollow>
          <ButtonDanger disabled={isLoading} onClick={() => handleDelete()}>
            Delete
          </ButtonDanger>
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default DeleteCustomerAppraisalModal
