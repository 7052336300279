import React, { useEffect, useState } from 'react'
import { Box, IconButton, Modal } from '@mui/material'
import { Close } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import TextFieldSmall from '../../ui/TextFieldSmall'
import ButtonAttention from '../../ui/Buttons/ButtonAttention'
import IconDoubleArrows from '../../assests/img/icons/icon-double-arrows.svg'
import Preloader from '../Preloader'

const NotEnoughFleetifyCoins = (props) => {
  const { open, setOpen, handleSubmit, generatingInvoice, title, description } = props

  const [coinsAmount, setCoinsAmount] = useState(50)
  const [localCurrencyAmount, setLocalCurrencyAmount] = useState(200)

  const handleSetLocalCurrencyAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setLocalCurrencyAmount(coinsAmount * 4)
    }
  }

  const handleSetCoinsAmount = () => {
    if (coinsAmount * 4 !== localCurrencyAmount) {
      setCoinsAmount(localCurrencyAmount / 4)
    }
  }

  useEffect(() => {
    handleSetLocalCurrencyAmount()
  }, [coinsAmount])

  useEffect(() => {
    handleSetCoinsAmount()
  }, [localCurrencyAmount])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 650,
          background: colorsConfig.bgLightBlue,
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        className={'disable-scrollbar'}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <TextH1
          sx={{
            mb: '16px',
          }}
        >
          {title ? title : 'Not enough Fleetify Coins'}
        </TextH1>
        <TextNormal
          sx={{
            mb: '30px',
          }}
        >
          {description ? description : 'Purchase Fleetify Coins in order to use that service'}
          <br />
          Or learn how to <span style={{ color: colorsConfig.mainBlue }}>
            Earn Fleetify Coins
          </span>{' '}
          for free
        </TextNormal>
        <TextBoldNormal
          sx={{
            mb: '16px',
          }}
        >
          Enter Amount 1 FC = 4 AED
        </TextBoldNormal>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '31px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '30px',
              height: '21px',
              top: '50%',
              mt: '-10.5px',
              left: '205px',
            }}
          >
            <img src={IconDoubleArrows} alt="" />
          </Box>
          <Box
            sx={{
              mr: '70px',
              position: 'relative',
            }}
          >
            <TextFieldSmall
              type="text"
              value={coinsAmount}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setCoinsAmount(e.target.value)
              }}
              sx={{
                width: '168px',
              }}
            />
            <TextNormal
              sx={{
                color: colorsConfig.iconGray,
                fontSize: '14px',
                position: 'absolute',
                top: '9px',
                right: '11px',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  height: '16px',
                  width: '1px',
                  background: colorsConfig.lightGray,
                  left: '-10px',
                  top: '50%',
                  mt: '-8px',
                },
              }}
            >
              FC
            </TextNormal>
          </Box>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <TextFieldSmall
              value={localCurrencyAmount}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                setLocalCurrencyAmount(e.target.value)
              }}
              type="text"
              sx={{
                width: '168px',
              }}
            />
            <TextNormal
              sx={{
                color: colorsConfig.iconGray,
                fontSize: '14px',
                position: 'absolute',
                top: '9px',
                right: '11px',
                '&::after': {
                  content: '" "',
                  display: 'block',
                  position: 'absolute',
                  height: '16px',
                  width: '1px',
                  background: colorsConfig.lightGray,
                  left: '-10px',
                  top: '50%',
                  mt: '-8px',
                },
              }}
            >
              AED
            </TextNormal>
          </Box>
        </Box>
        <TextNormal
          sx={{
            fontSize: '14px',
            color: colorsConfig.lightGray,
            mb: '18px',
          }}
        >
          Wait until Fleeetify coins are transferred to your account (up to 24 hours after payment)
        </TextNormal>
        <ButtonAttention
          fullWidth
          disabled={!coinsAmount || !localCurrencyAmount || generatingInvoice}
          onClick={() => {
            handleSubmit(coinsAmount)
          }}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {generatingInvoice ? 'Generating invoice...' : 'Get invoice'}
          {generatingInvoice ? <Preloader type={'gooey'} /> : null}
        </ButtonAttention>
      </Box>
    </Modal>
  )
}

export default NotEnoughFleetifyCoins
