import React, { useState, useEffect } from 'react'
import { Box, Button, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colorsConfig } from '../../config/themeConfig'
import IconAppraisal from '../../assests/img/icons/icon-appraisal.svg'
import IconAuctions from '../../assests/img/icons/icon-auctions.svg'
import IconFinance from '../../assests/img/icons/icon-financing.svg'
import IconInsurance from '../../assests/img/icons/icon-insurance.svg'
import IconWarranty from '../../assests/img/icons/icon-warranty.svg'
import IconCarSubscription from '../../assests/img/icons/icon-car-subscription.svg'
import IconCheckmarkBlack from '../../assests/img/icons/icon-checkmark-black.svg'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { API_ENDPOINTS, MERCURE_BASE_URL } from '../../config/default'
import SendToOurCompoundHint from './SendToOurCompoundHint'
import { Close } from '@mui/icons-material'
import SendToCompound from '../SendToCompound'
import CarSteps from './CarSteps'
import CarActionButtons from './CarActionButtons'
import StartAuctionModal from './StartAuctionModal'
import CarActionInfoBlock from './CarActionInfoBlock'
import { privateRequest } from '../../utils/request'
import SubmitToQualityCheckConfirmModal from '../VirtualGarage/SubmitToQualityCheckConfirmModal'
import { setWorkflowState } from '../../store/features/carSlice/carSlice'
import { setBalance } from '../../store/features/authSlice'
import { toast } from 'react-toastify'
import { EventSourcePolyfill } from 'event-source-polyfill'
import ApplyForQualityCheckModal from '../../components/Modals/ApplyForQualityCheckModal'
import { downloadFileFromPrivateBucket } from '../../utils/downloadFile'
import TextH1 from '../../ui/Text/TextH1'
import ButtonDanger from '../../ui/Buttons/ButtonDanger'
import ButtonSuccess from '../../ui/Buttons/ButtonSuccess'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import ServiceHintAppraisalModal from '../../components/Modals/ServiceHintAppraisalModal'
import ServiceHintContinueAppraisalModal from '../../components/Modals/ServiceHintContinueAppraisalModal'
import ServiceHintQualityCheckModal from '../../components/Modals/ServiceHintQualityCheckModal'
import ServiceHintInfoModal from '../../components/Modals/ServiceHintInfoModal'
import LoanApplications from './DealerFinancing/LoanApplications'
import RequestCoinsModal from '../../components/Modals/RequestCoinsModal'
import CarInsuranceApplications from '../Insurance/CarInsurance/CarInsuranceApplications'
import CarWarrantyApplications from '../Warranty/CarWarranty/CarWarrantyApplications'
import { REGION_LOCALE, COMPANY_NAME } from '../../utils/constants/global.constants'
import { mercureTopics } from '../../api/mercure.constants'

const CarActionBlock = (props) => {
  const { activeTab, setActiveTab } = props

  const largeOnly = useMediaQuery('(min-width: 1350px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)
  const carData = useSelector((state) => state.car.carData)
  const mercureToken = useSelector((state) => state.auth.mercureToken)
  const carAppraisalChecksResource = useSelector((state) => state.car.carAppraisalChecksResource)

  const dispatch = useDispatch()

  const [servicesTabs, setServicesTabs] = useState([])
  const [sendToCompoundModal, setSendToCompoundModal] = useState(false)
  const [startAuctionModal, setStartAuctionModal] = useState(false)
  const [sendToCompoundBlock, setSendToCompoundBlock] = useState(false)
  const [currentAuction, setCurrentAuction] = useState(null)
  const [qualityCheckModal, setQualityCheckModal] = useState(false)
  const [notEnoughCoinsModal, setNotEnoughCoinsModal] = useState(false)
  const [qualityCheckFailedModal, setQualityCheckFailedModal] = useState(false)
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const [sellModal, setSellModal] = useState(false)
  const [doNotSellModal, setDoNotSellModal] = useState(false)
  const [serviceHintAppraisalModal, setServiceHintAppraisalModal] = useState(false)
  const [serviceHintContinueAppraisalModal, setServiceHintContinueAppraisalModal] = useState(false)
  const [serviceHintQualityCheckModal, setServiceHintQualityCheckModal] = useState(false)
  const [serviceHintAwaitQualityCheckModal, setServiceHintAwaitQualityCheckModal] = useState(false)
  const [serviceHintAwaitCompoundAppraisalModal, setServiceHintAwaitCompoundAppraisalModal] =
    useState(false)

  const displaySendToCompoundComponent =
    combinedStatus === 'car_workflow.new' ||
    combinedStatus === 'car_workflow.appraisal_on_site' ||
    combinedStatus === 'car_workflow.moderation_failed' ||
    combinedStatus === 'car_workflow.requested_appraisal_compound' ||
    combinedStatus === 'car_workflow.requested_appraisal_compound_failed'

  const hideAdditionalBlocks = activeTab && activeTab >= 1

  const toggleSendToCompoundBlock = () => {
    setSendToCompoundBlock(!sendToCompoundBlock)
  }

  let pdfFileId = null

  const services = [
    {
      id: 1,
      name: 'service_appraisal',
      icon: IconAppraisal,
      active: [
        'car_workflow.new',
        'car_workflow.appraisal_on_site',
        'car_workflow.quality_check',
        'car_workflow.moderation_failed',
        'car_workflow.requested_appraisal_compound',
        'car_workflow.awaiting_appraisal_compound',
        'car_workflow.appraisal_compound',
        'car_workflow.requested_appraisal_compound_failed',
      ],
      completed: [
        'car_workflow.ready_for_sale',
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
        'deal_workflow.pending',
        'deal_workflow.invoice_sent',
        'deal_workflow.money_received',
        'deal_workflow.money_payed',
        'deal_workflow.transportation_in_progress',
        'deal_workflow.cancel',
        'car_workflow.sale_succeed',
      ],
      serviceHints: [],
    },
    {
      id: 2,
      name: 'service_auction',
      icon: IconAuctions,
      disabled: true,
      active: [
        'car_workflow.ready_for_sale',
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
      ],
      completed: [
        'deal_workflow.pending',
        'deal_workflow.pending',
        'deal_workflow.money_received',
        'deal_workflow.money_payed',
        'deal_workflow.transportation_in_progress',
        'deal_workflow.invoice_sent',
        'deal_workflow.cancel',
        'car_workflow.sale_succeed',
      ],
      keepOpened: [
        'deal_workflow.pending',
        'deal_workflow.money_received',
        'deal_workflow.money_payed',
        'deal_workflow.invoice_sent',
        'deal_workflow.transportation_in_progress',
        'deal_workflow.cancel',
        'car_workflow.sale_succeed',
      ],
      serviceHints: [
        {
          modalName: 'hint-appraisal',
          allowStatuses: ['car_workflow.new'],
        },
        {
          modalName: 'hint-continue-appraisal',
          allowStatuses: ['car_workflow.appraisal_on_site'],
          carAppraisalChecksResource: 'not-completed',
        },
        {
          modalName: 'hint-await-appraisal',
          allowStatuses: [
            'car_workflow.requested_appraisal_compound',
            'car_workflow.awaiting_appraisal_compound',
            'car_workflow.appraisal_compound',
            'car_workflow.requested_appraisal_compound_failed',
          ],
        },
        {
          modalName: 'hint-await-quality-check',
          allowStatuses: ['car_workflow.quality_check'],
        },
        {
          modalName: 'hint-quality-check',
          allowStatuses: ['car_workflow.appraisal_on_site', 'car_workflow.moderation_failed'],
          carAppraisalChecksResource: 'completed',
        },
      ],
    },
    {
      id: 3,
      name: 'service_finance',
      icon: IconFinance,
      disabled: true,
      active: [
        'car_workflow.new',
        'car_workflow.appraisal_on_site',
        'car_workflow.requested_appraisal_compound',
        'car_workflow.requested_appraisal_compound_failed',
        'car_workflow.awaiting_appraisal_compound',
        'car_workflow.appraisal_compound',
        'car_workflow.moderation_failed',
        'car_workflow.quality_check',
        'car_workflow.ready_for_sale',
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
      ],
      completed: [],
    },
    {
      id: 4,
      name: 'service_insurance',
      icon: IconInsurance,
      disabled: true,
      active: [
        'car_workflow.new',
        'car_workflow.appraisal_on_site',
        'car_workflow.requested_appraisal_compound',
        'car_workflow.requested_appraisal_compound_failed',
        'car_workflow.awaiting_appraisal_compound',
        'car_workflow.appraisal_compound',
        'car_workflow.moderation_failed',
        'car_workflow.quality_check',
        'car_workflow.ready_for_sale',
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
      ],
      completed: ['car_workflow.ready_for_auction'],
    },
    {
      id: 5,
      name: 'service_warranty',
      icon: IconWarranty,
      disabled: true,
      active: [
        'car_workflow.new',
        'car_workflow.appraisal_on_site',
        'car_workflow.requested_appraisal_compound',
        'car_workflow.requested_appraisal_compound_failed',
        'car_workflow.awaiting_appraisal_compound',
        'car_workflow.appraisal_compound',
        'car_workflow.moderation_failed',
        'car_workflow.quality_check',
        'car_workflow.ready_for_sale',
        'auction_workflow.pending',
        'auction_workflow.active',
        'auction_workflow.completed',
      ],
      completed: [],
    },
    {
      id: 6,
      name: 'service_car_subscription',
      icon: IconCarSubscription,
      disabled: true,
      active: ['car_workflow.ready_for_sale'],
      completed: ['car_workflow.ready_for_auction'],
    },
  ]

  const appraisalBlock = {
    steps: [
      {
        id: 1,
        name: 'appraisal_step_title_new_vehicle',
        key: '',
        inactive: [],
        description: 'appraisal_step_p_new_vehicle',
      },
      {
        id: 2,
        name: 'appraisal_step_title_appraisal',
        inactive: ['car_workflow.new'],
        description: 'appraisal_step_p_appraisal',
      },
      {
        id: 3,
        name: 'appraisal_step_title_quality_check',
        inactive: [
          'car_workflow.new',
          'car_workflow.appraisal_on_site',
          'car_workflow.requested_appraisal_compound',
          'car_workflow.moderation_failed',
        ],
        description: 'appraisal_step_p_quality_check',
      },
      {
        id: 4,
        name: 'appraisal_step_title_ready_to_take_off',
        inactive: [
          'car_workflow.new',
          'car_workflow.appraisal_on_site',
          'car_workflow.quality_check',
          'car_workflow.moderation_failed',
          'car_workflow.requested_appraisal_compound',
        ],
        description: 'appraisal_step_p_ready_to_take_off',
      },
    ],
    buttons: [
      {
        id: 1,
        type: 'hollow',
        name: 'button_start_appraisal_on_site',
        workflows: ['car_workflow.new'],
        actions: [
          // {
          //                     //     type: 'workflowTransition',
          //                     //     link: `/cars/${params.id}${API_ENDPOINTS.startOnSiteAppraisal}`
          //                     // },
          {
            type: 'navigate',
            link: `/cars/${params.id}/appraisal`,
          },
        ],
      },
      // {
      //     id: 2,
      //     type: 'standard',
      //     name: 'Edit appraisal',
      //     workflows: ['car_workflow.appraisal_on_site', 'car_workflow.moderation_failed'],
      //     actions: [
      //         {
      //             type: 'navigate',
      //             link: `/cars/${params.id}/appraisal`
      //         }
      //     ]
      // },
      {
        id: 4,
        type: 'hollow-regular',
        name: 'button_submit_to_quality_check',
        workflows: ['car_workflow.appraisal_on_site'],
        specialConditions: 'carAppraisalChecksResource',
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setQualityCheckModal(true)
            },
          },
          // {
          //     type: 'workflowTransition',
          //     link: `/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`,
          //     successMessage: 'The car has been submitted to Quality Check successfully'
          // },
          // {
          //     type: 'localWorkflowTransition',
          //     link: `car_workflow.quality_check`
          // }
        ],
      },
      {
        id: 5,
        type: 'hollow-regular',
        name: 'button_submit_to_quality_check',
        workflows: ['car_workflow.moderation_failed'],
        actions: [
          // {
          //     type: 'workflowTransitionMulti',
          //     link: `/cars/${params.id}${API_ENDPOINTS.startOnSiteAppraisal}`,
          //     link2: `/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`,
          //     localTransition: 'car_workflow.quality_check',
          //     successMessage: 'The car has been submitted to Quality Check successfully'
          // }
          {
            type: 'modal',
            openModal: () => {
              setQualityCheckFailedModal(true)
            },
          },
        ],
      },
      {
        id: 5,
        type: 'pdf',
        name: 'button_download_appraisal_as_pdf',
        workflows: [
          'car_workflow.appraisal_on_site',
          'car_workflow.quality_check',
          'car_workflow.moderation_failed',
          'car_workflow.ready_for_sale',
          'car_workflow.sale_succeed',
          'auction_workflow.pending',
          'auction_workflow.active',
          'auction_workflow.completed',
          'deal_workflow.pending',
          'deal_workflow.money_payed',
          'deal_workflow.money_received',
          'deal_workflow.invoice_sent',
          'deal_workflow.transportation_in_progress',
        ],
        actions: [
          {
            type: 'pdf',
            handler: () => {
              handleDownloadPDF()
            },
          },
        ],
      },
      {
        id: 7,
        type: 'attention',
        name: 'button_send_to_our_compound',
        workflows: [
          'car_workflow.appraisal_on_site',
          'car_workflow.moderation_failed',
          'car_workflow.new',
        ],
        infoBlock: <SendToOurCompoundHint />,
        actions: [
          {
            type: 'modal',
            openModal: () => {
              toggleSendToCompoundBlock()
            },
          },
        ],
      },
      {
        id: 8,
        type: 'attention-disabled',
        name: 'button_send_to_our_compound',
        workflows: ['car_workflow.requested_appraisal_compound'],
      },
    ],
  }

  const auctionBlock = {
    steps: [
      {
        id: 1,
        name: 'Preparing auction',
        inactive: [],
        description:
          'To begin the appraisal process, either initiate it yourself or send your vehicle to our compound for a thorough appraisal and quality check. This will move you closer to obtaining verification for your car',
      },
      {
        id: 2,
        name: 'Auction started',
        inactive: ['car_workflow.ready_for_sale', 'auction_workflow.pending'],
        description:
          "Appraisal helps buyers get a sense of the vehicle's value and put them in a more informed position",
      },
      {
        id: 3,
        name: 'Auction ended',
        inactive: [
          'car_workflow.ready_for_sale',
          'auction_workflow.pending',
          'auction_workflow.active',
        ],
        description:
          'The quality check enables us to identify any issues before the vehicle is marketed to potential buyers',
      },
      {
        id: 4,
        name: 'Car sold',
        inactive: [
          'car_workflow.ready_for_sale',
          'auction_workflow.pending',
          'auction_workflow.active',
          'auction_workflow.completed',
        ],
        description: 'Appraisal and quality check are done, now you can use our services',
      },
    ],
    buttons: [
      {
        id: 1,
        type: 'hollow',
        name: 'Start auction',
        workflows: ['car_workflow.ready_for_sale'],
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setStartAuctionModal(true)
            },
          },
        ],
      },
      {
        id: 2,
        type: 'danger',
        name: "Don't sell",
        workflows: ['auction_workflow.completed'],
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setDoNotSellModal(true)
            },
          },
          // {
          //     type: 'workflowChange',
          //     link: `/auctions/${currentAuction?.id}${API_ENDPOINTS.doNotSellCar}`,
          //     localChange: 'car_workflow.ready_for_sale',
          //     successMessage: 'You have cancelled the sale. You can try to auction your car again or choose another service'
          // }
        ],
        specialConditions: 'bids',
      },
      {
        id: 3,
        type: 'success',
        name: 'Sell',
        workflows: ['auction_workflow.completed'],
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setSellModal(true)
            },
          },
          // {
          //     type: 'workflowChange',
          //     link: `/auctions/${currentAuction?.id}${API_ENDPOINTS.sellCar}`,
          //     successMessage: 'You sold your car! The buyer will proceed with the payment and the money will be credited to you within 2 business days',
          //     localChange: "deal_workflow.pending"
          // }
        ],
        specialConditions: 'bids',
      },
      {
        id: 4,
        type: 'hollow',
        name: 'Reschedule',
        workflows: ['auction_workflow.pending'],
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setStartAuctionModal(true)
            },
          },
        ],
      },
      // {
      //     id: 5,
      //     type: 'danger',
      //     name: 'Cancel',
      //     workflows: ['auction_workflow.pending'],
      //     actions: [
      //
      //     ]
      // },
      {
        id: 6,
        type: 'hollow',
        name: 'Restart auction',
        workflows: ['auction_workflow.completed'],
        actions: [
          {
            type: 'modal',
            openModal: () => {
              setStartAuctionModal(true)
            },
          },
        ],
        specialConditions: 'no-bids',
      },
      {
        id: 7,
        type: 'hollow',
        name: 'Return to virtual garage',
        workflows: ['auction_workflow.completed'],
        actions: [
          {
            type: 'workflowChange',
            link: `/auctions/${currentAuction?.id}${API_ENDPOINTS.doNotSellCar}`,
            localChange: 'car_workflow.ready_for_sale',
            successMessage:
              'You have cancelled the sale. You can try to auction your car again or choose another service',
          },
        ],
        specialConditions: 'no-bids',
      },
      {
        id: 8,
        type: 'hollow',
        name: 'Return car to Virtual Garage',
        workflows: ['deal_workflow.cancel'],
        actions: [
          {
            type: 'workflowChange',
            link: `/cars/${params.id}${API_ENDPOINTS.resetCarToReadyForSaleFromDealCancelled}`,
            localChange: 'car_workflow.ready_for_sale',
            successMessage: 'The car is now ready for action again',
          },
        ],
      },
      {
        id: 9,
        type: 'danger',
        name: 'Cancel the auction',
        workflows: ['auction_workflow.pending'],
        actions: [
          {
            type: 'workflowChange',
            link: `/auctions/${currentAuction?.id}${API_ENDPOINTS.cancelAuction}`,
            localChange: 'car_workflow.ready_for_sale',
            successMessage: 'The car is now ready for action again',
          },
        ],
      },
    ],
  }

  const financeBlock = {
    steps: [
      {
        id: 1,
        name: 'Provide loan information',
        inactive: [],
        description:
          'To begin the financing process, calculate the loan and enter customer data, then send it to banks.',
      },
      {
        id: 2,
        name: 'Receive bank response',
        inactive: [],
        description: 'Receive bank response and choose the best deals for your client.',
      },
      {
        id: 3,
        name: 'Sign required documents',
        inactive: [],
        description:
          'After the proposal is approved, the bank will send the documents necessary for signing.',
      },
      {
        id: 4,
        name: 'Provide car to customer',
        inactive: [],
        description: '',
      },
    ],
  }

  const handleDownloadPDF = async () => {
    privateRequest.post(API_ENDPOINTS.carPdfAppraisalResult(params.id), {}).then(async () => {
      const url = mercureTopics.pdfAppraisalResult(carData?.id)
      const tokenMerc = localStorage.getItem('mercure_token')
      const eventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: 'Bearer ' + tokenMerc,
        },
      })

      setDownloadingPDF(true)

      eventSource.onmessage = function (event) {
        const { fileId } = JSON.parse(event.data)
        pdfFileId = fileId
        initiateFileDownload(pdfFileId)
        setDownloadingPDF(false)
        eventSource.close()
      }

      eventSource.onerror = function (error) {
        toast.error(error)
        setDownloadingPDF(false)
      }

      const { make, model, vin } = carData
      const fileName =
        `${make.name.replace('.', '_')}_${model.name.replace('.', '_')}_${vin}`.replace(' ', '-')

      async function initiateFileDownload(pdfFileId) {
        privateRequest.get(API_ENDPOINTS.privateFiles(pdfFileId), {}).then((response) => {
          const { signedUrl } = response.data
          downloadFileFromPrivateBucket(signedUrl, fileName)
        })
      }
    })
  }

  const handleSubmitToQualityCheck = () => {
    privateRequest
      .post(`/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`, {})
      .then(() => {
        toast.success('The car has been submitted to quality check successfully')
        dispatch(setWorkflowState('car_workflow.quality_check'))
        privateRequest.get(API_ENDPOINTS.userInfo).then((res) => {
          dispatch(setBalance(res.data.manager.company.coinBalance.balance))
        })
        setQualityCheckModal(false)
        setServiceHintQualityCheckModal(false)
      })
      .catch((error) => {
        if (error.response.data['hydra:description'] === 'Not enough coins for withdraw') {
          setQualityCheckModal(false)
          setServiceHintQualityCheckModal(false)
          setNotEnoughCoinsModal(true)
        }
      })
  }

  const handleSubmitToQualityCheckFailed = () => {
    privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.startOnSiteAppraisal}`, {}).then(() => {
      privateRequest
        .post(`/cars/${params.id}${API_ENDPOINTS.completeOnSiteAppraisal}`, {})
        .then(() => {
          toast.success('The car has been submitted to quality check successfully')
          dispatch(setWorkflowState('car_workflow.quality_check'))
          setQualityCheckFailedModal(false)
          setServiceHintQualityCheckModal(false)
        })
        .catch(() => {
          toast.error('An error occurred while submitting the car to Quality Check')
        })
    })
  }

  const isAppraisalCompleted = () => {
    if (!carAppraisalChecksResource.vehicleDetailsCheck) return false
    if (!carAppraisalChecksResource.documentsCheck) return false
    if (!carAppraisalChecksResource.photosCheck) return false
    if (!carAppraisalChecksResource.damagesCheck) return false
    if (!carAppraisalChecksResource.warningLightsCheck) return false
    if (!carAppraisalChecksResource.interiorConditionsCheck) return false
    if (!carAppraisalChecksResource.mechanicalConditionsCheck) return false
    return true
  }

  const handleSetServicesTabs = () => {
    setServicesTabs(
      services.map((item, index) => {
        let serviceStatus = null
        let keepOpened = false
        if (
          item.active.find((itemWorkflow) => itemWorkflow === combinedStatus) &&
          !item?.disabled
        ) {
          serviceStatus = 'active'
        }
        if (
          item.completed.find((itemWorkflow) => itemWorkflow === combinedStatus) &&
          !item?.disabled
        ) {
          serviceStatus = 'completed'
        }
        if (!serviceStatus) {
          serviceStatus = 'faded'
        }
        if (
          item.keepOpened &&
          item.keepOpened.filter((itemWorkflow) => itemWorkflow === combinedStatus)[0]
        ) {
          keepOpened = true
          handleSelectService(index)
        }
        return {
          id: item.id,
          name: item.name,
          icon: item.icon,
          status: serviceStatus,
          keepOpened: keepOpened,
          serviceHints: item.serviceHints ? item.serviceHints : [],
        }
      }),
    )
  }

  const handleShowServiceHint = (serviceHints) => {
    const matchServiceHint = serviceHints.filter((item) => {
      if (item.allowStatuses.filter((status) => status === combinedStatus)[0]) {
        if (!item.carAppraisalChecksResource) return true
        if (item.carAppraisalChecksResource && item.carAppraisalChecksResource === 'completed')
          return isAppraisalCompleted()
        if (item.carAppraisalChecksResource && item.carAppraisalChecksResource === 'not-completed')
          return !isAppraisalCompleted()
      }
      return false
    })[0]
    switch (matchServiceHint.modalName) {
      case 'hint-appraisal':
        setServiceHintAppraisalModal(true)
        break
      case 'hint-continue-appraisal':
        setServiceHintContinueAppraisalModal(true)
        break
      case 'hint-quality-check':
        setServiceHintQualityCheckModal(true)
        break
      case 'hint-await-quality-check':
        setServiceHintAwaitQualityCheckModal(true)
        break
      case 'hint-await-appraisal':
        setServiceHintAwaitCompoundAppraisalModal(true)
        break
      default:
        return
    }
  }

  const handleSelectService = (serviceIndex) => {
    if (activeTab === serviceIndex) {
      setActiveTab(null)
    } else {
      setActiveTab(serviceIndex)
    }
  }

  const handleSetInitialActiveTab = () => {
    if (
      (combinedStatus && combinedStatus === 'car_workflow.appraisal_on_site') ||
      combinedStatus === 'car_workflow.requested_appraisal_compound' ||
      combinedStatus === 'car_workflow.moderation_failed' ||
      combinedStatus === 'car_workflow.quality_check'
    ) {
      setActiveTab(services.filter((item) => item.name === 'service_appraisal')[0].id - 1)
    }
    if (combinedStatus && combinedStatus.includes('auction_workflow')) {
      setActiveTab(services.filter((item) => item.name === 'service_auction')[0].id - 1)
    }
  }

  const getCurrentAuction = () => {
    privateRequest.get(`/cars/${params.id}${API_ENDPOINTS.lastAuction}`).then((response) => {
      setCurrentAuction(response.data)
    })
  }

  const handleSellCar = () => {
    privateRequest
      .post(`/auctions/${currentAuction?.id}${API_ENDPOINTS.sellCar}`, {})
      .then((response) => {
        setSellModal(false)
        dispatch(setWorkflowState('deal_workflow.pending'))
        toast.success(
          'You sold your car! The buyer will proceed with the payment and the money will be credited to you within 2 business days',
        )
      })
  }

  const handleDoNotSellCar = () => {
    privateRequest
      .post(`/auctions/${currentAuction?.id}${API_ENDPOINTS.doNotSellCar}`, {})
      .then((response) => {
        setDoNotSellModal(false)
        toast.success(
          'You have cancelled the sale. You can try to auction your car again or choose another service',
        )
        dispatch(setWorkflowState('car_workflow.ready_for_sale'))
      })
  }

  useEffect(() => {
    if (combinedStatus) {
      handleSetServicesTabs()
    }
    if (combinedStatus && combinedStatus.includes('auction_workflow')) {
      getCurrentAuction()
    }
    handleSetInitialActiveTab()
    if (combinedStatus === 'car_workflow.requested_appraisal_compound_failed') {
      setSendToCompoundBlock(true)
    }
    if (combinedStatus === 'car_workflow.requested_appraisal_compound') {
      setSendToCompoundBlock(true)
    }
  }, [combinedStatus])

  useEffect(() => {
    if (window.location.search.includes('Finance')) {
      setActiveTab(2)
    }
    if (window.location.search.includes('Insurance')) {
      setActiveTab(3)
    }
    if (window.location.search.includes('Warranty')) {
      setActiveTab(4)
    }
  }, [])

  return (
    <Box>
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          mt: '23px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mb: '23px',
          gap: '12px',
          '&.xs-only': {
            mt: 0,
            mb: 0,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: '7px',
          },
          '&.tablet-only': {
            gap: '5px',
          },
        }}
      >
        {servicesTabs.map((item, index) => {
          if (item.status === 'completed') {
            return (
              <Button
                key={item.id}
                variant="contained"
                className={[
                  activeTab === index ? 'active' : '',
                  xsOnly ? 'xs-only' : '',
                  tabletOnly ? 'tablet-only' : '',
                ].join(' ')}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '6px',
                  height: '48px',
                  background: colorsConfig.mainGreen,
                  paddingLeft: largeOnly ? '22px' : '13px',
                  paddingRight: largeOnly ? '22px' : '13px',
                  whiteSpace: 'nowrap',
                  textTransform: 'none',
                  transition: '0.25s all ease-in-out',
                  '& span': {
                    fontSize: '16px',
                    color: colorsConfig.mainBlack,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    background: colorsConfig.mainGreen,
                  },
                  '&.active': {
                    height: '58px',
                  },
                  '&.xs-only': {
                    width: 'calc(50% - 5px)',
                    fontSize: '15px',
                    '& span': {
                      fontSize: '15px',
                    },
                    '&.active': {
                      height: '48px',
                    },
                  },
                  '&.tablet-only': {
                    '& span': {
                      fontSize: '14px',
                    },
                  },
                }}
                onClick={() => {
                  handleSelectService(index)
                }}
              >
                <img
                  src={IconCheckmarkBlack}
                  style={{
                    marginRight: largeOnly ? '12px' : '5px',
                  }}
                  alt=""
                />
                <span>{t(item.name)}</span>
              </Button>
            )
          }
          return (
            <Button
              key={item.id}
              variant="contained"
              disabled={item.status === 'disabled'}
              className={[
                activeTab === index ? 'active' : '',
                xsOnly ? 'xs-only' : '',
                item.status === 'faded' ? 'faded' : '',
                tabletOnly ? 'tablet-only' : '',
              ].join(' ')}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                height: '48px',
                background: colorsConfig.mainBlue,
                paddingLeft: largeOnly ? '22px' : '13px',
                paddingRight: largeOnly ? '22px' : '13px',
                textTransform: 'none',
                whiteSpace: 'nowrap',
                transition: '0.25s all ease-in-out',
                '& span': {
                  fontSize: '16px',
                  color: 'white',
                  fontWeight: 700,
                },
                '&:last-child': {
                  marginRight: 0,
                },
                '&.Mui-disabled': {
                  background: colorsConfig.buttonDisabled,
                  '& span': {
                    color: colorsConfig.iconGray,
                  },
                  '& img': {
                    fill: colorsConfig.iconGray,
                    filter: 'brightness(50%)',
                  },
                },
                '&:hover': {
                  background: colorsConfig.buttonHover,
                  transition: '0.25s all ease-in-out',
                },
                '&.active': {
                  background: colorsConfig.buttonHover,
                  transition: '0.25s all ease-in-out',
                  height: '58px',
                },
                '&.faded': {
                  background: colorsConfig.fadedBlue,
                  '&:hover': {
                    opacity: '0.7',
                  },
                },
                '&.xs-only': {
                  width: 'calc(50% - 5px)',
                  fontSize: '15px',
                  mb: '10px',
                  mr: 0,
                  '& span': {
                    fontSize: '15px',
                  },
                  '&:nth-of-type(5)': {
                    mb: 0,
                  },
                  '&:nth-of-type(6)': {
                    mb: 0,
                  },
                  '&.active': {
                    height: '48px',
                  },
                },
                '&.tablet-only': {
                  '& span': {
                    fontSize: '14px',
                  },
                },
              }}
              onClick={() => {
                if (item.status !== 'faded') {
                  handleSelectService(index)
                } else {
                  handleShowServiceHint(item.serviceHints)
                }
              }}
            >
              <img
                src={item.icon}
                style={{
                  marginRight: largeOnly ? '12px' : '5px',
                }}
                alt=""
              />
              <span>{t(item.name)}</span>
            </Button>
          )
        })}
      </Box>
      {activeTab !== null && services[activeTab].name === 'service_appraisal' ? (
        <Box>
          <CarSteps block={appraisalBlock} />
          {!carData.isArchived ? (
            <CarActionButtons block={appraisalBlock} downloadingPDF={downloadingPDF} />
          ) : null}
        </Box>
      ) : null}
      {activeTab !== null && services[activeTab].name === 'service_auction' ? (
        <Box>
          <CarSteps block={auctionBlock} />
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: combinedStatus === 'auction_workflow.pending' ? 'flex' : '',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '&.xs-only': {
                display: 'block',
              },
            }}
          >
            <CarActionInfoBlock carId={params.id} currentAuction={currentAuction} />
            {!carData.isArchived ? (
              <CarActionButtons
                position={combinedStatus === 'auction_workflow.pending' ? 'right' : ''}
                block={auctionBlock}
                currentAuction={currentAuction}
              />
            ) : null}
          </Box>
        </Box>
      ) : null}
      {activeTab !== null && services[activeTab].name === 'service_finance' ? (
        <Box>
          <CarSteps block={financeBlock} />
          {!carData.isArchived ? (
            <Box>
              <LoanApplications />
            </Box>
          ) : null}
        </Box>
      ) : null}
      {activeTab !== null && services[activeTab].name === 'service_insurance' ? (
        <CarInsuranceApplications />
      ) : null}
      {activeTab && services[activeTab]?.name === 'service_warranty' ? (
        <CarWarrantyApplications />
      ) : null}
      {displaySendToCompoundComponent && sendToCompoundBlock && !hideAdditionalBlocks ? (
        <SendToCompound setIsFormOpen={setSendToCompoundBlock} />
      ) : null}
      {!carData.isArchived ? (
        <React.Fragment>
          <Modal
            open={sendToCompoundModal}
            onClose={() => {
              setSendToCompoundModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 430,
                background: 'white',
                borderRadius: '10px',
                padding: '55px',
                maxHeight: '80vh',
                overflow: 'auto',
              }}
              className={'disable-scrollbar'}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setSendToCompoundModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '40px',
                  fontWeight: 700,
                  mb: '30px',
                  lineHeight: '46px',
                }}
              >
                Send your car <br /> to us
              </Typography>
            </Box>
          </Modal>
          <StartAuctionModal
            startAuctionModal={startAuctionModal}
            setStartAuctionModal={setStartAuctionModal}
            carId={params.id}
          />
          <ApplyForQualityCheckModal
            open={qualityCheckModal}
            setOpen={setQualityCheckModal}
            handleSubmit={() => {
              handleSubmitToQualityCheck()
            }}
          />
          <RequestCoinsModal
            open={notEnoughCoinsModal}
            setOpen={setNotEnoughCoinsModal}
            title={`Not enough ${COMPANY_NAME[REGION_LOCALE]} Coins`}
            description={`Purchase ${COMPANY_NAME[REGION_LOCALE]} Coins in order to use that service`}
          />
          <SubmitToQualityCheckConfirmModal
            open={qualityCheckFailedModal}
            setOpen={setQualityCheckFailedModal}
            handleSubmit={() => {
              handleSubmitToQualityCheckFailed()
            }}
          />
          <Modal
            open={sellModal}
            onClose={() => {
              setSellModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 688,
                background: 'white',
                borderRadius: '10px',
                padding: '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  width: 'calc(100% - 30px)',
                  px: '10px',
                  py: '20px',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setSellModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '16px',
                  '&.xs-only': {
                    fontSize: '24px',
                  },
                }}
              >
                Are you sure you want to sell the car?
              </TextH1>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ButtonHollow
                  sx={{
                    width: 'calc(50% - 5px)',
                  }}
                  onClick={() => {
                    setSellModal(false)
                  }}
                >
                  Think twice
                </ButtonHollow>
                <ButtonSuccess
                  sx={{
                    width: 'calc(50% - 5px)',
                  }}
                  onClick={() => {
                    handleSellCar()
                  }}
                >
                  Sell
                </ButtonSuccess>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={doNotSellModal}
            onClose={() => {
              setDoNotSellModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 688,
                background: 'white',
                borderRadius: '10px',
                padding: '30px',
                maxHeight: '80vh',
                overflow: 'auto',
                '&.xs-only': {
                  px: '10px',
                  py: '20px',
                  width: 'calc(100% - 30px)',
                },
              }}
              className={['disable-scrollbar', xsOnly ? 'xs-only' : ''].join(' ')}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => {
                  setDoNotSellModal(false)
                }}
              >
                <Close
                  style={{
                    fill: colorsConfig.mainRed,
                  }}
                />
              </IconButton>
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '16px',
                  '&.xs-only': {
                    fontSize: '24px',
                  },
                }}
              >
                Are you sure you do not want to sell the car?
              </TextH1>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ButtonHollow
                  sx={{
                    width: 'calc(50% - 5px)',
                  }}
                  onClick={() => {
                    setDoNotSellModal(false)
                  }}
                >
                  Think twice
                </ButtonHollow>
                <ButtonDanger
                  sx={{
                    width: 'calc(50% - 5px)',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    handleDoNotSellCar()
                  }}
                >
                  Do not sell
                </ButtonDanger>
              </Box>
            </Box>
          </Modal>
        </React.Fragment>
      ) : null}
      <ServiceHintAppraisalModal
        open={serviceHintAppraisalModal}
        setOpen={setServiceHintAppraisalModal}
        handleAction={() => {
          navigate(`/cars/${params.id}/appraisal`)
        }}
      />
      <ServiceHintContinueAppraisalModal
        open={serviceHintContinueAppraisalModal}
        setOpen={setServiceHintContinueAppraisalModal}
        handleAction={() => {
          navigate(`/cars/${params.id}/appraisal`)
        }}
      />
      <ServiceHintQualityCheckModal
        open={serviceHintQualityCheckModal}
        setOpen={setServiceHintQualityCheckModal}
        handleAction={() => {
          if (combinedStatus === 'car_workflow.appraisal_on_site') {
            handleSubmitToQualityCheck()
          }
          if (combinedStatus === 'car_workflow.moderation_failed') {
            handleSubmitToQualityCheckFailed()
          }
        }}
      />
      <ServiceHintInfoModal
        open={serviceHintAwaitQualityCheckModal}
        setOpen={setServiceHintAwaitQualityCheckModal}
        title={'Quality check in progress'}
        description={
          <span>
            Quality check will take up to 48 hours. <br /> Once it's done, you will be able to
            auction the car
          </span>
        }
      />
      <ServiceHintInfoModal
        open={serviceHintAwaitCompoundAppraisalModal}
        setOpen={setServiceHintAwaitCompoundAppraisalModal}
        title={
          <span style={{ fontWeight: 700 }}>
            Appraisal on compound
            <br />
            in progress
          </span>
        }
        description={'Once the appraisal is completed, you will be able to auction the car'}
      />
    </Box>
  )
}

export default CarActionBlock
