import { createAsyncThunk } from '@reduxjs/toolkit'
import rviApiService from '../../../api/rviService/rviApiService'
import { setCurrentPageTitle } from '../authSlice'
import { toast } from 'react-toastify'
import { closingReasonEnumNames } from '../../../pages/RVI/policies.constants'
import filesApiService from '../../../api/filesService/filesApiService'
import { fileTypeEnums } from '../../../utils/constants/enums.constants'
import { getApiError } from '../../../api/api'

export const getRviPolicy = createAsyncThunk(
  'rviPolicy/getRviPolicy',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.getPolicyById(policyId)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const getRestitutionSalesPhotos = createAsyncThunk(
  'rviPolicy/getRestitutionSalesPhotos',
  async (_, { rejectWithValue, getState }) => {
    try {
      const salesPhotos = getState().rviPolicy.data?.restitutionDetails?.salesPhotos
      if (!salesPhotos || salesPhotos.length === 0) return []
      const photosRes = await Promise.allSettled(
        salesPhotos.map((e) => filesApiService.getPrivateFileByIdUrl(e?.salesPhoto)),
      )
      return photosRes.map((res) => res.value.data)
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateRviPolicy = createAsyncThunk(
  'rviPolicy/updateRviPolicy',
  async ({ policyId, data }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.patchPolicy(policyId, data)
      return response.data
    } catch (e) {
      toast.error(getApiError(e))
      return rejectWithValue(e)
    }
  },
)

export const rviStartRestitution = createAsyncThunk(
  'rviPolicy/rviStartRestitution',
  async ({ policyId, formData }, { rejectWithValue }) => {
    try {
      const { vehicleRegistrationCard, appraisalDocument, salesPhotos, isAppraisalApproved } =
        formData
      if (!isAppraisalApproved) {
        const resVehicleRegistrationCard = await filesApiService.createFile(
          vehicleRegistrationCard,
          fileTypeEnums.rvi_policy_restitution_details_vehicle_registration_card,
        )
        const data = {
          ...formData,
          vehicleRegistrationCard: resVehicleRegistrationCard.data?.['@id'],
        }
        const response = await rviApiService.confirmToRestitutionStarted(policyId, data)
        return response.data
      }
      const [resVehicleRegistrationCard, resAppraisalDoc, ...resSalesPhotos] = await Promise.all([
        filesApiService.createFile(
          vehicleRegistrationCard,
          fileTypeEnums.rvi_policy_restitution_details_vehicle_registration_card,
        ),
        isAppraisalApproved &&
          filesApiService.createFile(
            appraisalDocument,
            fileTypeEnums.rvi_policy_restitution_details_appraisal_document,
          ),
        ...salesPhotos.map((item) =>
          filesApiService.createFile(
            item,
            fileTypeEnums.rvi_policy_restitution_details_sales_photo,
          ),
        ),
      ])
      const data = {
        ...formData,
        vehicleRegistrationCard: resVehicleRegistrationCard.data?.['@id'],
        appraisalDocument: resAppraisalDoc.data?.['@id'],
        salesPhotos: resSalesPhotos.map((item) => item?.data?.['@id']),
      }
      const response = await rviApiService.confirmToRestitutionStarted(policyId, data)
      return response.data
    } catch (e) {
      toast.error(e?.response?.data?.errorMessage)
      return rejectWithValue(e)
    }
  },
)

export const cancelPolicy = createAsyncThunk(
  'rviPolicy/cancelPolicy',
  async ({ policyId, data }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToCancel(policyId, data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)

export const closeDueToEndOfContract = createAsyncThunk(
  'rviPolicy/closeDueToEndOfContract',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToClosedDueToEndOfContract(policyId)
      return response.data
    } catch (e) {
      console.log({ e })
      return rejectWithValue(e)
    }
  },
)

export const closePolicyAsDealer = createAsyncThunk(
  'rviPolicy/closePolicyAsDealer',
  async ({ policyId }, { rejectWithValue }) => {
    try {
      const response = await rviApiService.confirmToClosedByDealer(policyId)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const closeRunningContract = createAsyncThunk(
  'rviPolicy/closeRunningContract',
  async ({ policyId, data }, { rejectWithValue }) => {
    try {
      const { closingReason, comment } = data
      let response
      switch (closingReason) {
        case closingReasonEnumNames.END_OF_CONTRACT:
          response = await rviApiService.closeDueToEndOfContract(policyId)
          break
        case closingReasonEnumNames.TOTAL_DAMAGE:
          response = await rviApiService.closeDueToTotalDamage(policyId)
          break
        case closingReasonEnumNames.CUSTOMER_BUYOUT:
          response = await rviApiService.closeDueToCustomerBuyout(policyId)
          break
        case closingReasonEnumNames.VEHICLE_SOLD:
          response = await rviApiService.closeDueToVehicleSold(policyId)
          break
        case closingReasonEnumNames.EARLY_CONTRACT_TERMINATION:
          response = await rviApiService.closeDueToEarlyContractTermination(policyId)
          break
        case closingReasonEnumNames.OTHER_REASON:
          response = await rviApiService.closeDueToOtherReason(policyId, {
            closingReasonDetails: comment,
          })
          break
        default:
          response = await rviApiService.closeDueToOtherReason(policyId, {
            closingReasonDetails: comment,
          })
          break
      }
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
