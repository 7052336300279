import React from 'react'
import IconTG from '../../../assests/img/icons/icon-tg.png'
import { IconButton } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'

const ButtonLinkTG = ({ title = 'Share via Telegram', link, message, sx, ...props }) => {
  return (
    <Tooltip title={title}>
      <IconButton
        component="a"
        href={`https://telegram.me/share/url?url=${link}&text=${message}`}
        target="_blank"
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '6px',
          ...sx,
        }}
        {...props}
      >
        <img
          src={IconTG}
          alt=""
          style={{
            width: '80%',
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonLinkTG
