import React, { useState } from 'react'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import ButtonHollow from '../../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import ModalWrap from '../../../../components/Modals/ModalWrap'
import ButtonDanger from '../../../../ui/Buttons/ButtonDanger'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { useDispatch, useSelector } from 'react-redux'
import { cancelPolicy } from '../../../../store/features/rviPolicy/rviPolicy.actions'

const CancelPolicyModal = ({ open, setOpen, data, onClose }) => {
  const isLoading = useSelector((state) => state.rviPolicy.cancelPolicy.isLoading)
  const dispatch = useDispatch()

  const [cancellationReason, setCancellationReason] = useState('')

  const handleCancelPolicy = async () => {
    const reasonData = {
      reason: cancellationReason,
    }
    const res = await dispatch(cancelPolicy({ policyId: data?.id, data: reasonData }))
    if (cancelPolicy.fulfilled.match(res)) {
      onClose()
    }
  }

  return (
    <ModalWrap open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge
          sx={{
            fontSize: {
              xs: '24px',
              md: '32px',
            },
          }}
          text={`Are you sure you want to cancel the policy for "${data?.details?.make} ${data?.details?.model}?"`}
        />
        <TextFieldSmallEdible
          fullWidth
          label="Cancellation reason"
          value={cancellationReason}
          onChange={(e) => {
            setCancellationReason(e.target.value)
          }}
          multiline
          rows={4}
          maxRows={10}
        />
        <Stack gap="10px" direction={{ xs: 'column', md: 'row' }}>
          <ButtonHollow disabled={isLoading} sx={{ flex: 1 }} onClick={() => setOpen(false)}>
            Double-check the policy
          </ButtonHollow>
          <ButtonDanger disabled={isLoading} sx={{ flex: 1 }} onClick={() => handleCancelPolicy()}>
            Cancel
          </ButtonDanger>
        </Stack>
      </Stack>
    </ModalWrap>
  )
}

export default CancelPolicyModal
