import React, { useState } from 'react'
import { policyWorkflowStates } from '../../../utils/constants/workflows.constants'
import { LOCAL_ROLE, ROLES } from '../../../utils/constants/global.constants'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import LinkDealerModal from '../Operator/Modals/LinkDealerModal'
import CancelPolicyModal from '../Operator/Modals/CancelPolicyModal'
import CloseDueToEndOfContractModal from '../Operator/Modals/CloseDueToEndOfContractModal'
import {
  rviApproveRestitution,
  rviCloseRestitution,
} from '../../../store/features/rviOperator/rviOperator.actions'
import {
  closeDueToEndOfContract,
  closePolicyAsDealer,
  getRviPolicy,
} from '../../../store/features/rviPolicy/rviPolicy.actions'
import { toast } from 'react-toastify'
import RestituteVehicleModal from '../Dealer/RestituteVehicleModal'
import ApproveRestitutionRequestModal from '../Operator/Modals/ApproveRestitutionRequestModal'
import CloseRestitutionModal from '../Operator/Modals/CloseRestitutionModal'
import { setRviCarPolicy } from '../../../store/features/addNewCarSlice/addNewCar.reducer'
import { getInitialMakeAndModel } from '../../../store/features/rviCarSlice/rviCar.actions'
import { useNavigate } from 'react-router-dom'
import CloseRVIContractModal from '../Dealer/CloseRVIContractModal'
import CloseRunningContractModal from '../Operator/Modals/CloseRunningContractModal'

const getPolicyActions = (data, role, workflow) => {
  return data.filter((item) => {
    const matchRole = item.roles.find((roleItem) => roleItem === role)
    const matchWorkflow = item.workflows.find((workflowItem) => workflowItem === workflow)
    return matchRole && matchWorkflow
  })
}

const RVIPolicyActionItems = () => {
  const policy = useSelector((state) => state.rviPolicy.data)
  const role = useSelector((state) => state.auth.role)
  const currentWorkflowState = useSelector((state) => state.rviPolicy.data?.currentWorkflowState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [linkDealerModal, setLinkDealerModal] = useState(false)
  const [cancelPolicyModal, setCancelPolicyModal] = useState(false)
  const [closeDueToEndOfContractModal, setCloseDueToEndOfContractModal] = useState(false)
  const [approveRestitutionModal, setApproveRestitutionModal] = useState(false)
  const [closeRestitutionModal, setCloseRestitutionModal] = useState(false)
  const [closeContractModal, setCloseContractModal] = useState(false)
  const [closeRunningContractModal, setCloseRunningContractModal] = useState(false)

  const onCancelPolicy = () => {
    setCancelPolicyModal(false)
    navigate('/operator/rvi')
  }

  const handleClosePolicyDueToEndOfContract = async () => {
    const res = await dispatch(closeDueToEndOfContract({ policyId: policy?.id }))
    if (closeDueToEndOfContract.fulfilled.match(res)) {
      toast.success('Policy has been closed successfully')
      setCloseDueToEndOfContractModal(false)
      navigate('/operator/rvi')
    }
  }
  const handleApproveRestitution = async () => {
    const res = await dispatch(rviApproveRestitution({ policyId: policy?.id }))
    if (rviApproveRestitution.fulfilled.match(res)) {
      toast.success('Restitution process has started')
      setApproveRestitutionModal(false)
      navigate('/operator/rvi')
    }
  }

  const handleCloseRestitution = async () => {
    const res = await dispatch(rviCloseRestitution({ policyId: policy?.id }))
    if (rviCloseRestitution.fulfilled.match(res)) {
      toast.success('Restitution process has been closed')
      setCloseRestitutionModal(false)
      navigate('/operator/rvi')
    }
  }

  const handleGoToAddCarToDealer = async () => {
    dispatch(setRviCarPolicy(policy))
    navigate('/operator/add-car-to-dealer')
  }

  const handleCloseRVIContract = async () => {
    const res = await dispatch(closePolicyAsDealer({ policyId: policy?.id }))
    if (closePolicyAsDealer.fulfilled.match(res)) {
      toast.success('You have closed the RVI contract')
      setCloseContractModal(false)
      navigate('/rvi')
    }
  }

  const actions = [
    {
      name: 'Request restitution',
      buttonType: 'default',
      workflows: [policyWorkflowStates.linked_to_dealer],
      roles: [LOCAL_ROLE.COMPANY],
      handleAction: () => navigate(`/rvi/policies/${policy?.id}/restitute-vehicle`),
    },
    {
      name: 'Link policy to dealer',
      buttonType: 'hollow',
      workflows: [policyWorkflowStates.new],
      roles: [LOCAL_ROLE.OPERATOR],
      handleAction: () => setLinkDealerModal(true),
    },
    {
      name: 'Start restitution',
      buttonType: 'default',
      workflows: [policyWorkflowStates.restitution_started],
      roles: [LOCAL_ROLE.OPERATOR],
      handleAction: () => setApproveRestitutionModal(true),
    },
    {
      name: 'Close',
      buttonType: 'default',
      workflows: [policyWorkflowStates.restitution_in_progress],
      roles: [LOCAL_ROLE.OPERATOR],
      handleAction: () => setCloseRestitutionModal(true),
    },
    {
      name: 'Close running contract',
      buttonType: 'hollow',
      workflows: [
        policyWorkflowStates.restitution_in_progress,
        policyWorkflowStates.restitution_started,
        policyWorkflowStates.linked_to_dealer,
      ],
      roles: [LOCAL_ROLE.OPERATOR, LOCAL_ROLE.COMPANY],
      handleAction: () => setCloseRunningContractModal(true),
    },
    {
      name: 'Add car to dealer',
      buttonType: 'hollow',
      workflows: [policyWorkflowStates.closed],
      roles: [LOCAL_ROLE.OPERATOR],
      handleAction: () => handleGoToAddCarToDealer(),
    },
    {
      name: 'Cancel policy',
      buttonType: 'cancel',
      workflows: [
        policyWorkflowStates.restitution_started,
        policyWorkflowStates.new,
        policyWorkflowStates.restitution_in_progress,
        policyWorkflowStates.linked_to_dealer,
        policyWorkflowStates.new,
      ],
      roles: [LOCAL_ROLE.OPERATOR],
      handleAction: () => setCancelPolicyModal(true),
    },
  ]

  return (
    <Stack direction="row" gap="10px">
      {getPolicyActions(actions, role, currentWorkflowState).map((item, index) => {
        if (item?.buttonType === 'hollow') {
          return (
            <ButtonHollow onClick={() => item?.handleAction()} key={index}>
              {item?.name}
            </ButtonHollow>
          )
        }
        if (item?.buttonType === 'cancel') {
          return (
            <ButtonDanger onClick={() => item?.handleAction()} key={index}>
              {item?.name}
            </ButtonDanger>
          )
        }
        return (
          <ButtonLG onClick={() => item?.handleAction()} key={index}>
            {item?.name}
          </ButtonLG>
        )
      })}
      {linkDealerModal && (
        <LinkDealerModal open={linkDealerModal} setOpen={setLinkDealerModal} policy={policy} />
      )}
      {cancelPolicyModal && (
        <CancelPolicyModal
          open={cancelPolicyModal}
          setOpen={setCancelPolicyModal}
          data={policy}
          onClose={onCancelPolicy}
        />
      )}
      {closeDueToEndOfContractModal && (
        <CloseDueToEndOfContractModal
          open={closeDueToEndOfContractModal}
          setOpen={setCloseDueToEndOfContractModal}
          data={policy}
          handleAction={handleClosePolicyDueToEndOfContract}
        />
      )}
      {approveRestitutionModal && (
        <ApproveRestitutionRequestModal
          open={approveRestitutionModal}
          setOpen={setApproveRestitutionModal}
          handleAction={handleApproveRestitution}
        />
      )}
      {closeRestitutionModal && (
        <CloseRestitutionModal
          open={closeRestitutionModal}
          setOpen={setCloseRestitutionModal}
          handleAction={handleCloseRestitution}
        />
      )}
      {closeContractModal && (
        <CloseRVIContractModal
          open={closeContractModal}
          setOpen={setCloseContractModal}
          handleAction={handleCloseRVIContract}
          policy={policy}
        />
      )}
      {closeRunningContractModal && (
        <CloseRunningContractModal
          open={closeRunningContractModal}
          setOpen={setCloseRunningContractModal}
          data={policy}
          onClose={() => dispatch(getRviPolicy({ policyId: policy?.id }))}
        />
      )}
    </Stack>
  )
}

export default RVIPolicyActionItems
