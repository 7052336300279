import { createSlice } from '@reduxjs/toolkit'
import calculateRVBuilder from './builders/calculateRV.builder'
import calculateTermsBuilder from './builders/calculateTerms.builder'
import calculateCustomPackageTermsBuilder from './builders/calculateCustomPackageTerms.builder'

const initialState = {
  rvCalculation: {
    isLoading: false,
    error: null,
    data: null,
  },
  termsCalculation: {
    isLoading: false,
    error: null,
    isLoadingCustom: false,
    errorCustom: null,
    basic: [],
    full: [],
    custom: [],
    customPrice: 0,
  },
}

const financingSlice = createSlice({
  name: 'financingReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    calculateRVBuilder(builder)
    calculateTermsBuilder(builder)
    calculateCustomPackageTermsBuilder(builder)
  },
})

export default financingSlice.reducer
