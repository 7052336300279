import dayjs from 'dayjs'

export const getWarrantyDetailsFormInitialState = (warrantyApplication) => {
  const offsetDateTime = new Date().getTime() - 3600000 * 24 * 366
  const startDateTime = new Date(warrantyApplication?.startDate).getTime()
  return {
    startDate: startDateTime < offsetDateTime ? '' : dayjs(warrantyApplication?.startDate),
    selectedProduct: warrantyApplication?.selectedProduct,
    consent: !!warrantyApplication?.selectedProduct,
  }
}
