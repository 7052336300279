import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import AdminPanelControlPoint from './AdminPanelControlPoint'
import { colorsConfig } from '../../../config/themeConfig'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import IconEdit from '../../../assests/img/icons/icon-edit.svg'
import ModalWrap from '../../../components/Modals/ModalWrap'
import TextH1 from '../../../ui/Text/TextH1'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import ButtonDanger from '../../../ui/Buttons/ButtonDanger'
import { privateRequest } from '../../../utils/request'
import { toast } from 'react-toastify'

const AdminPanelSubblock = (props) => {
  const { subblock, refresh } = props

  const { name, controlPoints, translations } = subblock

  const [editModal, setEditModal] = useState(false)
  const [mainName, setMainName] = useState(name)
  const [nameDE, setNameDE] = useState(
    translations.filter((item) => item.systemName === 'de')[0].value,
  )
  const [nameFR, setNameFR] = useState(
    translations.filter((item) => item.systemName === 'fr')[0].value,
  )
  const [nameIT, setNameIT] = useState(
    translations.filter((item) => item.systemName === 'it')[0].value,
  )
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const handleUpdateSubblock = () => {
    setSaveButtonDisabled(true)
    const formData = {
      name: mainName,
      translations: [
        {
          locale: 'en',
          field: 'name',
          value: mainName,
        },
        {
          locale: 'de',
          field: 'name',
          value: nameDE,
        },
        {
          locale: 'fr',
          field: 'name',
          value: nameFR,
        },
        {
          locale: 'it',
          field: 'name',
          value: nameIT,
        },
      ],
    }
    privateRequest
      .put(subblock['@id'], formData)
      .then(() => {
        toast.success('Subblock has been updated')
        refresh()
        setEditModal(false)
      })
      .finally(() => {
        setSaveButtonDisabled(false)
      })
  }

  return (
    <>
      <Accordion
        sx={{
          boxShadow: '0 0 12px 0 rgba(0,0,0,.05)',
          borderRadius: '6px',
          '&::before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          expandIcon={<ExpandMore />}
        >
          <TextBoldNormal>{name}</TextBoldNormal>
          <Button
            sx={{
              textTransform: 'none',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setEditModal(true)
            }}
          >
            <img
              src={IconEdit}
              style={{
                marginRight: '5px',
              }}
              alt=""
            />
            Edit
          </Button>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            background: colorsConfig.bgLightBlue,
            pt: '10px',
          }}
        >
          {controlPoints.map((item, index) => {
            return (
              <AdminPanelControlPoint key={index} controlPoint={item} getControlPoints={refresh} />
            )
          })}
        </AccordionDetails>
      </Accordion>
      <ModalWrap open={editModal} setOpen={setEditModal}>
        <TextH1
          sx={{
            fontSize: '32px',
            mb: '20px',
          }}
        >
          Edit "{name}"
        </TextH1>
        <Grid container spacing={'15px'}>
          <Grid item xs={12}>
            <TextFieldSmallEdible
              fullWidth
              label={'Name'}
              value={mainName}
              onChange={(e) => {
                setMainName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldSmallEdible
              fullWidth
              label={'Name (German)'}
              value={nameDE}
              onChange={(e) => {
                setNameDE(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldSmallEdible
              fullWidth
              label={'Name (French)'}
              value={nameFR}
              onChange={(e) => {
                setNameFR(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldSmallEdible
              fullWidth
              label={'Name (Italian)'}
              value={nameIT}
              onChange={(e) => {
                setNameIT(e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            mt: '20px',
          }}
        >
          <ButtonDanger
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              setEditModal(false)
            }}
          >
            Cancel
          </ButtonDanger>
          <ButtonLG
            disabled={saveButtonDisabled}
            sx={{
              width: 'calc(50% - 5px)',
            }}
            onClick={() => {
              handleUpdateSubblock()
            }}
          >
            Save
          </ButtonLG>
        </Box>
      </ModalWrap>
    </>
  )
}

export default AdminPanelSubblock
