import React from 'react'
import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material'
import { useField } from 'formik'
import UITextBodySmall from '../../../../ui/UIText/UITextBodySmall'
import { colorsConfig } from '../../../../config/themeConfig'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'

const FormInputAutocomplete = ({
  label,
  id,
  required,
  suffix,
  regExp,
  validateOnChange,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = (e, newValue) => {
    setValue(newValue?.value ?? null)
  }

  return (
    <Box>
      <Autocomplete
        id={id}
        required={required}
        options={options}
        value={options.find((option) => option.value === field.value) || null}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextFieldSmallEdible
            {...params}
            required={required}
            isAutocomplete
            label={label}
            name={field.name}
            error={meta.error && meta.touched}
          />
        )}
        {...props}
      />
      {meta.error && (meta.touched || validateOnChange) ? (
        <FormHelperText>
          <UITextBodySmall
            sx={{ color: colorsConfig.darkRed, fontSize: '12px', pl: '12px' }}
            component="span"
            text={meta.error}
          />
        </FormHelperText>
      ) : null}
    </Box>
  )
}

export default FormInputAutocomplete
