import React, { useEffect, useState } from 'react'
import { Stack, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CarDetails from './sections/CarDetails'
import WarrantyDetails from './sections/WarrantyDetails'
import { getWarrantyProducts } from '../../../store/features/warrantyProductsSlice/warrantyProducts.actions'
import { getCoveredComponents } from '../../../store/features/coveredComponentsSlice/coveredComponents.actions'
import { getWarrantyApplication } from '../../../store/features/warrantyApplicationSlice/warrantyApplication.actions'
import { resetWarrantyApplication } from '../../../store/features/warrantyApplicationSlice/warrantyApplication.reducer'
import ActivatedWarrantyDetails from './sections/ActivatedWarrantyDetails'
import AnimationFinancingModal from '../../../components/Modals/AnimationFinancingModal'
import RBox from '../../../ui/RBox'
import ComingSoon from '../../../components/ComingSoon'

const CarWarrantyApplications = () => {
  const warrantyApplication = useSelector((state) => state.warrantyApplication.warrantyApplication)
  const showActivatedPopup = useSelector((state) => state.warrantyApplication.showActivatedPopup)
  const isLoadingProducts = useSelector((state) => state.warrantyProducts.isLoading)
  const isLoadingComponents = useSelector((state) => state.coveredComponents.isLoading)
  const warrantyProducts = useSelector((state) => state.warrantyProducts.warrantyProducts)
  const coveredComponents = useSelector((state) => state.coveredComponents.coveredComponents)
  const dispatch = useDispatch()
  const [showAnimationModal, setShowAnimationModal] = useState(false)

  const displayWarrantyDetails =
    !isLoadingComponents &&
    !isLoadingProducts &&
    warrantyProducts.length >= 3 &&
    coveredComponents.length > 0

  const displayActivatedDetails =
    displayWarrantyDetails && warrantyApplication?.currentWorkflowState === 'issued'

  useEffect(() => {
    if (showActivatedPopup) {
      window.scrollTo(0, 200)
      setShowAnimationModal(true)
    }
  }, [showActivatedPopup])

  useEffect(() => {
    dispatch(getWarrantyApplication())
    dispatch(getWarrantyProducts())
    dispatch(getCoveredComponents())
    return () => {
      dispatch(resetWarrantyApplication())
    }
  }, [])

  return (
    <RBox
      sx={{
        '&.xs-only': {
          mt: '30px',
        },
      }}
    >
      <AnimationFinancingModal open={showAnimationModal} setOpen={setShowAnimationModal} />
      {displayActivatedDetails ? (
        <ActivatedWarrantyDetails />
      ) : (
        <Stack gap="30px">
          <CarDetails />
          {displayWarrantyDetails ? <WarrantyDetails /> : null}
        </Stack>
      )}
    </RBox>
  )
}

export default CarWarrantyApplications
