import React, { useEffect, useState } from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import {
  Box,
  FormControl,
  IconButton,
  Grid,
  Stack,
  InputAdornment,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import { ExpandMoreRounded } from '@mui/icons-material'
import TextFieldSmall from '../../../ui/TextFieldSmall'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import { patchRequest, privateMultipartRequest, privateRequest } from '../../../utils/request'
import { API_ENDPOINTS, API_FINANCE_ENDPOINTS, FILE_TYPES } from '../../../config/default'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import FileField from './FileField'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { countries } from '../../../default-data/coutries'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCustomerDataFormOpened,
  updateCustomer,
  updateMonthlySalary,
  updateYearlySalary,
  updateEmploymentStatus,
  updateCustomerDataForm,
} from '../../../store/features/financeCar'
import CustomRadio from '../../../ui/CustomRadio'

const CustomerDataForm = (props) => {
  const { setSendToBanksModal } = props

  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const banks = useSelector((state) => state.carFinance.banks)
  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const expanded = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerDataFormOpened,
  )
  const disabled = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerDataFormDisabled,
  )
  const saved = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerDataFormSaved,
  )
  const loanApplicationId = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab]['@id'],
  )
  const customer = useSelector((state) => state.carFinance.loanApplications[selectedTab].customer)
  const monthlySalary = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerMonthlySalary,
  )
  const yearlySalary = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerYearlySalary,
  )
  const employmentStatus = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerEmploymentStatus,
  )
  const documents = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].customerDocuments,
  )

  const [showErrors, setShowErrors] = useState(false)
  const [saveButtonLoading, setSaveButtonLoading] = useState(false)
  const [allowPersonalData, setAllowPersonalData] = useState(false)
  const [banksList, setBanksList] = useState([])

  const validateEmail = () => {
    if (
      !customer.email ||
      !customer.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    )
      return false
    return true
  }

  const checkErrors = () => {
    if (!customer.firstName) return true
    if (!customer.lastName) return true
    if (!customer.address) return true
    if (!customer.city) return true
    if (!validateEmail()) return true
    if (!customer.phoneNumber || customer.phoneNumber.length > 9 || customer.phoneNumber.length < 8)
      return true
    if (!customer.nationality) return true
    if (!employmentStatus) return true
    if (!customer.employerName) return true
    if (!customer.yearsInEmployment) return true
    if (!monthlySalary) return true
    if (!yearlySalary) return true
    if (!customer.salaryTransferBank) return true
    return false
  }

  const uploadCustomerFile = async (file, type) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', FILE_TYPES.customerFile)
    const fileId = await privateMultipartRequest
      .post(API_ENDPOINTS.file, formData)
      .then((response) => {
        return response.data['@id']
      })
    return {
      fileId,
      type,
    }
  }

  const handleSubmitCustomerDataForm = async () => {
    if (checkErrors()) {
      setShowErrors(true)
      return
    }
    setSaveButtonLoading(true)
    const currentDocs = documents.filter((item) =>
      Boolean(item.employmentStatuses.filter((itemStatus) => itemStatus === employmentStatus)[0]),
    )
    let newFiles = []
    let filesToDelete = []
    currentDocs.forEach((item) => {
      if (item.files.filter((file) => !!file.newFile).length > 0) {
        newFiles = newFiles.concat(
          item.files
            .filter((file) => !!file.newFile)
            .map((file) => {
              return {
                type: item.systemName,
                file: file.file,
              }
            }),
        )
      }
      if (item.files.filter((file) => !!file.deleted).length > 0) {
        filesToDelete = filesToDelete.concat(item.files.filter((file) => !!file.deleted))
      }
    })
    Promise.all(filesToDelete.map((item) => privateRequest.delete(`/finance${item['@id']}`))).then(
      () => {
        Promise.all(newFiles.map((item) => uploadCustomerFile(item.file, item.type))).then(
          (result) => {
            const formData = {
              customer: {
                person: {
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                  email: customer.email,
                  nationality: customer.nationality.label,
                  phoneNumber: `+971${customer.phoneNumber}`,
                  city: customer.city,
                  country: customer.country.label,
                  address: customer.address,
                },
                employmentStatus: employmentStatus,
                employerName: customer.employerName,
                yearsInEmployment: customer.yearsInEmployment.toString(),
                monthSalary: monthlySalary.toString(),
                yearlySalary: yearlySalary.toString(),
                salaryTransferBank: customer.salaryTransferBank,
                files: result.map((item) => {
                  return {
                    type: item.type,
                    file: item.fileId,
                  }
                }),
              },
            }
            patchRequest
              .patch(`${loanApplicationId}/customer`, formData)
              .then((response) => {
                if (saved) {
                  dispatch(updateCustomerDataForm(response.data.customer.files))
                  window.scrollTo(0, 840)
                  setSendToBanksModal(true)
                  return
                } else {
                  privateRequest
                    .post(
                      API_FINANCE_ENDPOINTS.loanApplicationWorkflowTransitions.customerDataSaved(
                        loanApplicationId.replace(/[^0-9]/g, ''),
                      ),
                      {},
                    )
                    .then(() => {
                      dispatch(updateCustomerDataForm(response.data.customer.files))
                      window.scrollTo(0, 840)
                      setSendToBanksModal(true)
                    })
                }
              })
              .finally(() => {
                setSaveButtonLoading(false)
              })
          },
        )
      },
    )
  }

  const handleSetBanksList = () => {
    setBanksList(
      banks.map((item) => {
        return `${item.title}${item.shortTitle ? ` (${item.shortTitle})` : ''}`
      }),
    )
  }

  useEffect(() => {
    if (banks.length > 0) {
      handleSetBanksList()
    }
  }, [banks])

  return (
    <Box
      className={[disabled ? 'disabled' : ''].join(' ')}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: colorsConfig.bgLightBlue,
        mt: '30px',
        transition: '0.25s all ease-in-out',
        '&.disabled': {
          pointerEvents: 'none',
          opacity: 0.5,
          transition: '0.25s all ease-in-out',
        },
      }}
    >
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          height: '79px',
          py: '17px',
          px: '29px',
          border: '1px solid #DCDCDC',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          zIndex: 2,
          '&.xs-only': {
            px: '16px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box>
          <TextBoldNormal
            sx={{
              color: colorsConfig.mainBlue,
              fontSize: '18px',
            }}
          >
            Customer data form
          </TextBoldNormal>
          <TextNormal>Enter customer data</TextNormal>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {saved ? (
            <TextBoldNormal
              sx={{
                fontSize: '18px',
                color: colorsConfig.mainGreen,
                mr: '20px',
              }}
            >
              Saved
            </TextBoldNormal>
          ) : null}
          <IconButton
            className={expanded ? 'open' : ''}
            sx={{
              position: 'relative',
              mr: '-15px',
              transition: '0.25s all ease-in-out',
              '&.open': {
                transform: 'rotate(180deg)',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              dispatch(setCustomerDataFormOpened(!expanded))
            }}
          >
            <ExpandMoreRounded
              sx={{
                fontSize: '23px',
                width: '40px',
                height: '40px',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={[
          expanded ? '' : 'collapsed',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
        sx={{
          paddingTop: '119px',
          mt: '-79px',
          position: 'relative',
          px: '40px',
          pb: '35px',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: colorsConfig.buttonDisabled,
          background: colorsConfig.bgLightBlue,
          transition: '0.3s all ease-in-out',
          '&.collapsed': {
            mt: '-70%',
            display: 'none',
            opacity: '0',
            pointerEvents: 'none',
            transition: '0.3s all ease-in-out',
          },
          '&.xs-only': {
            px: '20px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '20px',
                  '&.xs-only': {
                    mb: 0,
                  },
                }}
              >
                <RadioGroup
                  row
                  value={employmentStatus}
                  onChange={(e) => {
                    dispatch(updateEmploymentStatus(e.target.value))
                  }}
                >
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={'Employed'}
                    label={'Employed'}
                    sx={{ mr: '30px' }}
                  />
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={'Self-employed'}
                    label={'Self-employed'}
                    sx={{ mr: '30px' }}
                  />
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={'Company loan'}
                    label={'Company loan'}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Stack spacing={3}>
                {employmentStatus === 'Self-employed' || employmentStatus === 'Company loan' ? (
                  <TextFieldSmallEdible
                    label={'Company name'}
                    fullWidth
                    value={customer.companyName}
                    onChange={(e) => {
                      dispatch(
                        updateCustomer({
                          ...customer,
                          companyName: e.target.value,
                        }),
                      )
                    }}
                  />
                ) : null}
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'First name'}
                  value={customer.firstName}
                  onChange={(e) => {
                    dispatch(
                      updateCustomer({
                        ...customer,
                        firstName: e.target.value,
                      }),
                    )
                  }}
                  error={showErrors && !customer.firstName}
                  helperText={
                    showErrors && !customer.firstName ? 'This field can not be empty' : ''
                  }
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Last name'}
                  value={customer.lastName}
                  onChange={(e) => {
                    dispatch(
                      updateCustomer({
                        ...customer,
                        lastName: e.target.value,
                      }),
                    )
                  }}
                  error={showErrors && !customer.lastName}
                  helperText={showErrors && !customer.lastName ? 'This field can not be empty' : ''}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Address'}
                  value={customer.address}
                  onChange={(e) => {
                    dispatch(
                      updateCustomer({
                        ...customer,
                        address: e.target.value,
                      }),
                    )
                  }}
                  error={showErrors && !customer.address}
                  helperText={showErrors && !customer.address ? 'This field can not be empty' : ''}
                />
                {!employmentStatus || employmentStatus === 'Employed' ? (
                  <>
                    <TextFieldSmallEdible
                      required
                      fullWidth
                      label={'City'}
                      value={customer.city}
                      onChange={(e) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            city: e.target.value,
                          }),
                        )
                      }}
                      error={showErrors && !customer.city}
                      helperText={showErrors && !customer.city ? 'This field can not be empty' : ''}
                    />
                    <Autocomplete
                      id="country"
                      options={[
                        {
                          label: null,
                        },
                        ...countries,
                      ]}
                      getOptionLabel={(option) => option.label || ''}
                      fullWidth
                      autoHighlight
                      value={customer.country}
                      onChange={(e, newVal) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            country: newVal,
                          }),
                        )
                      }}
                      renderOption={(props, option) => {
                        if (!option.label) return null
                        return (
                          <Box
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextFieldSmallEdible
                          required
                          {...params}
                          label="Country"
                          error={showErrors && !customer.country}
                          helperText={
                            showErrors && !customer.country ? 'This field can not be empty' : ''
                          }
                        />
                      )}
                    />
                  </>
                ) : null}
              </Stack>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Stack spacing={3}>
                {employmentStatus && employmentStatus !== 'Employed' ? (
                  <>
                    <TextFieldSmallEdible
                      required
                      fullWidth
                      label={'City'}
                      value={customer.city}
                      onChange={(e) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            city: e.target.value,
                          }),
                        )
                      }}
                      error={showErrors && !customer.city}
                      helperText={showErrors && !customer.city ? 'This field can not be empty' : ''}
                    />
                    <Autocomplete
                      id="country"
                      options={[
                        {
                          label: null,
                        },
                        ...countries,
                      ]}
                      getOptionLabel={(option) => option.label || ''}
                      fullWidth
                      autoHighlight
                      value={customer.country}
                      onChange={(e, newVal) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            country: newVal,
                          }),
                        )
                      }}
                      renderOption={(props, option) => {
                        if (!option.label) return null
                        return (
                          <Box
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextFieldSmallEdible
                          required
                          {...params}
                          label="Country"
                          error={showErrors && !customer.country}
                          helperText={
                            showErrors && !customer.country ? 'This field can not be empty' : ''
                          }
                        />
                      )}
                    />
                  </>
                ) : null}
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'E-mail'}
                  type={'email'}
                  value={customer.email}
                  onChange={(e) => {
                    dispatch(
                      updateCustomer({
                        ...customer,
                        email: e.target.value,
                      }),
                    )
                  }}
                  error={showErrors && !validateEmail()}
                  helperText={showErrors && !validateEmail() ? 'Invalid email' : ''}
                />
                <Box>
                  <Grid item container md={12} spacing={2}>
                    <Grid item xs={3} sm={4}>
                      <TextFieldSmall fullWidth value={'+971'} readOnly />
                    </Grid>
                    <Grid item xs={9} sm={8}>
                      <TextFieldSmallEdible
                        required
                        fullWidth
                        label={'Mobile number'}
                        value={customer.phoneNumber}
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        maxLength={9}
                        onChange={(e) => {
                          if (e.target.value.length <= 9) {
                            dispatch(
                              updateCustomer({
                                ...customer,
                                phoneNumber: e.target.value,
                              }),
                            )
                          }
                        }}
                        error={
                          showErrors &&
                          (!customer.phoneNumber ||
                            customer.phoneNumber.length > 9 ||
                            customer.phoneNumber.length < 8)
                        }
                        helperText={
                          showErrors &&
                          (!customer.phoneNumber ||
                            customer.phoneNumber.length > 9 ||
                            customer.phoneNumber.length < 8)
                            ? 'Mobile number must have 10-11 digits'
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                {!employmentStatus || employmentStatus === 'Employed' ? (
                  <>
                    <Autocomplete
                      id="nationality"
                      options={[
                        {
                          label: null,
                        },
                        ...countries,
                      ]}
                      getOptionLabel={(option) => option.label || ''}
                      fullWidth
                      autoHighlight
                      value={customer.nationality}
                      onChange={(e, newVal) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            nationality: newVal,
                          }),
                        )
                      }}
                      renderOption={(props, option) => {
                        if (!option.label) return null
                        return (
                          <Box
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextFieldSmallEdible
                          required
                          {...params}
                          label="Nationality"
                          error={showErrors && !customer.nationality}
                          helperText={
                            showErrors && !customer.nationality ? 'This field can not be empty' : ''
                          }
                        />
                      )}
                    />
                    <TextFieldSmallEdible
                      required
                      fullWidth
                      label={'Employer name'}
                      value={customer.employerName}
                      onChange={(e) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            employerName: e.target.value,
                          }),
                        )
                      }}
                      error={showErrors && !customer.employerName}
                      helperText={
                        showErrors && !customer.employerName ? 'This field can not be empty' : ''
                      }
                    />
                    <TextFieldSmallEdible
                      required
                      fullWidth
                      label={'Years in employment'}
                      value={customer.yearsInEmployment}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                      onChange={(e) => {
                        if (e.target.value.length < 3) {
                          dispatch(
                            updateCustomer({
                              ...customer,
                              yearsInEmployment: e.target.value.replace(/[^0-9]/g, ''),
                            }),
                          )
                        }
                      }}
                      error={showErrors && !customer.yearsInEmployment}
                      helperText={
                        showErrors && !customer.yearsInEmployment
                          ? 'This field can not be empty'
                          : ''
                      }
                    />
                  </>
                ) : null}
              </Stack>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Stack spacing={3}>
                {employmentStatus === 'Self-employed' || employmentStatus === 'Company loan' ? (
                  <>
                    <Autocomplete
                      id="nationality"
                      options={[
                        {
                          label: null,
                        },
                        ...countries,
                      ]}
                      getOptionLabel={(option) => option.label || ''}
                      fullWidth
                      autoHighlight
                      value={customer.nationality}
                      onChange={(e, newVal) => {
                        dispatch(
                          updateCustomer({
                            ...customer,
                            nationality: newVal,
                          }),
                        )
                      }}
                      renderOption={(props, option) => {
                        if (!option.label) return null
                        return (
                          <Box
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )
                      }}
                      renderInput={(params) => (
                        <TextFieldSmallEdible
                          required
                          {...params}
                          label="Nationality"
                          error={showErrors && !customer.nationality}
                          helperText={
                            showErrors && !customer.nationality ? 'This field can not be empty' : ''
                          }
                        />
                      )}
                    />
                  </>
                ) : null}
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Fixed Salary / month'}
                  value={sumDelimiter(monthlySalary)}
                  onChange={(e) => {
                    dispatch(updateMonthlySalary(e.target.value.replace(/[^0-9]/g, '')))
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  error={showErrors && !monthlySalary}
                  helperText={showErrors && !monthlySalary ? 'This field can not be empty' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          AED
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextFieldSmallEdible
                  required
                  fullWidth
                  label={'Yearly salary'}
                  value={sumDelimiter(yearlySalary)}
                  onChange={(e) => {
                    dispatch(updateYearlySalary(e.target.value.replace(/[^0-9]/g, '')))
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  error={showErrors && !yearlySalary}
                  helperText={showErrors && !yearlySalary ? 'This field can not be empty' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <TextNormal
                          sx={{
                            fontSize: '14px',
                            color: colorsConfig.iconGray,
                          }}
                        >
                          AED
                        </TextNormal>
                      </InputAdornment>
                    ),
                  }}
                />
                {!employmentStatus || employmentStatus === 'Employed' ? (
                  <Autocomplete
                    freeSolo
                    fullWidth
                    options={banksList}
                    value={customer.salaryTransferBank}
                    onChange={(e, newVal) => {
                      dispatch(
                        updateCustomer({
                          ...customer,
                          salaryTransferBank: newVal,
                        }),
                      )
                    }}
                    renderInput={(params) => (
                      <TextFieldSmallEdible
                        {...params}
                        label={'Salary transfer bank'}
                        required
                        value={customer.salaryTransferBank}
                        onChange={(e) => {
                          dispatch(
                            updateCustomer({
                              ...customer,
                              salaryTransferBank: e.target.value,
                            }),
                          )
                        }}
                        error={showErrors && !customer.salaryTransferBank}
                        helperText={
                          showErrors && !customer.salaryTransferBank
                            ? 'This field can not be empty'
                            : ''
                        }
                      />
                    )}
                  />
                ) : // <TextFieldSmallEdible
                //     required
                //     fullWidth
                //     label={'Salary transfer bank'}
                //     value={customer.salaryTransferBank}
                //     onChange={(e) => {
                //         dispatch(updateCustomer({
                //             ...customer,
                //             salaryTransferBank: e.target.value
                //         }))
                //     }}
                //     error={showErrors && !customer.salaryTransferBank}
                //     helperText={showErrors && !customer.salaryTransferBank ? 'This field can not be empty' : ''}
                // />
                null}
              </Stack>
            </Grid>
            {employmentStatus ? (
              <Grid item xs={12}>
                <TextBoldNormal sx={{ mt: '15px' }}>
                  Documents for {employmentStatus.toLowerCase()}
                </TextBoldNormal>
              </Grid>
            ) : null}
            {documents.map((item) => {
              if (!item.employmentStatuses.filter((empStatus) => empStatus === employmentStatus)[0])
                return null
              return (
                <FileField
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  files={item.files}
                  caption={item.caption ? item.caption : ''}
                />
              )
            })}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: '40px',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: '15px',
              }}
            >
              <SimpleCheckbox value={allowPersonalData} setValue={setAllowPersonalData} />
              <TextNormal
                sx={{
                  fontSize: '14px',
                  color: colorsConfig.iconGray,
                  ml: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setAllowPersonalData(!allowPersonalData)
                }}
              >
                I consent to the processing <br />
                of my personal data
              </TextNormal>
            </Box>
            <ButtonLG
              onClick={() => {
                handleSubmitCustomerDataForm()
              }}
              className={saveButtonLoading ? 'loading' : ''}
              sx={{
                '&.loading': {
                  pointerEvents: 'none',
                },
              }}
              disabled={!allowPersonalData}
            >
              {saveButtonLoading ? 'Saving...' : 'Save and finish'}
            </ButtonLG>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerDataForm
