import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../config/themeConfig'

const GrayTable = ({ content }) => {
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      className={xsOnly ? 'xs-only' : ''}
      sx={{
        position: 'relative',
        width: 'calc(100% + 64px)',
        marginLeft: '-32px',
        '&.xs-only': {
          ml: '-8px',
          width: 'calc(100% + 16px)',
        },
      }}
    >
      {content.map((item, index) => {
        return (
          <Box
            className={xsOnly ? 'xs-only' : ''}
            key={index}
            sx={{
              background: index === 0 || (index + 1) % 2 ? colorsConfig.bgLightBlue : 'white',
              py: item.fixedHeight ? '0' : '13px',
              height: item.fixedHeight ? '50px' : 'auto',
              px: '30px',
              '&.xs-only': {
                px: '8px',
              },
            }}
          >
            <Grid
              container
              spacing={item.fixedHeight ? 0 : 2}
              className={item.fixedHeight ? 'fixed-height' : ''}
              sx={{
                '&.fixed-height': {
                  '& > div > p': {
                    height: '50px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                },
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    fontFamily: '"HelveticaNeue", sans-serif',
                    fontWeight: 500,
                    fontSize: '16px',
                    color: colorsConfig.mainBlack,
                    '&.xs-only': {
                      fontWeight: 500,
                    },
                  }}
                >
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {item.fixedHeight ? (
                  <Box
                    sx={{
                      height: '50px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {item.value}
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    className={xsOnly ? 'xs-only' : ''}
                    sx={{
                      display: 'block',
                      textAlign: 'right',
                      fontFamily: '"HelveticaNeue", sans-serif',
                      fontWeight: 500,
                      fontSize: '16px',
                      color: colorsConfig.mainBlack,
                      '&.xs-only': {
                        fontWeight: 700,
                        textAlign: 'right',
                        paddingRight: '8px',
                      },
                    }}
                  >
                    {item.value}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}

export default GrayTable
