import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Typography,
  Grid,
  SvgIcon,
  IconButton,
  Modal,
  Button,
  Autocomplete,
  useMediaQuery,
} from '@mui/material'
import { Edit, CameraAlt } from '@mui/icons-material'
import EditIcon from './EditIcon'
import { privateRequest } from '../../utils/request'
import { API_ENDPOINTS, CDN_BASE_URL, FILE_TYPES } from '../../config/default'
import SettingsField from './SettingsField'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'
import { uploadFile } from '../../utils/uploadFile'
import Avatar1 from '../../assests/img/avatars/avatar-1.png'
import Avatar2 from '../../assests/img/avatars/avatar-2.png'
import Avatar3 from '../../assests/img/avatars/avatar-3.png'
import Avatar4 from '../../assests/img/avatars/avatar-4.png'
import Avatar5 from '../../assests/img/avatars/avatar-5.png'
import Avatar6 from '../../assests/img/avatars/avatar-6.png'
import Avatar7 from '../../assests/img/avatars/avatar-7.png'
import Avatar8 from '../../assests/img/avatars/avatar-8.png'
import Avatar9 from '../../assests/img/avatars/avatar-9.png'
import Avatar10 from '../../assests/img/avatars/avatar-10.png'
import Avatar11 from '../../assests/img/avatars/avatar-11.png'
import Avatar12 from '../../assests/img/avatars/avatar-12.png'
import Avatar13 from '../../assests/img/avatars/avatar-13.png'
import Avatar14 from '../../assests/img/avatars/avatar-14.png'
import Avatar15 from '../../assests/img/avatars/avatar-15.png'
import Avatar16 from '../../assests/img/avatars/avatar-16.png'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { setAvatar as setProfilePicture } from '../../store/features/authSlice'
import { downloadFileFromPrivateBucket } from '../../utils/downloadFile'
import { colorsConfig } from '../../config/themeConfig'
import TextFieldSmall from '../../ui/TextFieldSmall'
import TextH1 from '../../ui/Text/TextH1'
import { countries } from '../../default-data/coutries'
import TextFieldSmallEdible from '../../ui/TextFieldSmallEdible'
import CountryField from './CountryField'
import modalCloseIcon from '../../assests/img/icons/icon-close.svg'
import RBox from '../../ui/RBox'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import UserSettings from './UserSettings'
import CompanySettings from './CompanySettings'
import BoxShadowed from '../../ui/Layout/BoxShadowed'

const ProfileManagement = () => {
  const dispatch = useDispatch()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')
  const extraLarge = useMediaQuery('(min-width: 1500px)')

  const defaultIcons = [
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
    Avatar7,
    Avatar8,
    Avatar9,
    Avatar10,
    Avatar11,
    Avatar12,
    Avatar13,
    Avatar14,
    Avatar15,
    Avatar16,
  ]

  const companyId = useSelector((state) => state.auth.companyId)

  const [user, setUser] = useState(null)
  const [license, setLicense] = useState(null)
  const [tradeLicenseModal, setTradeLicenseModal] = useState(false)
  const [tradeLicenseButtonDisalbed, setTradeLicenseButtonDisalbed] = useState(false)
  const [tradeLicenseUploaded, setTradeLicenseUploaded] = useState(null)
  const [downloadId, setDownloadId] = useState(null)
  const [tradeLicenseDropped, setTradeLicenseDropped] = useState(false)

  const [avatarModal, setAvatarModal] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [avatarButtonDisabled, setAvatarButtonDisabled] = useState(false)

  function formatDateToYYYYMMDD(date) {
    if (!date) {
      return ''
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const getUser = () => {
    privateRequest.get(API_ENDPOINTS.userInfo).then((response) => {
      setUser(response.data)
      if (response.data.avatar) {
        dispatch(setProfilePicture(`${CDN_BASE_URL}${response.data.avatar.avatar.path}`))
      } else {
        dispatch(setProfilePicture(Avatar1))
      }
    })
  }

  const handleSetLicense = (file) => {
    setLicense(file)
  }

  const handleUploadLicense = async () => {
    setTradeLicenseButtonDisalbed(true)
    const fileId = await uploadFile(license, FILE_TYPES.tradeLicense)
    setDownloadId(await fileId)
    privateRequest
      .put(API_ENDPOINTS.companyCredentials, {
        tradeLicense: fileId,
      })
      .then((response) => {
        setTradeLicenseModal(false)
        setLicense(null)
        getUser()
        toast.success('Trade license has been uploaded successfully')
      })
      .catch((error) => {
        toast.error('An error occurred while uploading a trade license')
      })
      .finally(() => {
        setTradeLicenseButtonDisalbed(false)
      })
  }

  const handleSetAvatar = (file, type) => {
    if (type && type === 'icon') {
      setAvatar({
        value: file,
        type: 'icon',
      })
      return
    }
    setAvatar({
      value: file,
      type: 'file',
    })
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const handleUploadAvatar = async () => {
    setAvatarButtonDisabled(true)
    let fileId
    if (avatar.type === 'icon') {
      fileId = await uploadFile(dataURLtoFile(avatar.value, 'icon.png'), FILE_TYPES.avatar)
    } else {
      fileId = await uploadFile(avatar.value, FILE_TYPES.avatar)
    }
    privateRequest
      .put(API_ENDPOINTS.changeAvatar, {
        avatar: fileId,
      })
      .then((response) => {
        toast.success('The profile has been uploaded successfully')
        setAvatarModal(false)
        setAvatar(null)
        getUser()
      })
      .catch((error) => {
        toast.error('An error occurred while uploading a profile picture')
      })
      .finally(() => {
        setAvatarButtonDisabled(false)
      })
  }

  useEffect(() => {
    getUser()
    dispatch(setCurrentPageTitle('Account Settings'))
  }, [])

  useEffect(() => {}, [downloadId])

  const handleDownloadFile = () => {
    const { id } = user.manager.company.tradeLicense

    privateRequest
      .get(
        API_ENDPOINTS.privateFiles(id),
        {},
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const { signedUrl } = response.data

        downloadFileFromPrivateBucket(signedUrl, 'trade_license')
      })
  }

  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false)
  const [isChangeLocationModalOpen, setIsChangeLocationModalOpen] = useState(null)

  const handleAddLocationOpenModal = () => {
    setIsAddLocationModalOpen(true)
    setCountry(countries.filter((item) => item.code === 'AE')[0])
  }

  const handleAddLocationCloseModal = () => {
    setIsAddLocationModalOpen(false)
    handleClearFormFields()
    setShowErrors(false)
  }

  const handleChangeLocationOpenModal = async (locationId, itemAddressId) => {
    setIsChangeLocationModalOpen(locationId)

    const locationItem = await getLocation(itemAddressId)
    handleFillFormFields(locationItem)
  }

  const handleChangeLocationCloseModal = () => {
    setIsChangeLocationModalOpen(null)
    handleClearFormFields()
    setShowErrors(false)
  }

  const [showErrors, setShowErrors] = useState(false)
  const [addressType, setAddressType] = useState('All')
  const [country, setCountry] = useState(countries.filter((item) => item.code === 'AE')[0])
  const [state, setState] = useState('')
  const [postcode, setPostcode] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [comment, setComment] = useState('')
  const [locationsInfo, setLocationsInfo] = useState([])
  const [addressTypes, setAddressTypes] = useState([])

  const checkErrors = () => {
    if (!country) return true
    if (!state) return true
    if (!addressLine1) return true
    return false
  }

  const getAddressTypes = () => {
    privateRequest
      .get(
        API_ENDPOINTS.addressTypes,
        {},
        {
          headers: {
            accept: 'application/json',
          },
        },
      )
      .then((response) => {
        setAddressTypes(response.data['hydra:member'])
      })
  }

  const handleFillFormFields = (item) => {
    const { addressType, state, country, postcode, addressLine1, addressLine2, comment } = item
    setAddressType(addressType ? addressType : 'All')
    setCountry(
      countries.filter((item) => item.label === country)[0]
        ? countries.filter((item) => item.label === country)[0]
        : null,
    )
    setState(state)
    setPostcode(postcode)
    setAddressLine1(addressLine1)
    setAddressLine2(addressLine2)
    setComment(comment)
  }

  const handleClearFormFields = () => {
    setAddressType('All')
    setCountry('')
    setState('')
    setPostcode('')
    setAddressLine1('')
    setAddressLine2('')
    setComment('')
  }

  const handleAddLocation = () => {
    if (checkErrors()) {
      setShowErrors(true)
      toast.error('Please fill correctly all the required fields')
      return
    }

    const addressTypeIri =
      addressType === 'All'
        ? null
        : addressTypes.filter((typeItem) => typeItem.name === addressType)[0]['@id']

    const formData = {
      type: addressTypeIri,
      country: country?.label,
      state,
      postcode,
      addressLine1,
      addressLine2,
      comment,
    }

    privateRequest
      .post(API_ENDPOINTS.newAddress, formData, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
      })
      .then((response) => {
        const id = response.data.id
        const addressIri = `addresses/${id}`

        privateRequest
          .post(
            API_ENDPOINTS.companyAddresses,
            {
              address: addressIri,
              isMain: true,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
              },
            },
          )
          .then((response) => {
            getAllLocations()
            handleAddLocationCloseModal()
          })
      })
  }

  const handleChangeLocation = (itemId) => {
    if (checkErrors()) {
      setShowErrors(true)
      toast.error('Please fill correctly all the required fields')
      return
    }

    const addressTypeIri =
      addressType === 'All'
        ? null
        : addressTypes.filter((typeItem) => typeItem.name === addressType)[0]['@id']
    const formData = {
      type: addressTypeIri,
      country: country ? country?.label : '',
      state,
      postcode,
      addressLine1,
      addressLine2,
      comment,
    }

    privateRequest
      .patch(API_ENDPOINTS.address(itemId), formData, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .then(() => {
        getAllLocations()
        handleChangeLocationCloseModal()
      })
  }

  const handleDeleteLocation = (itemId) => {
    privateRequest
      .delete(
        API_ENDPOINTS.companyAddress(itemId),
        {},
        {
          headers: {
            accept: '*/*',
          },
        },
      )
      .then(() => {
        getAllLocations()
      })
  }

  const getLocation = async (itemAddressId) => {
    const response = await privateRequest.get(
      API_ENDPOINTS.companyAddress(itemAddressId),
      {},
      {
        headers: {
          accept: 'application/json',
        },
      },
    )

    const locationItem = response.data
    const { type, id, state, country, postcode, addressLine1, addressLine2, comment } =
      locationItem.address
    const companyAddressId = +(locationItem['@id'].match(/\/(\d+)$/) || [])[1]
    const addressType =
      addressTypes.filter((typeItem) => typeItem['@id'] === type)[0]?.name || 'All'

    return {
      addressType,
      id,
      state,
      country,
      postcode,
      addressLine1,
      addressLine2,
      companyAddressId,
      comment,
    }
  }

  const getAllLocations = async () => {
    const response = await privateRequest.get(
      `${API_ENDPOINTS.companyAddresses}&isPrimary=false`,
      {},
      {
        headers: {
          accept: 'application/json',
        },
      },
    )

    const locationsInfoArray = await Promise.all(
      response.data['hydra:member'].map(async (item) => {
        const companyAddressId = +(item['@id'].match(/\/(\d+)$/) || [])[1]
        return getLocation(companyAddressId)
      }),
    )
    setLocationsInfo(locationsInfoArray)
  }

  useEffect(() => {
    getAddressTypes()
  }, [])

  useEffect(() => {
    if (addressTypes.length > 0) {
      getAllLocations()
    }
  }, [addressTypes])

  const getModalFormTextFields = () => {
    return (
      <Grid container spacing={'20px'} sx={{ marginBottom: '30px' }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            required
            fullWidth
            id="address-types"
            options={addressTypes ? ['All', ...addressTypes.map((type) => type.name)] : []}
            value={addressType}
            onChange={(e, newVal) => {
              setAddressType(newVal)
            }}
            renderInput={(params) => <TextFieldSmall {...params} label="Location type" required />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="address-country"
            options={[
              {
                label: null,
              },
              ...countries,
            ]}
            getOptionLabel={(option) => option.label || ''}
            fullWidth
            autoHighlight
            value={country}
            onChange={(e, newVal) => {
              setCountry(newVal)
            }}
            renderOption={(props, option) => {
              if (!option.label) return null
              return (
                <Box
                  component="li"
                  sx={{
                    '& > img': {
                      mr: 2,
                      flexShrink: 0,
                    },
                  }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                required
                {...params}
                label="Country"
                error={showErrors && !country}
                helperText={showErrors && !country ? 'This field can not be empty' : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            freeSolo
            autoHighlight
            value={state}
            onChange={(e, newVal) => {
              setState(newVal)
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                required
                {...params}
                id="address-state"
                fullWidth
                label={country?.code === 'AE' ? 'Emirate' : 'State'}
                value={state}
                onChange={(e) => {
                  setState(e.target.value)
                }}
                error={showErrors && !state}
                helperText={showErrors && !state ? 'This field can not be empty' : ''}
              />
            )}
            options={[user?.manager?.company?.address?.state]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldSmall
            id="address-postcode"
            fullWidth
            label="ZIP/postcode"
            value={postcode}
            inputProps={{
              inputMode: 'numeric',
            }}
            onChange={(e) => {
              setPostcode(e.target.value.toUpperCase())
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            freeSolo
            autoHighlight
            value={addressLine1}
            onChange={(e, newVal) => {
              setAddressLine1(newVal)
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                required
                id="address-line-1"
                fullWidth
                label="Address line 1"
                value={addressLine1}
                onChange={(e) => {
                  setAddressLine1(e.target.value)
                }}
                error={showErrors && !addressLine1}
                helperText={showErrors && !addressLine1 ? 'This field can not be empty' : ''}
              />
            )}
            options={[user?.manager?.company?.address?.addressLine1]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            fullWidth
            freeSolo
            autoHighlight
            value={addressLine2}
            onChange={(e, newVal) => {
              setAddressLine2(newVal)
            }}
            renderInput={(params) => (
              <TextFieldSmallEdible
                {...params}
                id="address-line-2"
                fullWidth
                label="Address line 2"
                value={addressLine2}
                onChange={(e) => {
                  setAddressLine2(e.target.value)
                }}
              />
            )}
            options={[user?.manager?.company?.address?.addressLine2]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldSmall
            id="address-comment"
            fullWidth
            type="textarea"
            multiline
            rows={4}
            maxRows={10}
            label="Comment"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <RBox
      className={tabletOnly ? 'tablet-only' : ''}
      sx={{
        '&.extra-large': {
          pl: '37px',
          pt: '15px',
        },
        '&.tablet-only': {
          px: '16px',
          pb: '30px',
        },
      }}
    >
      <TextBoldNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '24px',
          marginBottom: '10px',
          '&.xs-only': {
            fontSize: '18px',
            mt: '20px',
            mb: '30px',
          },
        }}
      >
        User Settings
      </TextBoldNormal>
      <RBox
        sx={{
          marginBottom: '50px',
          boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
          borderRadius: '6px',
          padding: '20px',
          paddingTop: '15px',
          paddingBottom: '30px',
          '&.xs-only': {
            boxShadow: 'none',
          },
        }}
      >
        {user ? (
          <Grid
            container
            spacing={xsOnly ? 0 : 3}
            sx={{
              marginTop: '-15px',
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '75px',
                    height: '75px',
                    marginBottom: '15px',
                    border: '5px solid #007DFF',
                    borderRadius: '50%',
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                      '& .edit-avatar': {
                        opacity: 1,
                        transition: '0.25s all ease-in-out',
                      },
                    },
                  }}
                >
                  {user.avatar ? (
                    <img
                      src={`${CDN_BASE_URL}${user.avatar.avatar.path}`}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  ) : (
                    <img src={Avatar1} style={{ width: '70%' }} alt="" />
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      background: 'rgba(0,0,0,0.25)',
                      opacity: 0,
                      transition: '0.25s all ease-in-out',
                    }}
                    className="edit-avatar"
                    onClick={() => {
                      setAvatarModal(true)
                    }}
                  >
                    <IconButton
                      className="edit-avatar"
                      sx={{
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <Edit sx={{ color: 'white' }} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: '10px',
                    alignSelf: 'start',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setAvatarModal(true)
                  }}
                >
                  <IconButton
                    sx={{
                      background: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <EditIcon editIconWidth={15} editIconHeight={15} editIconColour={'#007DFF'} />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        textDecorationLine: 'underline',
                        color: '#007DFF',
                        marginLeft: '3px',
                      }}
                    >
                      Change
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
              <UserSettings />
            </Grid>
          </Grid>
        ) : null}
      </RBox>
      <TextBoldNormal
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          fontSize: '24px',
          marginBottom: '10px',
          '&.xs-only': {
            fontSize: '18px',
            mb: '30px',
          },
        }}
      >
        Company Settings
      </TextBoldNormal>
      <BoxShadowed>
        <CompanySettings />
        <Grid container spacing={'24px'}>
          <Grid item sm={3} xs={12}>
            <Box
              sx={{
                width: '100px',
                position: 'relative',
                marginTop: '34px',
                '& .MuiButtonBase-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                },
              }}
            >
              <TextField
                variant="standard"
                disabled
                fullWidth
                InputLabelProps={{
                  style: {
                    paddingLeft: '36px',
                    marginTop: '-14px',
                    top: '-3px',
                    left: '0px',
                    '&.Mui-focused': {
                      color: 'black',
                    },
                    fontSize: '16px',
                  },
                }}
                type="file"
                InputProps={{
                  disableUnderline: true,
                  inputComponent: FileUploader,
                  inputProps: {
                    handleChange: handleSetLicense,
                    name: 'file',
                    types: ['JPG', 'PNG', 'jpeg', 'pdf'],
                    hoverTitle: 'Drop here',
                    label: 'Upload or drop all the photos here',
                    classes: 'upload-picture',
                    maxSize: 10,
                    onDrop: () => {
                      setTradeLicenseUploaded(false)
                      setTradeLicenseDropped(true)
                    },
                    children: (
                      <>
                        <Box
                          sx={{
                            borderRadius: '6px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '92px',
                            background: 'none',
                            cursor: 'pointer',
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            position: 'absolute',
                            top: '-21px',
                            left: 0,
                            fontSize: '13px',
                            color: '#00000061',
                            fontFamily: '"Helvetica", sans-serif',
                            letterSpacing: '0.00938em',
                            lineHeight: '1.4375em',
                          }}
                        >
                          Register copy
                        </Typography>
                      </>
                    ),
                  },
                }}
                sx={{
                  width: '86px',
                  position: 'relative',
                  top: '16px',
                  'label.is-disabled': {
                    border: 'none',
                    backgroundColor: '#F7FAFB',
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`,
                    borderRadius: '6px',
                  },
                  '& .MuiTypography-root': {
                    width: '100px',
                    marginTop: '-3px',
                  },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '64.5px',
                  left: '14.5px',
                  transform: 'translate(0, -50%)',
                }}
              >
                <svg
                  width="59"
                  height="65"
                  viewBox="0 0 59 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2495_70049)">
                    <path
                      d="M44.0399 27.9729V15.744C44.0399 15.3941 43.8784 15.0706 43.6496 14.8148L29.9391 0.417625C29.6831 0.148422 29.3194 0 28.956 0H7.21864C3.20545 0 0 3.27259 0 7.28604V48.3902C0 52.4037 3.20545 55.6226 7.21851 55.6226H24.377C27.6224 61.0096 33.5217 64.6189 40.2419 64.6189C50.4505 64.6189 58.7872 56.3227 58.7872 46.1007C58.8011 37.1714 52.3899 29.7103 44.0399 27.9731V27.9729ZM30.303 4.72753L39.5013 14.4107H33.5351C31.7573 14.4107 30.3029 12.9428 30.3029 11.1652L30.303 4.72753ZM7.21851 52.9288C4.70027 52.9288 2.6938 50.9085 2.6938 48.3904V7.28604C2.6938 4.75403 4.70027 2.6938 7.21851 2.6938H27.6092V11.1649C27.6092 14.4375 30.2625 17.1043 33.5351 17.1043H41.3461V27.6226C40.9424 27.6092 40.619 27.5687 40.2689 27.5687C35.5687 27.5687 31.2455 29.3736 27.9863 32.2019H10.8821C10.1411 32.2019 9.5352 32.8079 9.5352 33.5483C9.5352 34.2894 10.141 34.8952 10.8821 34.8952H25.4813C24.5249 36.2421 23.7302 37.589 23.1111 39.0705H10.882C10.141 39.0705 9.53508 39.6764 9.53508 40.4174C9.53508 41.1578 10.1409 41.7643 10.882 41.7643H22.2353C21.8986 43.1112 21.7236 44.606 21.7236 46.1008C21.7236 48.5248 22.1948 50.9224 23.0433 52.9426H7.21851V52.9288ZM40.2557 61.9389C31.5285 61.9389 24.4307 54.8411 24.4307 46.1141C24.4307 37.3868 31.5148 30.2891 40.2557 30.2891C48.9962 30.2891 56.0801 37.3868 56.0801 46.1141C56.0801 54.8411 48.9828 61.9389 40.2557 61.9389Z"
                      fill="#007DFF"
                    />
                    <path
                      d="M10.8821 28.1468H24.5249C25.2659 28.1468 25.8718 27.5405 25.8718 26.7999C25.8718 26.0591 25.266 25.4531 24.5249 25.4531H10.8821C10.1411 25.4531 9.53516 26.0589 9.53516 26.7999C9.53516 27.5405 10.141 28.1468 10.8821 28.1468Z"
                      fill="#007DFF"
                    />
                    <path
                      d="M41.3805 52.3086C40.963 52.8743 40.3128 53.222 39.6097 53.2566C38.9067 53.2915 38.2257 53.0086 37.7546 52.4863L33.5331 47.8096C32.8622 47.0668 32.9218 45.9217 33.6659 45.252C34.4107 44.5822 35.557 44.642 36.2279 45.3852L39.2146 48.6942C39.2589 48.7434 39.3231 48.7698 39.3892 48.7665C39.4555 48.7633 39.5162 48.7302 39.5558 48.6775L45.6949 40.3654C46.2891 39.5602 47.4247 39.3889 48.2316 39.9824C49.0378 40.5759 49.2091 41.7096 48.6145 42.5144L41.3805 52.3086Z"
                      fill="#007DFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2495_70049">
                      <rect width="59" height="64.619" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 30px)',
                  left: '80px',
                  background: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  marginTop: '9px',
                  width: 'fit-content',
                  padding: '10px 20px',
                }}
                onClick={() => {
                  console.log('Update function is mounted!')
                  setTradeLicenseModal(true)
                }}
              >
                <SvgIcon sx={{ fontSize: '16px', marginBottom: '3px' }}>
                  <svg
                    width="18"
                    height="24"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.16219 0.117059C8.09273 0.0439009 7.99753 0 7.89885 0H1.99027C0.899939 0 0 0.896359 0 1.98654V13.1597C0 14.25 0.899968 15.1464 1.99024 15.1464H10.0097C11.1 15.1464 12 14.25 12 13.1597V4.28773C12 4.19265 11.9561 4.10124 11.8939 4.03166L8.16219 0.117059ZM8.26828 1.2915L10.7671 3.91463H9.14272C8.65978 3.91463 8.26828 3.5268 8.26828 3.04392V1.2915ZM10.0097 14.4147H1.99024C1.30614 14.4147 0.731701 13.8477 0.731701 13.1597V1.98654C0.731701 1.30244 1.30244 0.731701 1.99024 0.731701H7.53658V3.04389C7.53658 3.93289 8.25372 4.64633 9.14272 4.64633H11.2683V13.1597C11.2683 13.8477 10.6975 14.4147 10.0097 14.4147Z"
                      fill="#007DFF"
                    />
                    <path
                      d="M3.31677 6.06879L3.18822 5.52632C3.17784 5.48277 3.15898 5.44169 3.13272 5.40542C3.10646 5.36916 3.07332 5.33842 3.03518 5.31497C2.99704 5.29151 2.95466 5.2758 2.91045 5.26872C2.86624 5.26165 2.82106 5.26335 2.77751 5.27373C2.73396 5.28411 2.69288 5.30297 2.65661 5.32923C2.62035 5.35549 2.58961 5.38863 2.56615 5.42677C2.5427 5.46491 2.52698 5.50729 2.51991 5.5515C2.51283 5.59571 2.51454 5.64088 2.52492 5.68444L2.86428 7.08688C2.88562 7.17411 2.94054 7.24938 3.0171 7.29632C3.09365 7.34326 3.18564 7.35808 3.27306 7.33755L4.67679 7.00204C5.11899 6.89406 4.95831 6.23076 4.51611 6.33874L3.84124 6.50199C4.19926 6.05002 4.68374 5.71486 5.23294 5.53922C5.78214 5.36359 6.37119 5.35543 6.92504 5.51578C7.4789 5.67613 7.97247 5.99773 8.34289 6.43962C8.7133 6.8815 8.94378 7.42365 9.00496 7.99699C9.04224 8.46233 9.74667 8.38649 9.6824 7.92372C9.48572 6.08036 7.91745 4.70748 5.91855 4.72676C4.74621 4.84246 3.89394 5.34636 3.31677 6.06879ZM7.54852 9.44186C7.08833 9.53698 7.25415 10.2299 7.70664 10.1052L8.38151 9.94191C8.02431 10.3955 7.53989 10.7322 6.99025 10.909C6.4406 11.0857 5.85073 11.0944 5.29609 10.934C4.74146 10.7736 4.2473 10.4514 3.87683 10.0086C3.50637 9.56573 3.27645 9.02243 3.2165 8.44819C3.21423 8.40182 3.2025 8.35641 3.18204 8.31473C3.16157 8.27306 3.13281 8.23601 3.09751 8.20586C3.06221 8.17571 3.02112 8.15309 2.97676 8.13939C2.9324 8.12569 2.88571 8.12121 2.83956 8.12621C2.7934 8.13121 2.74876 8.14559 2.70836 8.16847C2.66797 8.19136 2.63268 8.22225 2.60466 8.25927C2.57664 8.29628 2.55648 8.33863 2.54542 8.38372C2.53436 8.42881 2.53263 8.47568 2.54034 8.52146C2.64265 9.4666 3.11549 10.3326 3.85524 10.9297C4.59498 11.5268 5.54128 11.8063 6.48673 11.7069C6.9579 11.6562 7.41429 11.5123 7.82939 11.2837C8.24448 11.0551 8.61 10.7462 8.90469 10.3751L9.03581 10.9189C9.11937 11.3945 9.83794 11.2222 9.69654 10.7607L9.36103 9.35702C9.32247 9.19505 9.17336 9.0845 8.97796 9.09992L7.54852 9.44186Z"
                      fill="#007DFF"
                    />
                  </svg>
                </SvgIcon>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#007DFF',
                  }}
                >
                  Update
                </Typography>
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 'calc(50% + 8px)',
                  left: '80px',
                  background: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  marginTop: '9px',
                  width: 'fit-content',
                  padding: '10px 20px',
                }}
                onClick={() => {
                  handleDownloadFile()
                  // setTimeout(() => setTradeLicenseUploaded(null), 0);
                }}
              >
                <SvgIcon sx={{ fontSize: '16px', marginBottom: '3px' }}>
                  <svg
                    width="18"
                    height="24"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.16219 0.117059C8.09273 0.0439009 7.99753 0 7.89885 0H1.99027C0.899939 0 0 0.896359 0 1.98654V13.1597C0 14.25 0.899968 15.1464 1.99024 15.1464H10.0097C11.1 15.1464 12 14.25 12 13.1597V4.28773C12 4.19265 11.9561 4.10124 11.8939 4.03166L8.16219 0.117059ZM8.26828 1.2915L10.7671 3.91463H9.14272C8.65978 3.91463 8.26828 3.5268 8.26828 3.04392V1.2915ZM10.0097 14.4147H1.99024C1.30614 14.4147 0.731701 13.8477 0.731701 13.1597V1.98654C0.731701 1.30244 1.30244 0.731701 1.99024 0.731701H7.53658V3.04389C7.53658 3.93289 8.25372 4.64633 9.14272 4.64633H11.2683V13.1597C11.2683 13.8477 10.6975 14.4147 10.0097 14.4147Z"
                      fill="#007DFF"
                    />
                    <path
                      d="M2.98914 5.77643H9.0111C9.21227 5.77643 9.37695 5.61189 9.37695 5.41058C9.37695 5.20942 9.2123 5.04473 9.0111 5.04473H2.98544C2.78428 5.04473 2.61959 5.20939 2.61959 5.41058C2.61959 5.61189 2.78428 5.77643 2.98914 5.77643ZM7.87332 9.71305L6.36599 11.3339V7.33867C6.36599 7.13751 6.2013 6.97282 6.00013 6.97282C5.79882 6.97282 5.63425 7.13748 5.63425 7.33867V11.3338L4.12692 9.71305C4.05379 9.63631 3.95857 9.59599 3.85988 9.59599C3.77205 9.59599 3.68055 9.62891 3.61109 9.69479C3.46465 9.8338 3.45371 10.0643 3.59271 10.2106L5.73664 12.5119C5.80622 12.585 5.90133 12.6289 6.00371 12.6289C6.10622 12.6289 6.20133 12.585 6.27076 12.5118L8.41471 10.2106C8.55372 10.0643 8.54275 9.83019 8.39646 9.69479C8.24275 9.55576 8.01233 9.56673 7.87332 9.71305Z"
                      fill="#007DFF"
                    />
                  </svg>
                </SvgIcon>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#007DFF',
                  }}
                >
                  "Download"
                </Typography>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            padding: '4px 22px 40px',
            pl: '0',
            pb: '20px',
            mt: '30px',
            '&.xs-only': {
              px: 0,
              pb: 0,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '5px' }}>
            <Typography
              sx={{ fontSize: '18px', fontWeight: 700, fontStyle: 'normal', lineHeight: 'normal' }}
            >
              Manage locations
            </Typography>
            <IconButton onClick={handleAddLocationOpenModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
              >
                <circle cx="15" cy="15.8594" r="14.6053" stroke="#007DFF" stroke-width="0.789474" />
                <path
                  d="M22.651 15.5775H15.3939M15.3939 15.5775H8.13672M15.3939 15.5775L15.3939 8.32031M15.3939 15.5775L15.3939 22.8346"
                  stroke="#007DFF"
                  strokeWidth="1.57895"
                />
              </svg>
            </IconButton>
          </Box>

          <Grid container sx={{ flexDirection: 'column', gap: '10px' }}>
            {locationsInfo.map((locationItem) => {
              const {
                addressType,
                id,
                state,
                addressLine1,
                country,
                postcode,
                companyAddressId,
                comment,
              } = locationItem

              return (
                <Grid
                  key={id}
                  item
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    position: 'relative',
                    flexDirection: 'column',
                    paddingBottom: '10px',
                    borderBottom: `1px solid ${colorsConfig.lightGray}`,
                    marginRight: '8px',
                    '&.xs-only': {
                      pb: '45px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      marginBottom: '9px',
                    }}
                  >
                    {addressType}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      marginBottom: '4px',
                    }}
                  >
                    {[addressLine1, state, country, postcode]
                      .filter((item) => Boolean(item))
                      .join(', ')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: colorsConfig.lightGray,
                    }}
                  >
                    {comment}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '9px',
                      right: '-16px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '7px',
                    }}
                  >
                    <IconButton onClick={() => handleChangeLocationOpenModal(id, companyAddressId)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_5224_73180)">
                          <path
                            d="M18.2083 12.405C17.6783 12.405 17.25 12.8344 17.25 13.3633V21.03C17.25 21.558 16.8206 21.9883 16.2917 21.9883H2.875C2.34596 21.9883 1.91673 21.558 1.91673 21.03V7.61328C1.91673 7.08527 2.34596 6.65501 2.875 6.65501H10.5417C11.0717 6.65501 11.5 6.22564 11.5 5.69673C11.5 5.16769 11.0717 4.73828 10.5417 4.73828H2.875C1.28993 4.73828 0 6.02821 0 7.61328V21.03C0 22.6151 1.28993 23.905 2.875 23.905H16.2917C17.8768 23.905 19.1667 22.6151 19.1667 21.03V13.3633C19.1667 12.8334 18.7382 12.405 18.2083 12.405Z"
                            fill="#3D3D3D"
                          />
                          <path
                            d="M8.98607 11.5325C8.91946 11.5994 8.87384 11.6843 8.85481 11.7767L8.1773 15.1656C8.14572 15.3226 8.19553 15.4846 8.30856 15.5986C8.35316 15.6431 8.40609 15.6783 8.46431 15.7023C8.52254 15.7263 8.58492 15.7386 8.6479 15.7385C8.67844 15.7385 8.7102 15.7357 8.74178 15.729L12.1295 15.0515C12.2234 15.0322 12.3087 14.9873 12.3748 14.9201L19.9572 7.3377L16.5694 3.95014L8.98607 11.5325ZM22.2993 1.60706C21.365 0.672645 19.845 0.672645 18.9115 1.60706L17.5852 2.93329L20.973 6.32103L22.2993 4.99462C22.7516 4.54329 23.0008 3.94139 23.0008 3.30125C23.0008 2.66111 22.7516 2.05925 22.2993 1.60706Z"
                            fill="#3D3D3D"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5224_73180">
                            <rect
                              width="23"
                              height="23"
                              fill="white"
                              transform="translate(0 0.859375)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </IconButton>
                    <IconButton
                      sx={{ marginTop: '6px', padding: '9px 8px' }}
                      onClick={() => handleDeleteLocation(companyAddressId)}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: '#AC0000',
                        }}
                      >
                        Delete
                      </Typography>
                    </IconButton>
                  </Box>
                </Grid>
              )
            })}
          </Grid>

          <Modal open={isAddLocationModalOpen} onClose={handleAddLocationCloseModal}>
            <Box
              className={[xsOnly ? 'xs-only' : '', 'disable-scrollbar'].join(' ')}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '45px 37px 35px 42px',
                width: '768px',
                maxHeight: 'calc(100vh - 60px)',
                borderRadius: '10px',
                background: 'white',
                overflowY: 'auto',
                boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
                '&.xs-only': {
                  width: 'calc(100vw - 30px)',
                  maxHeight: 'calc(100vh - 30px)',
                },
              }}
            >
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '40px',
                  '&.xs-only': {
                    fontSize: '24px',
                    mb: '20px',
                  },
                }}
              >
                Add address
              </TextH1>
              {getModalFormTextFields()}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  fullWidth={xsOnly}
                  variant="contained"
                  sx={{
                    marginTop: '60px',
                    padding: '10px 60px',
                    textTransform: 'capitalize',
                    background: colorsConfig.mainBlue,
                  }}
                  onClick={handleAddLocation}
                >
                  Add
                </Button>
              </Box>
              <IconButton
                onClick={handleAddLocationCloseModal}
                sx={{ position: 'absolute', top: '8px', right: '8px' }}
              >
                <img src={modalCloseIcon} alt="" />
              </IconButton>
            </Box>
          </Modal>
          <Modal open={isChangeLocationModalOpen !== null} onClose={handleChangeLocationCloseModal}>
            <Box
              className={[xsOnly ? 'xs-only' : '', 'disable-scrollbar'].join(' ')}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '45px 37px 35px 42px',
                width: '768px',
                maxHeight: 'calc(100vh - 60px)',
                borderRadius: '10px',
                background: 'white',
                overflowY: 'auto',
                boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.10)',
                '&.xs-only': {
                  width: 'calc(100vw - 30px)',
                  maxHeight: 'calc(100vh - 30px)',
                },
              }}
            >
              <TextH1
                className={xsOnly ? 'xs-only' : ''}
                sx={{
                  mb: '40px',
                  '&.xs-only': {
                    fontSize: '24px',
                    mb: '20px',
                  },
                }}
              >
                Change address
              </TextH1>
              {getModalFormTextFields()}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  fullWidth={xsOnly}
                  variant="contained"
                  sx={{
                    marginTop: '60px',
                    padding: '10px 60px',
                    textTransform: 'capitalize',
                    background: colorsConfig.mainBlue,
                  }}
                  onClick={() => handleChangeLocation(isChangeLocationModalOpen)}
                >
                  Change
                </Button>
              </Box>
              <IconButton
                onClick={handleChangeLocationCloseModal}
                sx={{ position: 'absolute', top: '8px', right: '8px' }}
              >
                <img src={modalCloseIcon} alt="" />
              </IconButton>
            </Box>
          </Modal>
        </Box>
      </BoxShadowed>
      <Modal
        open={tradeLicenseModal}
        onClose={() => {
          setTradeLicenseModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -80%)',
            width: 400,
            background: 'white',
            border: '1px solid lightgray',
            borderRadius: '8px',
            padding: '20px',
          }}
        >
          {license ? (
            <Typography
              sx={{
                color: '#dddddd',
                mb: 2,
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              Pending to upload:{' '}
              <a className="link" href={URL.createObjectURL(license)} target="_blank">
                {license.name}
              </a>
            </Typography>
          ) : null}
          <FileUploader
            handleChange={handleSetLicense}
            name="file"
            types={['JPG', 'PNG', 'jpeg', 'pdf']}
            hoverTitle="Drop here"
            label="Upload or drop all the photos here"
            classes="upload-picture"
            maxSize={10}
            children={
              <Box
                sx={{
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px dashed #dddddd',
                  minHeight: '150px',
                  background: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography>Upload or drop your license here</Typography>
              </Box>
            }
          />
          <Button
            fullWidth
            variant="contained"
            color="success"
            sx={{
              mt: 2,
            }}
            disabled={!license || tradeLicenseButtonDisalbed}
            onClick={() => {
              handleUploadLicense()
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Modal
        open={avatarModal}
        onClose={() => {
          setAvatarModal(false)
        }}
        sx={
          {
            // overflow: 'auto',
            // paddingBottom: '20px',
            // paddingTop: '20px',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'center',
            // alignItems: 'center',
            // border: 'none !important'
          }
        }
      >
        <Box
          className={[xsOnly ? 'xs-only' : '', 'disable-scrollbar'].join(' ')}
          sx={{
            position: 'absolute',
            top: '50px',
            left: '50%',
            marginLeft: {
              xs: '-150px',
              sm: '-250px',
            },
            width: {
              xs: '300px',
              sm: '500px',
            },
            background: 'white',
            border: '1px solid lightgray',
            borderRadius: '8px',
            padding: '20px',
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
          }}
        >
          <FileUploader
            handleChange={handleSetAvatar}
            name="file"
            types={['JPG', 'PNG', 'jpeg', 'pdf']}
            hoverTitle="Drop here"
            label="Upload or drop all the photos here"
            maxSize={10}
            children={
              <IconButton
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: avatar ? 'none' : 'rgba(0,0,0,0.2)',
                  minWidth: '75px',
                  width: '75px',
                  height: '75px',
                  borderRadius: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  overflow: avatar && avatar.type === 'icon' ? 'visible' : 'hidden',
                  padding: 0,
                  '&:hover': {
                    background: 'rgba(0,0,0,0.2)',
                  },
                }}
              >
                {avatar ? (
                  <React.Fragment>
                    {avatar.type === 'icon' ? (
                      <Box>
                        <img
                          src={avatar.value}
                          style={{
                            maxWidth: '70%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Box>
                        <img
                          src={URL.createObjectURL(avatar.value)}
                          style={{
                            width: '100%',
                            objectFit: 'fill',
                          }}
                          alt=""
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ) : (
                  <CameraAlt
                    sx={{
                      color: 'white',
                      fontSize: '30px',
                    }}
                  />
                )}
              </IconButton>
            }
          />
          <Typography
            sx={{
              color: '#a3a3a3',
              mb: 2,
              mt: 2,
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Upload or Drop your profile picture here or select an icon
          </Typography>
          <Box>
            <Grid container spacing={3}>
              {defaultIcons.map((item, index) => {
                return (
                  <Grid key={index} item sm={3} xs={4}>
                    <Box
                      sx={{
                        width: '80px',
                        height: '80px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          '& .icon-img': {
                            filter: 'grayscale(0%) !important',
                            transform: 'scale(1.1)',
                            transition: '0.25s all ease-in-out',
                          },
                        },
                      }}
                      onClick={() => {
                        handleSetAvatar(item, 'icon')
                      }}
                    >
                      <img
                        style={{
                          maxWidth: '90%',
                          maxHeight: '100%',
                          objectFit: 'cover',
                          filter:
                            avatar && avatar.value === item ? 'grayscale(0%)' : 'grayscale(100%)',
                          transition: '0.25s all ease-in-out',
                        }}
                        src={item}
                        alt=""
                        className="icon-img"
                      />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="success"
            sx={{
              mt: 2,
            }}
            disabled={!avatar || avatarButtonDisabled}
            onClick={() => {
              handleUploadAvatar()
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </RBox>
  )
}

export default ProfileManagement
