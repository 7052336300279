import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  RadioGroup,
  FormHelperText,
} from '@mui/material'
import CollapseBlock from '../../../../ui/Accordions/CollapseBlock'
import TextFieldSmallEdible from '../../../../ui/TextFieldSmallEdible'
import { colorsConfig } from '../../../../config/themeConfig'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCollapseBlockState,
  setApplicationData,
  updateApplicationData,
} from '../../../../store/features/insuranceSlice'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import FormControlTFS from '../../../../ui/CustomSelect/FormControlTFS'
import InputLabelTFS from '../../../../ui/CustomSelect/InputLabelTFS'
import SelectTFS from '../../../../ui/CustomSelect/SelectTFS'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import TextNormal from '../../../../ui/Text/TextNormal'
import CustomRadio from '../../../../ui/CustomRadio'
import RBox from '../../../../ui/RBox'
import ButtonLG from '../../../../ui/Buttons/ButtonLG'
import { patchRequest, privateRequest } from '../../../../utils/request'
import { API_ENDPOINTS } from '../../../../config/default'
import useValidation from '../../../../hooks/useValidation'

const CarInsuranceBlockRequiredCarDetails = () => {
  const carData = useSelector((state) => state.car.carData)
  const selectedTab = useSelector((state) => state.insurance.selectedTab)
  const collapsed = useSelector(
    (state) => state.insurance.applications[selectedTab]?.collapseBlocksState?.carDetailsCollapsed,
  )
  const saved = useSelector(
    (state) => state.insurance.applications[selectedTab]?.collapseBlocksState?.carDetailsSaved,
  )
  const application = useSelector((state) => state.insurance.applications[selectedTab])
  const dispatch = useDispatch()

  const [modelYear, setModelYear] = useState('')
  const [carState, setCarState] = useState('')
  const [modelDetails, setModelDetails] = useState('')
  const [mileage, setMileage] = useState('')
  const [isModified, setIsModified] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const carPriceValidation = useValidation(application?.data?.carPrice, { isEmpty: true })
  const carStateValidation = useValidation(carState, { isEmpty: true })
  const modelDetailsValidation = useValidation(modelDetails, { isEmpty: true })
  const modelYearValidation = useValidation(modelYear, { isEmpty: true })
  const mileageValidation = useValidation(mileage, { isEmpty: true })
  const isModifiedValidation = useValidation(isModified, { isEmpty: true })

  const hasErrors = () => {
    if (modelYearValidation.hasErrors) return true
    if (carStateValidation.hasErrors) return true
    if (modelDetailsValidation.hasErrors) return true
    if (mileageValidation.hasErrors) return true
    if (isModifiedValidation.hasErrors) return true
    if (carPriceValidation.hasErrors) return true
    return false
  }

  const handleSubmit = () => {
    if (hasErrors()) {
      setShowErrors(true)
      return
    }
    setButtonDisabled(true)
    const formData = {
      modelYear: parseInt(modelYear),
      mileage: parseInt(mileage),
      details: modelDetails,
      state: carState === 'Used' ? 1 : 2,
      isModified: isModified === 'Yes',
    }
    privateRequest
      .put(carData?.independentCarInfo['@id'], formData)
      .then((response) => {
        const insuranceFormData = {
          carPrice: parseInt(application.data.carPrice),
          car: carData['@id'],
          startDate: new Date(),
          insuranceType: 'INSURANCE_TYPE_THIRD_PARTY',
        }
        privateRequest
          .post(API_ENDPOINTS.insurance.applications, insuranceFormData)
          .then((response) => {
            dispatch(
              updateApplicationData({
                id: application.id,
                data: {
                  '@id': response.data['@id'],
                  id: response.data.id,
                  carPrice: parseInt(response.data.carPrice),
                },
              }),
            )
          })
          .finally(() => setButtonDisabled(false))
      })
      .catch(() => setButtonDisabled(false))
  }

  useEffect(() => {
    if (carData.independentCarInfo.mileage) setMileage(carData.independentCarInfo.mileage)
    if (carData.independentCarInfo.details) setModelDetails(carData.independentCarInfo.details)
    if (carData.independentCarInfo.modelYear) setModelYear(carData.independentCarInfo.modelYear)
    if (carData.independentCarInfo.isModified !== null)
      setIsModified(carData.independentCarInfo.isModified ? 'Yes' : 'No')
    if (carData.independentCarInfo.state) {
      if (carData.independentCarInfo.state === 'CAR_STATE_NEW') {
        setCarState('New')
      }
      if (carData.independentCarInfo.state === 'CAR_STATE_USED') {
        setCarState('Used')
      }
    }
  }, [carData])

  return (
    <CollapseBlock
      title={'Car Details'}
      description={'Enter car details'}
      collapsed={collapsed}
      saved={saved}
      onCollapseChange={(value) => {
        dispatch(
          setCollapseBlockState({
            id: application.id,
            block: 'carDetailsCollapsed',
            value: value,
          }),
        )
      }}
    >
      <Grid container spacing={'24px'}>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Car price'}
            inputProps={{
              inputMode: 'numeric',
            }}
            value={sumDelimiter(application?.data?.carPrice ?? '')}
            onChange={(e) => {
              dispatch(
                setApplicationData({
                  id: application.id,
                  data: {
                    ...application?.data,
                    carPrice: e.target.value.replace(/[^0-9]/g, ''),
                  },
                }),
              )
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.lightGray,
                    }}
                  >
                    AED
                  </TextNormal>
                </InputAdornment>
              ),
            }}
            error={showErrors && carPriceValidation.hasErrors}
            helperText={showErrors ? carPriceValidation.errorText : ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlTFS required fullWidth error={showErrors && modelYearValidation.hasErrors}>
            <InputLabelTFS id={'label-model-year'}>Model year</InputLabelTFS>
            <SelectTFS
              labelId={'label-model-year'}
              value={modelYear}
              onChange={(e) => {
                setModelYear(e.target.value)
              }}
            >
              <MenuItem value={''}>Not chosen</MenuItem>
              <MenuItem value={'2010'}>2010</MenuItem>
              <MenuItem value={'2011'}>2011</MenuItem>
              <MenuItem value={'2012'}>2012</MenuItem>
              <MenuItem value={'2013'}>2013</MenuItem>
              <MenuItem value={'2014'}>2014</MenuItem>
              <MenuItem value={'2015'}>2015</MenuItem>
              <MenuItem value={'2016'}>2016</MenuItem>
              <MenuItem value={'2017'}>2017</MenuItem>
              <MenuItem value={'2018'}>2018</MenuItem>
              <MenuItem value={'2019'}>2019</MenuItem>
              <MenuItem value={'2020'}>2020</MenuItem>
              <MenuItem value={'2021'}>2021</MenuItem>
              <MenuItem value={'2022'}>2022</MenuItem>
              <MenuItem value={'2023'}>2023</MenuItem>
              <MenuItem value={'2024'}>2024</MenuItem>
            </SelectTFS>
            {showErrors && modelYearValidation.hasErrors ? (
              <FormHelperText sx={{ color: colorsConfig.mainRed }}>
                {modelYearValidation.errorText}
              </FormHelperText>
            ) : null}
          </FormControlTFS>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlTFS required fullWidth error={showErrors && carStateValidation.hasErrors}>
            <InputLabelTFS id={'label-car-state'}>Car state</InputLabelTFS>
            <SelectTFS
              labelId={'label-car-state'}
              value={carState}
              onChange={(e) => {
                setCarState(e.target.value)
              }}
            >
              <MenuItem value={''}>Not chosen</MenuItem>
              <MenuItem value={'New'}>New</MenuItem>
              <MenuItem value={'Used'}>Used</MenuItem>
            </SelectTFS>
            {showErrors && carStateValidation.hasErrors ? (
              <FormHelperText>{carStateValidation.errorText}</FormHelperText>
            ) : null}
          </FormControlTFS>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Model details'}
            value={modelDetails}
            onChange={(e) => {
              setModelDetails(e.target.value)
            }}
            error={showErrors && modelDetailsValidation.hasErrors}
            helperText={
              showErrors && modelDetailsValidation.hasErrors ? modelDetailsValidation.errorText : ''
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSmallEdible
            required
            fullWidth
            label={'Mileage'}
            type="text"
            inputProps={{
              inputMode: 'numeric',
            }}
            value={mileage !== '' ? sumDelimiter(parseInt(mileage)) : ''}
            onChange={(e) => {
              setMileage(e.target.value.replace(/[^0-9]/g, ''))
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <TextNormal
                    sx={{
                      fontSize: '14px',
                      color: colorsConfig.iconGray,
                    }}
                  >
                    km
                  </TextNormal>
                </InputAdornment>
              ),
            }}
            error={showErrors && mileageValidation.hasErrors}
            helperText={
              showErrors && mileageValidation.hasErrors ? mileageValidation.errorText : ''
            }
          />
        </Grid>
      </Grid>
      <RBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '34px',
          mt: '20px',
        }}
      >
        <TextNormal
          sx={{
            minWidth: '160px',
          }}
        >
          Is the car modified?
        </TextNormal>
        <FormControl error={showErrors && isModifiedValidation.hasErrors}>
          <RadioGroup
            row
            value={isModified}
            onChange={(e) => {
              setIsModified(e.target.value)
            }}
          >
            <FormControlLabel
              control={<CustomRadio />}
              value={'Yes'}
              label={'Yes'}
              sx={{ mr: '30px' }}
            />
            <FormControlLabel control={<CustomRadio />} value={'No'} label={'No'} />
          </RadioGroup>
        </FormControl>
      </RBox>
      <RBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonLG
          disabled={buttonDisabled || (showErrors && hasErrors())}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save and continue
        </ButtonLG>
      </RBox>
    </CollapseBlock>
  )
}

export default CarInsuranceBlockRequiredCarDetails
