import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, StepConnector } from '@mui/material'
import LayoutPublicMini from '../../../components/Layout/LayoutPublicMini'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { colorsConfig } from '../../../config/themeConfig'
import AdditionalInfoStep from '../../AddNewVehicle/Steps/AdditionalInfoStep'
import { useTranslation } from 'react-i18next'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import VehicleReducedDataStep from '../../AddNewVehicle/Steps/VehicleReducedDataStep'
import {
  getReducedMainValuesFormData,
  requiredFieldsInitialData,
} from '../../AddNewVehicle/addNewVehicleForm.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getEnumDetails } from '../../../store/features/references/references.actions'
import { useParams } from 'react-router-dom'
import {
  createCustomerCar,
  getCustomerAppraisal,
  submitCustomerAppraisal,
  updateCustomerCar,
} from '../../../store/features/customerAppraisal/customerAppraisal.actions'
import AppraisalRoot from './AppraisalRoot'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { CheckCircleRounded, EditRounded } from '@mui/icons-material'
import UITextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import useBreakpoints from '../../../hooks/useBreakpoints'
import UIStepCounter from '../../../ui/UIBoxes/UIStepCounter'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import ButtonPlain from '../../../ui/Buttons/ButtonPlain'
import IconEdit from '../../../assests/img/icons/icon-edit.svg'
import { isCarDataValid } from './appraisalByLink.helpers'
import { privateRequest } from '../../../utils/request'
import { API_ENDPOINTS } from '../../../config/default'

const AppraisalByLink = () => {
  const { t, i18n } = useTranslation()
  const { smDown } = useBreakpoints()
  const dispatch = useDispatch()
  const params = useParams()

  const isCreatingCar = useSelector(
    (state) => state.customerAppraisal.customer.car.create.isLoading,
  )
  const isUpdatingCar = useSelector(
    (state) => state.customerAppraisal.customer.car.update.isLoading,
  )
  const carFirstData = useSelector((state) => state.addCar.carFirstData)
  const car = useSelector((state) => state.customerAppraisal.customer?.car?.data)
  const isSubmittingAppraisal = useSelector(
    (state) => state.customerAppraisal.submitAppraisal.isLoading,
  )
  const customerAppraisal = useSelector((state) => state.customerAppraisal.customer.data)
  const enumDetails = useSelector((state) => state.ref.enumDetails.data)
  const submitAppraisalSuccess = useSelector(
    (state) => state.customerAppraisal.submitAppraisal.success,
  )

  const [activeStep, setActiveStep] = useState(0)
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [isFirstStepValid, setIsFirstStepValid] = useState(false)

  const [requiredFieldsData, setRequiredFieldsData] = useState(requiredFieldsInitialData)

  const localToken = localStorage.getItem('X-Appraisal-Token')

  const steps = [
    {
      label: t('step_customer_appraisal_vehicle_data'),
      component: (
        <VehicleReducedDataStep
          showErrors={showErrors}
          handleNext={() => setActiveStep(activeStep + 1)}
          formData={requiredFieldsData}
          setFormData={setRequiredFieldsData}
        />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: t('step_customer_appraisal_appraisal'),
      component: (
        <AppraisalRoot carId={car?.id} activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
      nextButtonText: t('button_next_step'),
    },
    {
      label: t('step_customer_appraisal_additional_information'),
      component: <AdditionalInfoStep />,
      nextButtonText: t('button_park_car_to_virtual_garage'),
      nextButtonTextMobile: t('button_add_car'),
    },
  ]

  const handleNext = async () => {
    if (activeStep === 0) {
      const coreData = {
        vin: carFirstData.vin.value,
        make: carFirstData.make.id,
        model: carFirstData.model.id,
      }
      const mainData = getReducedMainValuesFormData(requiredFieldsData)
      if (!isCarDataValid(coreData, mainData)) {
        setIsFirstStepValid(false)
        setShowErrors(true)
        return
      }
      if (car && car?.id) {
        const res = await dispatch(
          updateCustomerCar({
            data: {
              ...coreData,
              ...mainData,
            },
            token: params?.token,
          }),
        )
        if (updateCustomerCar.fulfilled.match(res)) {
          setActiveStep(activeStep + 1)
        }
      } else {
        const res = await dispatch(createCustomerCar({ coreData, mainData, token: params?.token }))
        if (createCustomerCar.fulfilled.match(res)) {
          setActiveStep(activeStep + 1)
        }
      }
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1)
    }
    if (activeStep === 2) {
      const res = await dispatch(submitCustomerAppraisal({ token: params?.token }))
      if (submitCustomerAppraisal.fulfilled.match(res)) {
        setDisplaySuccessMessage(true)
      }
    }
  }

  const handleSetToken = () => {
    localStorage.setItem('X-Appraisal-Token', params?.token)
  }

  const handleInit = async () => {
    dispatch(getCustomerAppraisal({ token: params?.token }))
  }

  const handleGetEnumDetails = async () => {
    await dispatch(getEnumDetails())
  }

  useEffect(() => {
    if (showErrors) {
      const coreData = {
        vin: carFirstData.vin.value,
        make: carFirstData.make.id,
        model: carFirstData.model.id,
      }
      const mainData = getReducedMainValuesFormData(requiredFieldsData)
      setIsFirstStepValid(isCarDataValid(coreData, mainData))
    }
  }, [showErrors, carFirstData, requiredFieldsData])

  useEffect(() => {
    if (!customerAppraisal?.car && enumDetails?.length > 0) {
      setRequiredFieldsData({ ...requiredFieldsData, origin: 15 })
    }
  }, [enumDetails, customerAppraisal])

  useEffect(() => {
    if (localToken) {
      handleGetEnumDetails()
    }
  }, [i18n.language, localToken])

  useEffect(() => {
    handleSetToken()
    handleInit()
    return () => {
      localStorage.removeItem('X-Appraisal-Token')
    }
  }, [])

  return (
    <LayoutPublicMini>
      <Stack gap={{ xs: '15px', sm: '30px' }} sx={{ position: 'relative' }}>
        {isSubmittingAppraisal && <UIPreloader text={t('preloader_submitting_appraisal')} />}
        {displaySuccessMessage && (
          <Box
            sx={{
              position: { xs: 'fixed', sm: 'absolute' },
              top: { sm: 0, xs: '90px' },
              left: { sm: '-20px', xs: 0 },
              width: {
                xs: '100vw',
                sm: 'calc(100% + 40px)',
              },
              height: { xs: 'calc(100vh - 90px)', sm: '100%' },
              background: 'white',
              zIndex: 11,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: {
                xs: '16px',
                sm: 0,
              },
            }}
          >
            <Stack gap="30px" alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                sx={{ position: 'relative', ml: '-15px' }}
              >
                <CheckCircleRounded sx={{ fill: colorsConfig.mainGreen, fontSize: '32px' }} />
                <UITextHeadlineSmall text={t('common_thank_you')} />
              </Stack>
              <UITextRegular
                sx={{ textAlign: { xs: 'center', sm: 'left' } }}
                text={t('p_customer_appraisal_submit_appraisal_success')}
              />
              {/*<ButtonPlain*/}
              {/*  sx={{*/}
              {/*    gap: '10px',*/}
              {/*    px: '15px',*/}
              {/*    fontSize: '16px',*/}
              {/*    fontWeight: 700,*/}
              {/*    alignItems: 'center',*/}
              {/*  }}*/}
              {/*  onClick={() => {*/}
              {/*    setDisplaySuccessMessage(false)*/}
              {/*    setActiveStep(0)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <img width="20px" src={IconEdit} alt="" />*/}
              {/*  Edit appraisal*/}
              {/*</ButtonPlain>*/}
            </Stack>
          </Box>
        )}
        {smDown ? (
          <Stack direction="row" gap="10px" alignItems="center">
            <UIStepCounter current number={activeStep + 1} />
            <UITextTitleSmall text={steps[activeStep]?.label} />
          </Stack>
        ) : (
          <Stepper sx={{ mt: '50px' }} activeStep={activeStep} connector={<StepConnector />}>
            {steps.map((stepItem, index) => {
              return (
                <Step key={index}>
                  <Stack direction="row" alignItems="center" gap="10px">
                    <UIStepCounter
                      completed={activeStep > index}
                      current={activeStep === index}
                      number={index + 1}
                    />
                    <UITextRegular text={stepItem?.label} />
                  </Stack>
                </Step>
              )
            })}
          </Stepper>
        )}

        <Grid
          container
          sx={{
            position: 'relative',
            minHeight: '200px',
            ...(smDown && {
              pb: '50px',
            }),
          }}
        >
          {isCreatingCar || isUpdatingCar ? (
            <UIPreloader text={t('preloader_saving_data')} />
          ) : null}
          {steps[activeStep].component}
        </Grid>
        {activeStep !== 1 && (
          <Stack
            direction="row"
            gap="10px"
            justifyContent="flex-end"
            sx={{
              ...(smDown && {
                position: 'fixed',
                bottom: 0,
                right: 0,
                width: '100vw',
                p: '16px',
                background: 'white',
                zIndex: 10,
                boxShadow: '0 0 24px 0 rgba(255,255,255,.5)',
                '& button': {
                  flex: 1,
                  maxWidth: activeStep === 0 ? '100%' : 'calc(50% - 5px)',
                },
              }),
            }}
          >
            {activeStep !== 0 && (
              <ButtonHollow onClick={() => setActiveStep(activeStep - 1)}>Back</ButtonHollow>
            )}
            <ButtonLG disabled={!isFirstStepValid && showErrors} onClick={() => handleNext()}>
              {activeStep === 2
                ? smDown
                  ? t('button_submit')
                  : t('button_submit_appraisal')
                : t('button_next')}
            </ButtonLG>
          </Stack>
        )}
      </Stack>
    </LayoutPublicMini>
  )
}

export default AppraisalByLink
