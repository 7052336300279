export const API_BASE_URL = process.env.REACT_APP_API_URL
export const APP_BASE_URL = process.env.REACT_APP_BASE_URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL
export const MERCURE_BASE_URL = process.env.REACT_APP_BASE_MERCURE
export const FINANCE_CALCULATOR_API_BASE_URL = process.env.REACT_APP_FINANCE_CALCULATOR_API_BASE_URL
export const FINANCE_ADMIN_URL = process.env.REACT_APP_FINANCE_ADMIN_URL
export const SHOW_DEV_COMPONENTS = process.env.REACT_APP_SHOW_DEV_COMPONENTS

export const API_ENDPOINTS = {
  login: '/authentication_token',
  refresh: '/authentication_token/refresh',
  register: '/register-manager-demands',
  resetPasswordTokenRequest: '/reset-password',
  resetPasswordChangeRequest: '/change-password',
  userInfo: '/user',
  makes: '/reference/catalog/makes',
  models: '/reference/catalog/models',
  carWorkflows: '/car-workflow-states',
  auctionWorkflows: '/auction-workflow-states',
  dealWorkflows: '/deal-workflow-states',
  companies: '/companies?pagination=false',
  carPdfAppraisalResult: (id) => {
    return `/cars/${id}/pdf-appraisal-result`
  },
  privateFiles: (id) => {
    return `/private-files/${id}`
  },
  //cars: '/cars',
  cars: {
    newCars: '/cars?pagination=false&isArchived=false&combinedStatus[]=car_workflow.new',
    companyCars: '/cars?pagination=false&isArchived=false',
    carsForAuction:
      '/cars?pagination=false&isArchived=false&combinedStatus[]=car_workflow.ready_for_sale&combinedStatus[]=auction_workflow.pending&combinedStatus[]=auction_workflow.active&combinedStatus[]=auction_workflow.completed',
    statusCount: '/cars-combined-status-counts',
    default: '/cars',
    archivedCars: '/cars?pagination=false&isArchived=true',
    carsForAppraisal:
      '/cars?pagination=false&isArchived=false&combinedStatus[]=car_workflow.appraisal_on_site&combinedStatus[]=car_workflow.quality_check&combinedStatus[]=car_workflow.moderation_failed&combinedStatus[]=car_workflow.requested_appraisal_compound&combinedStatus[]=car_workflow.awaiting_appraisal_compound',
  },
  carId: (carId) => {
    return `/cars/${carId}`
  },
  file: '/files',
  foreshortenings: '/reference/appraisal/foreshortenings',
  appraisalByCar: (id) => {
    return `/cars/${id}/car-appraisal-aggregate`
  },
  appraisalByAuction: (id) => {
    return `/auctions/${id}/car-appraisal-aggregate`
  },
  rollbackToInitial: (id) => {
    return `/cars/${id}/workflow/rollback-to-initial`
  },
  failRequestedCompoundAppraisal: (id) => {
    return `/cars/${id}/workflow/fail-requested-compound-appraisal`
  },
  revertFromAppraisalCompoundFailed: (id) => {
    return `/cars/${id}/workflow/revert-from-appraisal-compound-failed`
  },
  carParts: '/reference/appraisal/car-parts',
  damages: '/damages',
  pilotLamps: '/reference/appraisal/pilot-lamps',
  controlPointsBlock: '/reference/appraisal/blocks',
  controlPoints: '/reference/appraisal/control-points',
  controlPointById: (carId, controlPointId) => `/cars/${carId}/control-points/${controlPointId}`,
  carControlPoints: (carId) => `/cars/${carId}/control-points`,
  carPhotosBatch: '/foreshortenings/batch',
  controlPointFileCollectionDelete: (id) => `/file-collection-control-point-value-files/${id}`,
  carPhotos: '/foreshortenings',
  carPhotosDirectBatch: '/foreshortenings/direct-batch',
  carSpecsOptionsBlocks: '/reference/appraisal/spec-blocks',
  carSpecsOptions: '/reference/appraisal/specs',
  carSpecs: {
    default: '/specs?pagination=false',
    batch: '/specs/batch',
  },
  comments: '/comments',
  lastAuction: '/last-auction',
  carAppraisalChecksResource: '/appraisal-checks-resource',
  lastDeal: (carId) => {
    return `/cars/${carId}/last-deal`
  },
  carDamages: (carId) => {
    return `cars/${carId}/damages`
  },
  carPilotLamp: (carId) => {
    return `cars/${carId}/pilot-lamps`
  },
  subblocksByBlock: (blockId) => {
    return `/reference/appraisal/blocks/${blockId}/subblocks`
  },
  submitCarControlPoints: (carId) => {
    return `cars/${carId}/control-points/batch`
  },
  auctions: '/auctions?currentWorkflowState=active',
  myAuctions: (companyId) => {
    return `/auctions?car.company=${companyId}&currentWorkflowState[]=active&currentWorkflowState[]=pending&currentWorkflowState[]=completed`
  },
  // myActiveAuctions
  // myPendingAuctions
  // myCompletedAuctions
  auctionsById: (id) => {
    return `/auctions/${id}`
  },
  auctionsByCompany: (id) => {
    return `/auctions?car.company=${id}`
  },
  bids: '/bids',
  qualityCheckHints: '/quality-check-hints',
  // workflow transitions
  startOnSiteAppraisal: '/workflow/start-onsite-appraisal',
  completeOnSiteAppraisal: '/workflow/complete-onsite-appraisal',
  onSiteAppraisalModerationSuccess: '/workflow/succeed-onsite-appraisal-moderation',
  onSiteAppraisalModerationFail: '/workflow/fail-onsite-appraisal-appraisal-moderation',
  requestCompound: '/workflow/request-compound',
  sentToCompound: (id) => {
    return `/cars/${id}/workflow/send-to-compound`
  },
  startAppraisalOnCompound: (id) => {
    return `/cars/${id}/workflow/start-compound-appraisal`
  },
  completeAppraisalOnCompound: '/workflow/complete-compound-appraisal',
  startAuction: '/workflow/start-auction',
  activateAuction: (id) => {
    return `/auctions/${id}/activate`
  },
  finishAuction: (id) => {
    return `/auctions/${id}/complete`
  },
  auctionSell: (id) => {
    return `/auctions/${id}/workflow/sell`
  },
  auctionDoNotSell: (id) => {
    return `/auctions/${id}/workflow/will-not-sell`
  },
  purchasedCars: (companyId) => {
    return `/deals?buyer=${companyId}&currentWorkflowState[]=pending&currentWorkflowState[]=invoice_sent&currentWorkflowState[]=money_received&currentWorkflowState[]=money_payed&currentWorkflowState[]=transportation_in_progress&currentWorkflowState[]=succeed`
  },
  sellingDeals: (companyId) => {
    return `/deals?seller=${companyId}&currentWorkflowState[]=pending&currentWorkflowState[]=invoice_sent&currentWorkflowState[]=money_received&currentWorkflowState[]=money_payed&currentWorkflowState[]=transportation_in_progress`
  },
  deals: '/deals?pagination=false',
  dealsPaymentRequired: '/deals?pagination=false&currentWorkflowState[]=invoice_sent',
  dealWorkflowTransition: {
    invoiceSent: '/workflow/confirm-invoice-sent',
    moneyReceived: '/workflow/confirm-money-received',
    moneyPayed: '/workflow/confirm-money-payed',
    sendToTransportation: '/workflow/send-to-transportation',
    soldAndDelivered: '/workflow/sold-and-delivered',
    cancel: '/workflow/cancel-the-deal',
  },
  transportApplication: '/transport-application',
  cancelAuction: '/workflow/cancel',
  resetCarToReadyForSaleFromDealCancelled: '/last-deal/rollback-car',
  sellCar: '/workflow/sell',
  doNotSellCar: '/workflow/will-not-sell',
  addPurchasedCar: '/obtain-car-to-the-buyer',
  allCarLoggingNodes: '/car-logging-nodes',
  carLoggingNodes: '/logging-nodes',
  mainPicture: '/main-foreshortening-photo',
  mainForeshortening: '/main-foreshortening',
  managerCredentials: '/user/profile/manager/credentials',
  companyCredentials: '/user/profile/manager/company/credentials',
  addressCredentials: '/user/profile/manager/company/address/credentials',
  changeEmail: '/user/profile/change-email',
  changePassword: '/user/profile/change-password',
  changeAvatar: '/user/profile/change-avatar',
  pickupDemand: (id) => {
    return `/cars/${id}/pickup-demand`
  },
  registerManagerDemands: '/register-manager-demands?pagination=false',
  registerManagerDemandsApprove: (id) => {
    return `/register-manager-demands/${id}/approve`
  },
  registerManagerDemandsReject: (id) => {
    return `/register-manager-demands/${id}/disapprove`
  },
  registerManagerDemandsTradeLicense: (id) => {
    return `/webhook/register/register-manager-demands/${id}/trade-license`
  },
  coins: {
    deposit: '/coin-transactions/deposit',
    withdraw: '/coin-transactions/withdraw',
    transactions: '/coin-transactions',
    depositDemand: '/coin-deposit-demands',
  },
  notificationLogs: '/notification-logs?pagination=false',
  markNotificationAsRead: (id) => {
    return `notification-logs/${id}/mark-as-read`
  },
  markAllNotificationsAsRead: '/notification-logs/mark-all-as-read',
  addressTypes: '/address-types',
  addressType: (id) => {
    return `/address-types/${id}`
  },
  newAddress: '/addresses',
  address: (id) => {
    return `/addresses/${id}`
  },
  companyAddresses: '/company-addresses?pagination=false&isMain=true',
  newCompanyAddress: '/company-addresses?pagination=false',
  companyAddress: (id) => {
    return `/company-addresses/${id}`
  },
  vinProvider: '/vin-provider/car-details',
  managerById: (id) => `/managers/${id}`,
  invoices: '/invoices',
  managers: '/managers',
  blockUser: (id) => `/admin/users/${id}/block`,
  unblockUser: (id) => `/admin/users/${id}/unblock`,
  resetUserPassword: (id) => `/admin/users/${id}/reset-password`,
  coinPricings: '/coin-pricings',
  locale: '/user/profile/locale',
  insurance: {
    independentCarInfo: (id) => `/independent-car-infos/${id}`,
    applications: '/insurance/insurance-applications',
    archiveApplication: (id) => `/insurance/insurance-applications/${id}/archive`,
    applicationById: (id) => `/insurance/insurance-applications/${id}`,
    risks: '/reference/insurance/risks',
    offersByRisks: (id) => `/insurance/insurance-applications/${id}/offers/by-risks`,
    offers: '/insurance/offers',
    preCalcRisks: '/insurance/pre-calc-risks',
    companies: '/reference/insurance/insurance-companies',
    workflows: {
      driverDetailsSaved: (id) =>
        `/insurance/insurance-applications/${id}/workflow/confirm-to-application-preparation`,
      sendToCompanies: (id) =>
        `/insurance/insurance-applications/${id}/workflow/confirm-to-approval-process-started`,
    },
    deals: '/insurance/insurance-deals',
    dealsByApplications: (id) => `/insurance/insurance-applications/${id}/insurance-deals`,
    dealByApplication: (applicationId, dealId) =>
      `/insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}`,
    sendCustomerApprovalLink: (id) =>
      `/insurance/insurance-applications/${id}/send-customer-approved-email`,
    approveCustomerDeal: (applicationId, dealId) =>
      `/insurance/customer/insurance-applications/${applicationId}/insurance-deals/${dealId}`,
    customerApplications: (id) => `/insurance/customer/insurance-applications/${id}`,
    dealWorkflows: {
      approved: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-approved`,
      requestInfo: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-insurance-company-request-info`,
      declined: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-insurance-company-declined`,
      companySent: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-insurance-company-sent`,
      customerDeclined: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-customer-declined`,
      approvalRequested: (applicationId, dealId) =>
        `insurance/insurance-applications/${applicationId}/insurance-deals/${dealId}/workflow/confirm-to-approval-requested`,
    },
  },
}

export const API_FINANCE_ENDPOINTS = {
  loanPrograms: {
    calculationSummary: '/loan-programs/calculation/terms',
  },
  loanApplications: `/finance/loan-applications?isArchived=false`,
  loanApplicationForCustomer: (secureHash) => `/finance/customer/loan-applications/${secureHash}`,
  loanDealApprovedByCustomer: (secureHash, loanDealId) =>
    `/finance/customer/loan-applications/${secureHash}/loan-deals/${loanDealId}`,
  loanApplicationById: (loanApplicationId) => `/finance/loan-applications/${loanApplicationId}`,
  loanApplicationArchive: (loanApplicationId) =>
    `/finance/loan-applications/${loanApplicationId}/archive`,
  loanDeals: '/finance/loan-deals?pagination=false',
  loanDealById: (id) => `/finance/loan-deals/${id}`,
  loanDealLoggingNodes: (id) => `/finance/loan-deals/${id}/logging-nodes`,
  loanApplicationWorkflowTransitions: {
    customerDataSaved: (id) =>
      `/finance/loan-applications/${id}/workflow/confirm-to-application-preparation`,
    sendToBanks: (id) =>
      `/finance/loan-applications/${id}/workflow/confirm-to-approval-process-started`,
  },
  sendCustomerApprovalLink: (id) => `/finance/loan-applications/${id}/send-customer-approved-email`,
  loanDealByLoanApplication: (loanDealId, loanApplicationId) =>
    `/finance/loan-applications/${loanApplicationId}/loan-deals/${loanDealId}`,
  loanDealWorkflowTransitions: {
    loanDealApproved: (loanApplicationId, loanDealId) =>
      `/finance/loan-applications/${loanApplicationId}/loan-deals/${loanDealId}/workflow/confirm-to-approved`,
    loanDealRequestInfo: (loanApplicationId, loanDealId) =>
      `/finance/loan-applications/${loanApplicationId}/loan-deals/${loanDealId}/workflow/confirm-to-bank-request-info`,
    loanDealDeclined: (loanApplicationId, loanDealId) =>
      `/finance/loan-applications/${loanApplicationId}/loan-deals/${loanDealId}/workflow/confirm-to-bank-declined`,
    loanDealBankSent: (loanApplicationId, loanDealId) =>
      `/finance/loan-applications/${loanApplicationId}/loan-deals/${loanDealId}/workflow/confirm-to-approval-requested`,
  },
}

export const API_FINANCE_ADMIN_ENDPOINTS = {
  productProviders: '/reference/product-provider/product-providers',
  loanPrograms: '/reference/product-provider/loan-programs',
  productTypes: '/reference/product-provider/product-types',
  products: '/reference/product-provider/products',
}

export const FILE_TYPES = {
  foreshortening: 'foreshortening',
  damage: 'damage',
  controlPointDocument: 'control_point_document',
  avatar: 'avatar',
  tradeLicense: 'trade_license',
  appraisalPdf: 'appraisal_pdf',
  default: 'default',
  customerFile: 'customer_file',
  loanDealAttachment: 'loan_deal_attachment',
}

export const HINT_TYPES = {
  damage: 'CarDamageQualityCheckHint',
  damages: 'DamagesQualityCheckHint',
  vehicleDetails: 'VehicleDetailsQualityCheckHint',
  photos: 'PhotosQualityCheckHint',
  photo: 'CarForeshorteningQualityCheckHint',
  warningLights: 'PilotLampsQualityCheckHint',
  subBlocks: 'SubblockQualityCheckHint',
}

export const DEALER_PRODUCTS_TYPES = {
  casco: 'DEALER_PRODUCT_CLASSIFICATION_CASCO',
  warranty: 'DEALER_PRODUCT_CLASSIFICATION_EXTENDED_WARRANTY',
  ppi: 'DEALER_PRODUCT_CLASSIFICATION_PPI',
}

export const pricingNames = [
  {
    systemName: 'quality_check',
    name: 'Quality check',
  },
  {
    systemName: 'vin_provider_request',
    name: 'VIN-check autofill',
  },
  {
    systemName: 'transit_loan_deals_to_approval',
    name: 'Finance request to banks',
  },
]

export const excludedPricings = ['appraisal_pdf', 'example']

export const currentLocales = [
  {
    systemName: 'de',
    name: 'German',
  },
  {
    systemName: 'fr',
    name: 'French',
  },
  {
    systemName: 'it',
    name: 'Italian',
  },
]

export const insuranceWorkflowStates = {
  blank: 'blank',
  new: 'new',
  driverInfoSaved: 'driver_info_saved',
  requested_quotes: 'requested_quotes',
}
