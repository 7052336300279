import { privateRequest } from '../../utils/request'

class CompanyAddressesApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      companyAddresses: '/company-addresses',
    }
  }

  getCompanyAddresses = async (params) => {
    const response = await this.#api.get(this.#endPoints.companyAddresses, {
      params,
    })
    return response
  }
}

const companyAddressesApiService = new CompanyAddressesApiService({ api: privateRequest })

export default companyAddressesApiService
