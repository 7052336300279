import { toast } from 'react-toastify'
import { apiConstants } from './api'

export const APIErrors = {
  NETWORK_ERROR: 'Network error, please check your connection',
}

export const handleDefaultError = (error) => {
  if (!error.response) {
    toast.error(error)
  }
  if (error?.response?.data) toast.error(error?.response?.data?.[apiConstants.hydraDescription])
}
