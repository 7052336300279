import {
  initialDealApprovedData,
  initialRiskDetails,
  insuranceDealWorkflowTypes,
} from './operator-insurance.constants'
import { Box } from '@mui/material'
import { sumDelimiter, sumDelimiterSpaced } from '../../../utils/sumDelimiter'
import { handleFormatDate } from '../../../utils/formatDate'
import UIMarkerCircle from '../../../ui/Markers/UIMarkerCircle'
import TextNormal from '../../../ui/Text/TextNormal'
import { getRequestMarkerClass } from '../../../utils/date.helpers'

const defaultRiskName = 'Third party liability'

export const displayRequestedCoverage = (data) => {
  return data.filter((item) => item.name !== defaultRiskName).map((item) => item.name)
}

export const getInsuranceCompanyName = (referenceCompanies, company) => {
  return referenceCompanies.find((item) => item['@id'] === company)?.name
}

export const getSavedResponseClassName = (currentWorkflowState) => {
  if (currentWorkflowState === 'insurance_company_request_info') {
    return 'attention'
  }
  if (currentWorkflowState === 'approved') {
    return 'success'
  }
  if (currentWorkflowState === 'insurance_company_declined') {
    return 'error'
  }
}

export const getTotalPremium = (inputString) =>
  inputString && inputString !== '' && parseInt(inputString) !== 0
    ? `${sumDelimiterSpaced(parseInt(inputString))} AED`
    : '-'

export const getMonthlyInstallment = (inputString) =>
  inputString && inputString !== '' && parseInt(inputString) !== 0
    ? `${sumDelimiterSpaced((parseInt(inputString) / 12).toFixed(0))} AED`
    : '-'

export const getDealerCommission = (dealerCommission, total) => {
  if (
    !dealerCommission ||
    dealerCommission === '' ||
    parseInt(dealerCommission) === 0 ||
    parseInt(total) === 0
  )
    return '-'
  const outputNumber = sumDelimiterSpaced(parseInt(dealerCommission))
  const outputPercentage = ((parseInt(dealerCommission) * 100) / parseInt(total)).toFixed(2)
  return `${outputNumber} AED (${outputPercentage} %)`
}

export const parseSelectedRisks = (selectedRisks) => {
  return selectedRisks.map((item) => {
    return {
      ...item,
      risksDetails: {
        ...initialRiskDetails,
        risk: item['@id'],
      },
    }
  })
}

export const parseInsuranceDeal = (data, selectedRisks) => {
  return {
    ...data,
    currentStatus:
      insuranceDealWorkflowTypes.find((item) => item.systemName === data.currentWorkflowState)
        ?.name || '',
    risks: parseSelectedRisks(selectedRisks),
    approvedData: initialDealApprovedData,
  }
}

export const formatRiskDetails = (data) => {
  return data
    .filter((item) => item.risksDetails.checked)
    .map((item) => ({
      risk: item.risksDetails.risk,
      coverage: '0',
      premium: item.risksDetails.premium,
      dealerCommission: item.risksDetails.dealerCommission,
    }))
}

export const getInsuranceRequestsProcessedTableData = (data, insuranceCompanies) => {
  return data.map((item) => {
    const person = item?.insuranceApplication?.customer?.person
    const customerName =
      person && (person?.firstName || person?.lastName)
        ? `${person?.firstName || ''} ${person?.lastName || ''}`
        : `Customer #${item?.insuranceApplication?.id}`
    return {
      id: item?.id,
      link: `/operator/insurance-requests/${item?.insuranceApplication?.id}`,
      linkParams: { loanDeal: item?.id },
      cells: [
        {
          type: 'text',
          value: `#${item?.insuranceApplication?.id}`,
        },
        {
          type: 'text',
          value: customerName,
        },
        {
          type: 'text',
          value: insuranceCompanies.find((company) => company['@id'] === item?.insuranceCompany)
            ?.name,
        },
        {
          type: 'text',
          value: `${item?.insuranceApplication?.car?.make?.name} ${item?.insuranceApplication?.car?.model?.name}`,
        },
        {
          type: 'text',
          value: handleFormatDate(item?.createdAt, '.'),
        },
      ],
    }
  })
}
export const getInsuranceRequestsTableData = (data, insuranceCompanies) => {
  return data.map((item) => {
    const person = item?.insuranceApplication?.customer?.person
    const customerName =
      person && (person?.firstName || person?.lastName)
        ? `${person?.firstName || ''} ${person?.lastName || ''}`
        : `Customer #${item?.insuranceApplication?.id}`
    return {
      id: item?.id,
      link: `/operator/insurance-requests/${item?.insuranceApplication?.id}`,
      linkParams: { loanDeal: item?.id },
      cells: [
        {
          type: 'text',
          value: `#${item?.insuranceApplication?.id}`,
        },
        {
          type: 'text',
          value: customerName,
        },
        {
          type: 'text',
          value: insuranceCompanies.find((company) => company['@id'] === item?.insuranceCompany)
            ?.name,
        },
        {
          type: 'text',
          value: `${item?.insuranceApplication?.car?.make?.name} ${item?.insuranceApplication?.car?.model?.name}`,
        },
        {
          type: 'component',
          value: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <UIMarkerCircle className={getRequestMarkerClass(item?.createdAt)} />
              <TextNormal>{handleFormatDate(item?.createdAt, '.')}</TextNormal>
            </Box>
          ),
        },
      ],
    }
  })
}

export const hasUrgentRequests = (data) => {
  return !!data.find((item) => getRequestMarkerClass(item.createdAt) === 'danger')
}
