import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Stack,
  FormHelperText,
} from '@mui/material'
import ColorRadioGroup from '../../ui/ColorRadio/ColorRadioGroup'
import { Add, Remove } from '@mui/icons-material'
import { API_ENDPOINTS, HINT_TYPES } from '../../config/default'
import {
  rimsTypes,
  cylinderTypes,
  fuelConsumptionOptions,
  CO2EmissionOptions,
} from '../AddNewVehicle/basicFieldsData'
import { privateRequest } from '../../utils/request'
import { toast } from 'react-toastify'
import CarSpecs from '../AddNewVehicle/CarSpecs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import VINValidator from '../../utils/VinValidator'
import {
  setCarMainData,
  setMake,
  setModel,
  setVin,
  resetCarData,
  setFirstDataValid,
} from '../../store/features/addNewCarSlice/addNewCar.reducer'
import TextFieldSmall from '../../ui/TextFieldSmall'
import { colorsConfig } from '../../config/themeConfig'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import TextAreaSmall from '../../ui/TextAreaSmall'
import { sumDelimiter } from '../../utils/sumDelimiter'
import IconBronze from '../../assests/img/icons/icon-bronze.svg'
import IconBronzeFull from '../../assests/img/icons/icon-bronze-full.svg'
import IconSilver from '../../assests/img/icons/icon-silver.svg'
import IconSilverFull from '../../assests/img/icons/icon-silver-full.svg'
import IconGold from '../../assests/img/icons/icon-gold.svg'
import IconGoldFull from '../../assests/img/icons/icon-golden-full.svg'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import IconCoin from '../../assests/img/icons/icon-fleetify-coin.svg'
import TextNormal from '../../ui/Text/TextNormal'
import IconApple from '../../assests/img/icons/icon-apple.svg'
import { setAppraisalTab } from '../../store/features/carSlice/carSlice'
import { fixHint, setHints } from '../../store/features/qualityCheckSlice'
import EnergyEfficiencyCategory from '../AddNewVehicle/FormTemplates/EnergyEfficiencyCategory'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import {
  gearboxTypes,
  bodyTypes,
  engineTypes,
  originTypes,
  driveTypes,
} from '../../config/formsConfig'
import CheckmarkBlack from '../../ui/CheckmarkBlack'
import dayjs from 'dayjs'

const EditVehicle = (props) => {
  const { mode } = props

  const qualityCheckHints = useSelector((state) => state.qualityCheck.hints)

  const theme = createTheme({
    spacing: [0, 5, 10, 20],
  })

  const params = useParams()
  const navigate = useNavigate()

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [makesLoading, setMakesLoading] = useState(false)
  const [firstBlur, setFirstBlur] = useState(false)
  const [vehicleDetailsHint, setVehicleDetailsHint] = useState(null)
  const [date, setDate] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const [showSpecs, setShowSpecs] = useState(false)
  const [carSpecs, setCarSpecs] = useState([])
  const [carSpecsLoaded, setCarSpecsLoaded] = useState(false)
  const [carSpecsReference, setCarSpecsReference] = useState([])
  const [carSpecsBlocks, setCarSpecsBlocks] = useState([])

  const isMobileSmall = useMediaQuery('(max-width: 420px)')
  const isMobileMedium = useMediaQuery('(max-width: 500px)')

  const carFirstDataValid = useSelector((state) => state.addCar.carFirstData.valid)
  const vin = useSelector((state) => state.addCar.carFirstData.vin)
  const make = useSelector((state) => state.addCar.carFirstData.make)
  const model = useSelector((state) => state.addCar.carFirstData.model)
  const carData = useSelector((state) => state.addCar.carMainData)
  const dispatch = useDispatch()

  const handleSetVin = (value) => {
    const validateVin = VINValidator().validate(value)
    const newVin = {
      valid: validateVin.valid,
      errorText: validateVin.error ? validateVin.error : '',
      value: value.toUpperCase(),
    }
    dispatch(setVin(newVin))
  }

  const handleSetMake = (value) => {
    dispatch(
      setMake({
        ...make,
        name: value,
      }),
    )
  }

  const handleSetModel = (value) => {
    let matchModel = null
    if (value) {
      matchModel = models.filter((item) => value === item.name)[0]
    }
    dispatch(
      setModel({
        name: value,
        id: matchModel ? matchModel['@id'] : '',
      }),
    )
  }

  const getMakes = (searchLine) => {
    privateRequest.get(`${API_ENDPOINTS.makes}?name=${searchLine}`).then((response) => {
      setMakes(response.data['hydra:member'])
      setMakesLoading(false)
      const matchMake = response.data['hydra:member'].filter((item) => item.name === make.name)[0]
      if (matchMake) {
        dispatch(
          setMake({
            ...make,
            id: matchMake['@id'],
          }),
        )
        getModels(matchMake['@id'])
      }
    })
  }

  const getModels = (makePath) => {
    privateRequest
      .get(`${API_ENDPOINTS.models}?make=${makePath}&pagination=false`)
      .then((response) => {
        setModels(response.data['hydra:member'])
        if (response.data['hydra:member'].length === 0) {
          toast.error('Can not add this make: no models found')
        }
      })
  }

  const getCar = () => {
    privateRequest.get(API_ENDPOINTS.carId(params.id)).then((response) => {
      dispatch(
        setVin({
          valid: true,
          errorText: '',
          value: response.data.vin,
        }),
      )
      dispatch(
        setMake({
          name: response.data.make.name,
          id: response.data.make['@id'],
        }),
      )
      getModels(response.data.make['@id'])
      dispatch(
        setModel({
          name: response.data.model.name,
          id: response.data.model['@id'],
        }),
      )
      setTimeout(() => {
        dispatch(setFirstDataValid())
      }, 2000)
      setDate(dayjs(response.data?.firstRegistrationDate))
      let cylinder = ''
      if (response.data.cylinder !== '') {
        cylinder = cylinderTypes.filter((item) => item.value === response.data.cylinder)[0]
      }
      let doorsNumber = ''
      if (response.data.doorsNumber) {
        doorsNumber = `${response.data.doorsNumber.toString().charAt(0)}/${response.data.doorsNumber.toString().charAt(1)}`
      }
      dispatch(
        setCarMainData({
          ...carData,
          type: response.data.type ? response.data.type : '',
          details: response.data.details ? response.data.details : '',
          mileage: response.data.mileage ? response.data.mileage : '',
          engineNumber: response.data.engineNumber ? response.data.engineNumber : '',
          interiorColor: response.data.interiorColor ? response.data.interiorColor : '',
          exteriorColor: response.data.exteriorColor ? response.data.exteriorColor : '',
          paintwork: response.data.paintwork ? response.data.paintwork.split('_') : [],
          upholstery: response.data.upholstery ? response.data.upholstery : '',
          wheelLocation: response.data.wheelLocation ? response.data.wheelLocation : '',
          rims: response.data.rims ? response.data.rims : '',
          bodyType: response.data.bodyType
            ? bodyTypes.filter((item) => item.systemName === response.data.bodyType)[0]
            : '',
          engineType: response.data.engineType
            ? engineTypes.filter((item) => item.systemName === response.data.engineType)[0]
            : '',
          firstRegistrationMonth: response.data.firstRegistrationMonth
            ? response.data.firstRegistrationMonth
            : '',
          firstRegistrationYear: response.data.firstRegistrationYear
            ? response.data.firstRegistrationYear
            : '',
          hp: response.data.hp ? response.data.hp : '',
          origin: response.data.origin
            ? originTypes.filter((item) => item.systemName === response.data.origin)[0]
            : '',
          engineSize: response.data.engineSize ? response.data.engineSize : '',
          cylinder: response.data.cylinder ? response.data.cylinder : '',
          gearbox: response.data.gearbox
            ? gearboxTypes.filter((item) => item.systemName === response.data.gearbox)[0]
            : '',
          driveType: response.data.driveType
            ? driveTypes.filter((item) => item.systemName === response.data.driveType)[0]
            : '',
          newPriceWithOptions: response.data.newPriceWithOptions
            ? response.data.newPriceWithOptions
            : '',
          gearsNumber: response.data.gearsNumber ? response.data.gearsNumber : '',
          seatsNumber: response.data.seatsNumber ? response.data.seatsNumber : '',
          doorsNumber: doorsNumber,
          bookingValue: response.data.bookingValue ? response.data.bookingValue : '',
          weight: response.data.weight ? response.data.weight : '',
          additionalInfo: response.data.additionalInfo ? response.data.additionalInfo : '',
          referenceNumber: response.data.referenceNumber ? response.data.referenceNumber : '',
          CO2Emission: response.data.CO2Emission ? response.data.CO2Emission : '',
          fuelConsumption: response.data.fuelConsumption
            ? parseFloat(parseFloat(response.data.fuelConsumption).toFixed(1))
            : null,
          energyEfficiencyCategory: response.data.energyEfficiencyCategory
            ? response.data.energyEfficiencyCategory
            : '',
          euroNorm: response.data.euroNorm ? response.data.euroNorm : '',
        }),
      )
      setLoading(false)
    })
  }

  const handleSetPaintwork = (paintwork) => {
    const currentPaintwork = carData.paintwork.map((item) => item)
    if (paintwork.value) {
      currentPaintwork.push(paintwork.label)
      dispatch(
        setCarMainData({
          ...carData,
          paintwork: currentPaintwork,
        }),
      )
    } else {
      dispatch(
        setCarMainData({
          ...carData,
          paintwork: currentPaintwork.filter((item) => item !== paintwork.label),
        }),
      )
    }
  }

  const handleUpdateCar = () => {
    const doorsNumber =
      carData.doorsNumber !== '' ? parseFloat(carData.doorsNumber.replace(/\D/g, '')) : 0
    let paintwork = ''
    if (carData.paintwork.length > 0) {
      for (let i = 0; i < carData.paintwork.length; i++) {
        paintwork += carData.paintwork[i]
        if (i !== carData.paintwork.length - 1) {
          paintwork += '_'
        }
      }
    }
    const formData = {
      ...carData,
      vin: vin.value,
      make: make.id,
      model: model.id,
      mileage: carData.mileage !== '' ? parseFloat(carData.mileage) : 0,
      engineNumber: carData.engineNumber ? carData.engineNumber : '',
      hp: carData.hp ? parseFloat(carData.hp) : null,
      gearsNumber: carData.gearsNumber ? parseFloat(carData.gearsNumber) : 0,
      seatsNumber: carData.seatsNumber ? parseFloat(carData.seatsNumber) : 0,
      doorsNumber: doorsNumber,
      weight: carData.weight ? parseFloat(carData.weight) : 0,
      bookingValue: carData.bookingValue ? parseFloat(carData.bookingValue) : 0,
      newPriceWithOptions: carData.newPriceWithOptions
        ? parseFloat(carData.newPriceWithOptions)
        : 0,
      paintwork: paintwork,
      cylinder: carData.cylinder ? carData.cylinder : null,
      CO2Emission: carData.CO2Emission ? parseFloat(carData.CO2Emission) : 0,
      fuelConsumption: carData.fuelConsumption ? carData.fuelConsumption.toString() : '0',
      energyEfficiencyCategory: carData.energyEfficiencyCategory,
      euroNorm: carData.euroNorm,
      engineType: carData.engineType?.systemName,
      origin: carData.origin?.systemName,
      bodyType: carData.bodyType?.systemName,
      gearbox: carData.gearbox?.systemName,
      driveType: carData.driveType?.systemName,
    }
    privateRequest.put(API_ENDPOINTS.carId(params.id), formData).then((response) => {
      if (mode && mode === 'fromAppraisal') {
        const matchHint = qualityCheckHints.filter(
          (hint) => hint['@type'] === HINT_TYPES.vehicleDetails,
        )[0]
        if (matchHint) {
          privateRequest
            .put(`/cars/${params.id}/quality-check-hints/${vehicleDetailsHint.id}/complete`, {})
            .then(() => {
              dispatch(
                setHints(
                  qualityCheckHints.map((item) => {
                    if (item['@type'] === HINT_TYPES.vehicleDetails) {
                      return {
                        ...item,
                        fixed: true,
                      }
                    } else return item
                  }),
                ),
              )
              dispatch(setAppraisalTab(1))
            })
        }
        dispatch(setAppraisalTab(1))
      } else {
        toast.success('Your car was updated successfully')
        dispatch(resetCarData())
        navigate(-1)
      }
    })
    const newSpecs = []
    carSpecsBlocks.forEach((item) => {
      item.specs.forEach((spec) => {
        if (spec.value !== null) {
          newSpecs.push({
            value: spec.value,
            spec: spec['@id'],
          })
        }
      })
    })
    privateRequest.post(`/cars/${params.id}${API_ENDPOINTS.carSpecs.batch}`, {
      carSpecs: newSpecs,
    })
  }

  const getCarSpecsReference = () => {
    privateRequest.get(API_ENDPOINTS.carSpecsOptionsBlocks).then((response) => {
      setCarSpecsReference(response.data['hydra:member'])
    })
  }

  const getCarSpecs = () => {
    privateRequest
      .get(`${API_ENDPOINTS.carId(params.id)}${API_ENDPOINTS.carSpecs.default}`)
      .then((response) => {
        setCarSpecs(response.data['hydra:member'])
        setCarSpecsLoaded(true)
      })
  }

  const handleSetCarSpecsBlocks = () => {
    setCarSpecsBlocks(
      carSpecsReference.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          const matchCarSpec = carSpecs.filter((carSpec) => carSpec.spec === spec['@id'])[0]
          if (matchCarSpec) {
            return {
              ...spec,
              carSpecId: matchCarSpec['@id'],
              value: matchCarSpec.value,
            }
          }
          return {
            ...spec,
            value: false,
          }
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  const changeCarSpec = (id, value) => {
    setCarSpecsBlocks(
      carSpecsBlocks.map((item) => {
        const newSpecs = item.specs.map((spec) => {
          if (spec['@id'] === id) {
            return {
              ...spec,
              value: value,
            }
          }
          return spec
        })
        return {
          ...item,
          specs: newSpecs,
        }
      }),
    )
  }

  const handleSetMainSpecsSubmitDisabled = () => {
    if (!carData.mileage) return true
    if (!carData.engineType) return true
    if (!carData.bodyType) return true
    if (!carData.seatsNumber) return true
    if (!carData.doorsNumber) return true
    if (!carData.driveType) return true
    if (!carData.gearbox) return true
    if (!carData.engineSize && carData.engineType?.name !== 'Electric') return true
    if (!vin.value) return true
    if (!make.id) return true
    if (!model.id) return true
    return false
  }

  const handleSetVehicleDetailsHint = () => {
    let hintIndex = -1
    const matchHint = qualityCheckHints.filter((item, index) => {
      if (item['@type'] === HINT_TYPES.vehicleDetails) {
        hintIndex = index
      }
      return item['@type'] === HINT_TYPES.vehicleDetails
    })[0]
    if (matchHint && hintIndex !== -1) {
      setVehicleDetailsHint({
        ...matchHint,
        index: hintIndex,
      })
    }
  }

  useEffect(() => {
    if (firstBlur && !loading) {
      if (make.name) {
        const matchMake = makes.filter((item) => {
          return item.name === make.name
        })[0]
        if (matchMake) {
          setMakesLoading(false)
          dispatch(
            setMake({
              ...make,
              id: matchMake['@id'],
            }),
          )
          getModels(matchMake['@id'])
        } else if (make.name.length >= 2) {
          dispatch(
            setMake({
              ...make,
              id: null,
            }),
          )
          setModels([])
          setMakesLoading(true)
          getMakes(make.name)
        }
      } else {
        dispatch(
          setMake({
            ...make,
            id: null,
          }),
        )
        setModels([])
      }
    }
    setFirstBlur(true)
  }, [make.name])

  useEffect(() => {
    if (carSpecsLoaded && carSpecsReference.length > 0) {
      handleSetCarSpecsBlocks()
    }
  }, [carSpecs, carSpecsReference])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Edit information'))
    getCar()
    getCarSpecsReference()
    getCarSpecs()
    return () => {
      dispatch(resetCarData())
    }
  }, [])

  useEffect(() => {
    setSaveButtonDisabled(handleSetMainSpecsSubmitDisabled())
  }, [carData, vin, make, model])

  useEffect(() => {
    if (qualityCheckHints.length > 0 && mode && mode === 'fromAppraisal') {
      handleSetVehicleDetailsHint()
    }
  }, [qualityCheckHints])

  useEffect(() => {
    console.log(carData)
    if (carData.engineType?.name === 'Electric') {
      dispatch(
        setCarMainData({
          ...carData,
          gearbox: gearboxTypes.filter((item) => item.systemName === 'automatic')[0],
          gearsNumber: 1,
          engineSize: '',
          cylinder: '',
          fuelConsumption: '0',
        }),
      )
    }
  }, [carData.engineType])

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mt: '40px',
          px: {
            xs: '16px',
            sm: 0,
          },
          pb: {
            xs: '95px',
            sm: 0,
          },
        }}
      >
        {mode && mode === 'fromAppraisal' ? null : (
          <Box
            sx={{
              mb: '30px',
            }}
          >
            <BreadcrumbsComponent
              currentPage={{ name: `Edit information` }}
              parentPages={[
                {
                  name: 'Virtual Garage',
                  icon: <img src={IconVirtualGarage} alt="" />,
                  link: '/virtual-garage',
                },
                {
                  name: `${make.name} ${model.name}`,
                  link: `/cars/${params.id}`,
                },
              ]}
            />
          </Box>
        )}
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            mb: '14px',
          }}
        >
          Required fields <span style={{ color: colorsConfig.mainRed }}>*</span>
        </TextBoldNormal>
        <Grid container spacing={3} sx={{ mb: '40px' }}>
          <Grid item sm={4} xs={12}>
            <TextFieldSmall
              required
              fullWidth
              size={'small'}
              id="car-vin"
              label="VIN Number"
              value={vin.value}
              onChange={(e) => {
                if (e.target.value.length > 17) {
                  e.preventDefault()
                } else {
                  handleSetVin(e.target.value.replace(/[ioqIOQ]/g, ''))
                }
              }}
              helperText={vin.errorText}
              error={vin.value ? !vin.valid : false}
              InputProps={{
                endAdornment: (
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        vin.value === null || vin.value.length === 17 || vin.value.length === 0
                          ? 'lightgray'
                          : '#d32f2f',
                    }}
                  >
                    {vin.value ? vin.value.length : 0}/17
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              required
              fullWidth
              id="car-make"
              options={makes.map((item) => {
                return item.name
              })}
              value={make.name}
              loading={!make.name ? true : makesLoading}
              loadingText={!make.name ? 'Start typing...' : 'Loading makes...'}
              onChange={(e, value) => {
                handleSetMake(value)
              }}
              freeSolo
              renderInput={(params) => (
                <TextFieldSmall
                  {...params}
                  label="Make"
                  size={'small'}
                  value={make.name}
                  fullWidth
                  onChange={(e) => {
                    handleSetMake(e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {makesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              required
              disablePortal
              id="car-model"
              options={models.map((item) => {
                return item.name
              })}
              fullWidth
              value={model.name}
              loading={models.length === 0}
              freeSolo
              loadingText={'Please select the make first'}
              onChange={(e, newVal) => {
                handleSetModel(newVal)
              }}
              renderInput={(params) => (
                <TextFieldSmall
                  {...params}
                  label="Model"
                  size={'small'}
                  value={model.name}
                  onChange={(e) => {
                    handleSetModel(e.target.value)
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider sx={{ width: '100%' }} fullWidth dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Year / month of the first registration"
                inputFormat="MM/YYYY"
                mask={'__/____'}
                disableMaskedInput={false}
                renderInput={(params) => (
                  <TextFieldSmall {...params} fullWidth required value={date} />
                )}
                onChange={(newVal) => {
                  setDate(newVal)
                  if (newVal && newVal['$d'] && newVal['$d'] !== 'Invalid Date') {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        firstRegistrationMonth: newVal['$M'],
                        firstRegistrationYear: newVal['$y'],
                        firstRegistrationDate: newVal,
                      }),
                    )
                  } else {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        firstRegistrationMonth: '',
                        firstRegistrationYear: '',
                      }),
                    )
                  }
                }}
                value={date}
                views={['year', 'month']}
                disableFuture
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              required
              fullWidth
              label="Mileage (km)"
              type="text"
              inputProps={{
                inputMode: 'numeric',
              }}
              value={sumDelimiter(carData.mileage)}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    mileage: e.target.value.replace(/[^0-9]/g, ''),
                  }),
                )
              }}
              error={showErrors && !carData.mileage}
              helperText={showErrors && !carData.mileage ? 'This field can not be empty' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              size={'small'}
              error={showErrors && !carData.engineType}
            >
              <InputLabel
                id="engine-type"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Engine type
              </InputLabel>
              <Select
                labelId="engine-type"
                id="car-engine-type"
                label="Engine type"
                value={carData.engineType?.name || ''}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      engineType: engineTypes.filter((item) => item.name === e.target.value)[0],
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value={''}>Not Chosen</MenuItem>
                {engineTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>
                {showErrors && !carData.engineType ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          {carData.engineType?.name !== 'Electric' ? (
            <Grid item xs={12} sm={4}>
              <TextFieldSmall
                fullWidth
                required
                id="car-engine-size"
                inputProps={{
                  inputMode: 'numeric',
                }}
                label="Engine Size (ccm)"
                type="number"
                value={carData.engineSize}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      engineSize: e.target.value.replace(/[^0-9]/g, ''),
                    }),
                  )
                }}
                error={showErrors && !carData.engineSize && carData.engineType?.name !== 'Electric'}
                helperText={
                  showErrors && !carData.engineSize && carData.engineType?.name !== 'Electric'
                    ? 'This field can not be empty'
                    : ''
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              required
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              size={'small'}
              error={showErrors && !carData.bodyType}
            >
              <InputLabel
                id="body-type"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  border: 'none',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Body Type
              </InputLabel>
              <Select
                labelId="body-type"
                id="car-body-type"
                label="Body Type"
                size={'small'}
                value={carData.bodyType?.name || ''}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      bodyType: bodyTypes.filter((item) => item.name === e.target.value)[0],
                    }),
                  )
                }}
                error={showErrors && !carData.bodyType}
                errorText={showErrors && !carData.bodyType ? 'This field can not be empty' : ''}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                {bodyTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            width: '30px',
                            mr: 1,
                            position: 'relative',
                            marginBottom: '-3px',
                          }}
                        >
                          <img className="img-in-box" src={item.icon} alt={item.name} />
                        </Box>
                        <Typography variant="body1">{item.name}</Typography>
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>
                {showErrors && !carData.bodyType ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              size={'small'}
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
            >
              <InputLabel
                id="wheel-location"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Steering Wheel Location
              </InputLabel>
              <Select
                required
                labelId="wheel-location"
                id="car-wheel-location"
                label="Steering Wheel Location"
                value={carData.wheelLocation}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      wheelLocation: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="car-origin"
              options={originTypes}
              getOptionLabel={(item) => item.name}
              value={carData.origin}
              onChange={(e, newVal) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    origin: newVal,
                  }),
                )
              }}
              renderInput={(params) => (
                <TextFieldSmall
                  {...params}
                  label="Origin"
                  required
                  value={carData.origin?.name}
                  onChange={(e) => {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        origin: e.target.value,
                      }),
                    )
                  }}
                  error={showErrors && !carData.origin}
                  helperText={showErrors && !carData.origin ? 'This field can not be empty' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              fullWidth
              size={'small'}
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              error={showErrors && !carData.driveType}
            >
              <InputLabel
                id="label-drive"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Drive
              </InputLabel>
              <Select
                labelId="label-drive"
                id="car-drive"
                label="Drive"
                required
                value={carData.driveType?.name || ''}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      driveType: driveTypes.filter((item) => item.name === e.target.value)[0],
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                {driveTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>
                {showErrors && !carData.driveType ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              size={'small'}
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              error={showErrors && !carData.gearbox}
            >
              <InputLabel
                id="label-gear"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Gear
              </InputLabel>
              <Select
                labelId="label-gear"
                id="car-gear"
                label="Gear"
                disabled={carData.engineType?.name === 'Electric'}
                value={carData.gearbox?.name || ''}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      gearbox: gearboxTypes.filter((item) => item.name === e.target.value)[0],
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                {gearboxTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>
                {showErrors && !carData.gearbox ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              size={'small'}
              required
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              error={showErrors && !carData.seatsNumber}
            >
              <InputLabel
                id="label-seats-amount"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Number of Seats
              </InputLabel>
              <Select
                labelId="label-seats-amount"
                id="car-seats-amount"
                label="Number of Seats"
                required
                value={carData.seatsNumber}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      seatsNumber: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>7+</MenuItem>
              </Select>
              <FormHelperText>
                {showErrors && !carData.seatsNumber ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              size={'small'}
              error={showErrors && !carData.doorsNumber}
            >
              <InputLabel
                id="label-doors-amount"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Number of Doors
              </InputLabel>
              <Select
                labelId="label-doors-amount"
                id="car-doors-amount"
                label="Number of Doors"
                required
                value={carData.doorsNumber}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      doorsNumber: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                <MenuItem value={'2/3'}>2 / 3</MenuItem>
                <MenuItem value={'4/5'}>4 / 5</MenuItem>
                <MenuItem value={'6/7'}>6 / 7</MenuItem>
              </Select>
              <FormHelperText>
                {showErrors && !carData.doorsNumber ? 'This field can not be empty' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            mb: '20px',
            mt: '20px',
          }}
        >
          Optional fields
        </TextBoldNormal>
        <Grid container spacing={3} sx={{ mb: '40px' }}>
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              id="car-reference-number"
              fullWidth
              label="Reference Number"
              inputProps={{
                inputMode: 'numeric',
              }}
              value={carData.referenceNumber}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    referenceNumber: e.target.value,
                  }),
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              fullWidth
              label="Model Details"
              value={carData.details}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    details: e.target.value,
                  }),
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
              size={'small'}
            >
              <InputLabel
                id="car-type-label"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Car Type
              </InputLabel>
              <Select
                labelId="car-type-label"
                id="car-type-select"
                value={carData.type}
                label="Car Type"
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      type: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
                size={'small'}
              >
                <MenuItem value={''}>Not Chosen</MenuItem>
                <MenuItem value={'Personal'}>Personal</MenuItem>
                <MenuItem value={'Light Commercial Vehicle'}>Light Commercial Vehicle</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              fullWidth
              label="Weight (kg)"
              type="text"
              inputProps={{
                inputMode: 'numeric',
              }}
              id="car-weight"
              value={carData.weight}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    weight: e.target.value.replace(/[^0-9]/g, ''),
                  }),
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              fullWidth
              id="horse-power"
              label="Engine Power (Horse Powers)"
              type="text"
              inputProps={{
                inputMode: 'numeric',
              }}
              value={carData.hp}
              onChange={(e) => {
                if (e.target.value.length > 4) {
                  e.preventDefault()
                } else {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      hp: e.target.value.replace(/[^0-9]/g, ''),
                    }),
                  )
                }
              }}
            />
          </Grid>
          {carData.engineType?.name !== 'Electric' ? (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="car-cylinder"
                options={cylinderTypes}
                fullWidth
                value={carData.cylinder}
                onChange={(e, newVal) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      cylinder: newVal,
                    }),
                  )
                }}
                renderInput={(params) => (
                  <TextFieldSmall
                    {...params}
                    label="Cylinder"
                    //value={carData.cylinder}
                    // onChange={(e) => {
                    //     dispatch(setCarMainData({
                    //         ...carData,
                    //         cylinder: e.target.value
                    //     }))
                    // }}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={4}>
            <TextFieldSmall
              fullWidth
              label="Engine Number"
              inputProps={{
                inputMode: 'numeric',
              }}
              value={carData.engineNumber}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    engineNumber: e.target.value,
                  }),
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              size={'small'}
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
            >
              <InputLabel
                id="label-gear-amount"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Number of Gears
              </InputLabel>
              <Select
                labelId="label-gear-amount"
                id="car-gear-amount"
                label="Number of Gears"
                value={carData.gearsNumber}
                disabled={carData.engineType?.name === 'Electric'}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      gearsNumber: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              fullWidth
              size={'small'}
              sx={{
                color: colorsConfig.iconGray,
                fontWeight: 400,
                fontSize: '14px',
                border: 'none',
              }}
            >
              <InputLabel
                id="rims"
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  '&.Mui-focused': {
                    background: 'white',
                    color: colorsConfig.mainBlue,
                    boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                    overflow: 'visible',
                  },
                }}
              >
                Rims
              </InputLabel>
              <Select
                labelId="rims"
                id="car-rims"
                label="Rims"
                value={carData.rims}
                onChange={(e) => {
                  dispatch(
                    setCarMainData({
                      ...carData,
                      rims: e.target.value,
                    }),
                  )
                }}
                sx={{
                  borderRadius: '4px',
                  height: '38px',
                  fontSize: '14px',
                  border: 'none',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                  },
                }}
              >
                <MenuItem value="">Not Chosen</MenuItem>
                {rimsTypes.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            height: {
              xs: 'auto',
              sm: '71px',
            },
            borderRadius: '4px',
            display: {
              xs: 'block',
              sm: 'flex',
            },
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            px: '21px',
            py: {
              xs: '23px',
              sm: 0,
            },
            border: '1px solid',
            borderColor: colorsConfig.buttonDisabled,
            mb: '40px',
          }}
        >
          <TextNormal
            sx={{
              mr: {
                xs: 0,
                sm: '17px',
              },
              mb: {
                xs: '12px',
                sm: 0,
              },
            }}
          >
            Private information:
          </TextNormal>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '30%',
              },
              mr: {
                xs: 0,
                sm: '20px',
              },
              mb: {
                xs: '10px',
                sm: 0,
              },
            }}
          >
            <TextFieldSmall
              fullWidth
              label="Purchase price (AED)"
              type={'text'}
              inputProps={{
                inputMode: 'numeric',
              }}
              id="gross-price-with-options"
              value={sumDelimiter(carData.newPriceWithOptions)}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    newPriceWithOptions: e.target.value.replace(/[^0-9]/g, ''),
                  }),
                )
              }}
            />
          </Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '30%',
              },
              mr: {
                xs: 0,
                sm: '20px',
              },
              mb: {
                xs: '12px',
                sm: 0,
              },
            }}
          >
            <TextFieldSmall
              fullWidth
              label="Booking value (AED)"
              type={'text'}
              id="booking value"
              value={sumDelimiter(carData.bookingValue)}
              inputProps={{
                inputMode: 'numeric',
              }}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    bookingValue: e.target.value.replace(/[^0-9]/g, ''),
                  }),
                )
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {
                xs: 'flex-start',
                sm: 'center',
              },
              alignItems: 'center',
            }}
          >
            <CheckmarkBlack
              value={carData.carHiddenPriceShow}
              setValue={(newVal) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    carHiddenPriceShow: newVal,
                  }),
                )
              }}
            />
            <TextNormal
              sx={{
                ml: '6px',
              }}
            >
              Show to other users
            </TextNormal>
          </Box>
        </Box>
        <TextBoldNormal
          sx={{
            fontSize: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '8px',
            mt: '20px',
          }}
        >
          <img src={IconCoin} alt="" style={{ marginRight: '5.5px' }} />
          Rewarded fields
        </TextBoldNormal>
        <TextNormal sx={{ mb: '20px' }}>
          Fill out these fields to receive 5{' '}
          <span style={{ color: colorsConfig.mainBlue, textDecoration: 'underline' }}>
            Fleetify Coins
          </span>{' '}
          for free
        </TextNormal>
        <TextNormal
          sx={{
            fontSize: '18px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '16px',
          }}
        >
          Eco data <img src={IconApple} alt="" style={{ marginLeft: '4px' }} />
        </TextNormal>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <EnergyEfficiencyCategory
              value={carData.energyEfficiencyCategory}
              onChange={(value) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    energyEfficiencyCategory: value,
                  }),
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={3}>
              {carData.engineType?.name === 'Electric' ? (
                <TextFieldSmall
                  id="car-fuel-consumption"
                  fullWidth
                  label="Electricity consumption (kwh / 100 km)"
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  value={carData.electricityConsumption}
                  onChange={(e) => {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        electricityConsumption: e.target.value.replace(/[^0-9]/g, ''),
                      }),
                    )
                  }}
                />
              ) : (
                <FormControl
                  fullWidth
                  size={'small'}
                  sx={{
                    color: colorsConfig.iconGray,
                    fontWeight: 400,
                    fontSize: '14px',
                    border: 'none',
                  }}
                >
                  <InputLabel
                    id="fuel-consumption"
                    sx={{
                      color: colorsConfig.iconGray,
                      fontWeight: 400,
                      fontSize: '14px',
                      '&.Mui-focused': {
                        background: 'white',
                        color: colorsConfig.mainBlue,
                        boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                        overflow: 'visible',
                      },
                    }}
                  >
                    Fuel consumption (L / 100 km)
                  </InputLabel>
                  <Select
                    labelId="fuel-consumption"
                    id="car-fuel-consumption"
                    label="Fuel consumption (L / 100 km)"
                    value={carData.fuelConsumption}
                    onChange={(e) => {
                      dispatch(
                        setCarMainData({
                          ...carData,
                          fuelConsumption: e.target.value,
                        }),
                      )
                    }}
                    sx={{
                      borderRadius: '4px',
                      height: '38px',
                      fontSize: '14px',
                      border: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                      },
                    }}
                  >
                    <MenuItem value="">Not Chosen</MenuItem>
                    {fuelConsumptionOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item} L / 100 km
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}
              {/*<TextFieldSmall*/}
              {/*    id='car-fuel-consumption'*/}
              {/*    fullWidth*/}
              {/*    label='Fuel consumption (l/km)'*/}
              {/*    value={carData.fuelConsumption}*/}
              {/*    onChange={(e) => {*/}
              {/*        dispatch(setCarMainData({*/}
              {/*            ...carData,*/}
              {/*            fuelConsumption: e.target.value*/}
              {/*        }))*/}
              {/*    }}*/}
              {/*/>*/}
              {/*<TextFieldSmall*/}
              {/*    fullWidth*/}
              {/*    label='CO2-Emission (g/km)'*/}
              {/*    value={carData.CO2Emission}*/}
              {/*    onChange={(e) => {*/}
              {/*        dispatch(setCarMainData({*/}
              {/*            ...carData,*/}
              {/*            CO2Emission: e.target.value*/}
              {/*        }))*/}
              {/*    }}*/}
              {/*/>*/}
              <FormControl
                fullWidth
                size={'small'}
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  border: 'none',
                }}
              >
                <InputLabel
                  id="fuel-consumption"
                  sx={{
                    color: colorsConfig.iconGray,
                    fontWeight: 400,
                    fontSize: '14px',
                    '&.Mui-focused': {
                      background: 'white',
                      color: colorsConfig.mainBlue,
                      boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                      overflow: 'visible',
                    },
                  }}
                >
                  CO2 Emission (g/km)
                </InputLabel>
                <Select
                  labelId="co2-emission"
                  id="car-co2-emission"
                  label="CO2 Emission (g/km)"
                  value={carData.CO2Emission}
                  onChange={(e) => {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        CO2Emission: e.target.value,
                      }),
                    )
                  }}
                  sx={{
                    borderRadius: '4px',
                    height: '38px',
                    fontSize: '14px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                    },
                  }}
                >
                  <MenuItem value="">Not Chosen</MenuItem>
                  {CO2EmissionOptions.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item} g / km
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                sx={{
                  color: colorsConfig.iconGray,
                  fontWeight: 400,
                  fontSize: '14px',
                  border: 'none',
                }}
                size={'small'}
              >
                <InputLabel
                  id="car-euro-norm-label"
                  sx={{
                    color: colorsConfig.iconGray,
                    fontWeight: 400,
                    fontSize: '14px',
                    '&.Mui-focused': {
                      background: 'white',
                      color: colorsConfig.mainBlue,
                      boxShadow: '0 0 10px 5px rgba(255,255,255,.9)',
                      overflow: 'visible',
                    },
                  }}
                >
                  Euro-norm
                </InputLabel>
                <Select
                  labelId="car-euro-norm-label"
                  id="car-euro-norm-select"
                  value={carData.euroNorm}
                  label="Euro-norm"
                  onChange={(e) => {
                    dispatch(
                      setCarMainData({
                        ...carData,
                        euroNorm: e.target.value,
                      }),
                    )
                  }}
                  sx={{
                    borderRadius: '4px',
                    height: '38px',
                    fontSize: '14px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
                    },
                  }}
                  size={'small'}
                >
                  <MenuItem value={''}>Not Chosen</MenuItem>
                  <MenuItem value={'1'}>Euro 1</MenuItem>
                  <MenuItem value={'2'}>Euro 2</MenuItem>
                  <MenuItem value={'3'}>Euro 3</MenuItem>
                  <MenuItem value={'4'}>Euro 4</MenuItem>
                  <MenuItem value={'5'}>Euro 5</MenuItem>
                  <MenuItem value={'6'}>Euro 6</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: '35px', mb: '25px', color: colorsConfig.mainBlue }}>
            <Button
              onClick={() => {
                setShowSpecs(!showSpecs)
              }}
              sx={{
                fontWeight: 700,
                textTransform: 'none',
                color: colorsConfig.mainBlue,
                fontSize: '16px',
              }}
              startIcon={
                !showSpecs ? (
                  <Add
                    style={{ fill: colorsConfig.mainBlue }}
                    sx={{
                      marginTop: '-2px',
                      position: 'relative',
                    }}
                  />
                ) : (
                  <Remove sx={{ marginTop: '-2px', position: 'relative' }} />
                )
              }
            >
              Additional specifications
            </Button>
            {showSpecs ? <CarSpecs blocks={carSpecsBlocks} changeCarSpec={changeCarSpec} /> : null}
          </Grid>
          <Grid item sm={6}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              Exterior Colour
            </Typography>

            <ColorRadioGroup
              itemsPerRow={isMobileSmall ? 3 : 4}
              colors={[
                { color: '#DBAF6E', label: 'Beige' },
                { color: '#0059B2', label: 'Blue' },
                { color: '#994200', label: 'Brown' },
                {
                  color: '#DB9D5D',
                  label: 'Bronze',
                  specialType: 'image',
                  image: IconBronze,
                  imageFull: IconBronzeFull,
                },
                { color: '#F7C81E', label: 'Yellow' },
                { color: '#949494', label: 'Grey' },
                { color: '#38A614', label: 'Green' },
                { color: '#D91A2A', label: 'Red' },
                { color: '#000000', label: 'Black' },
                {
                  color: '#C4C4C4',
                  label: 'Silver',
                  specialType: 'image',
                  image: IconSilver,
                  imageFull: IconSilverFull,
                },
                { color: '#991289', label: 'Violet' },
                { color: '#F0F0F0', label: 'White' },
                { color: '#FF7500', label: 'Orange' },
                {
                  color: '#E8C84A',
                  label: 'Gold',
                  specialType: 'image',
                  image: IconGold,
                  imageFull: IconGoldFull,
                },
              ]}
              value={carData.exteriorColor}
              setValue={(color) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    exteriorColor: color,
                  }),
                )
              }}
            />

            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              Paintwork
            </Typography>

            <ColorRadioGroup
              colors={[
                { color: '#FFFFFF', label: 'Metallic' },
                { color: '#FFFFFF', label: 'Matt' },
                { color: '#FFFFFF', label: 'Wrapped' },
              ]}
              allowMultiple
              value={carData.paintwork}
              setValue={(paintwork) => {
                handleSetPaintwork(paintwork)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              Interior Colour
            </Typography>

            <ColorRadioGroup
              itemsPerRow={isMobileSmall ? 3 : 4}
              colors={[
                { color: '#DBAF6E', label: 'Beige' },
                { color: '#0059B2', label: 'Blue' },
                { color: '#994200', label: 'Brown' },
                {
                  color: '#DB9D5D',
                  label: 'Bronze',
                  specialType: 'image',
                  image: IconBronze,
                  imageFull: IconBronzeFull,
                },
                { color: '#F7C81E', label: 'Yellow' },
                { color: '#949494', label: 'Grey' },
                { color: '#38A614', label: 'Green' },
                { color: '#D91A2A', label: 'Red' },
                { color: '#000000', label: 'Black' },
                {
                  color: '#C4C4C4',
                  label: 'Silver',
                  specialType: 'image',
                  image: IconSilver,
                  imageFull: IconSilverFull,
                },
                { color: '#991289', label: 'Violet' },
                { color: '#F0F0F0', label: 'White' },
                { color: '#FF7500', label: 'Orange' },
                {
                  color: '#E8C84A',
                  label: 'Gold',
                  specialType: 'image',
                  image: IconGold,
                  imageFull: IconGoldFull,
                },
              ]}
              value={carData.interiorColor}
              setValue={(color) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    interiorColor: color,
                  }),
                )
              }}
            />

            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: colorsConfig.mainBlack,
                mb: '20px',
              }}
            >
              Upholstery
            </Typography>

            <ColorRadioGroup
              itemsPerRow={3}
              colors={[
                { color: '#FFFFFF', label: 'Alcantara' },
                { color: '#FFFFFF', label: 'Full leather' },
                { color: '#FFFFFF', label: 'Velour' },
                { color: '#FFFFFF', label: 'Cloth' },
                { color: '#FFFFFF', label: 'Part leather' },
                { color: '#FFFFFF', label: 'Other' },
              ]}
              value={carData.upholstery}
              setValue={(color) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    upholstery: color,
                  }),
                )
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextAreaSmall
              fullWidth
              label="Additional Info"
              type="textarea"
              multiline
              rows={4}
              maxRows={10}
              value={carData.additionalInfo}
              onChange={(e) => {
                dispatch(
                  setCarMainData({
                    ...carData,
                    additionalInfo: e.target.value,
                  }),
                )
              }}
            />
          </Grid>
          <Grid
            xs={12}
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {mode && mode === 'fromAppraisal' ? (
              <ButtonLG
                onClick={() => {
                  handleUpdateCar()
                }}
                disabled={saveButtonDisabled}
              >
                {vehicleDetailsHint ? 'Apply & proceed' : 'Next'}
              </ButtonLG>
            ) : (
              <ButtonLG
                sx={{
                  background: colorsConfig.mainGreen,
                  '&:hover': {
                    background: colorsConfig.mainGreen,
                    opacity: 0.7,
                    transition: '0.25s all ease-in-out',
                  },
                }}
                onClick={() => {
                  handleUpdateCar()
                }}
                disabled={saveButtonDisabled}
              >
                Save changes
              </ButtonLG>
            )}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default EditVehicle
