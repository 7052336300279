import { createAsyncThunk } from '@reduxjs/toolkit'
import residualValueMatrixEntriesApiService from '../../../api/residualValueMatrixEntriesApiService/residualValueMatrixEntriesApiService'
import { toast } from 'react-toastify'

export const createEntry = createAsyncThunk(
  'residualValueMatrixEntries/createEntry',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await residualValueMatrixEntriesApiService.createEntry(data)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getEntries = createAsyncThunk(
  'residualValueMatrixEntries/getEntries',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().residualValueMatrixEntries.params
      const response = await residualValueMatrixEntriesApiService.getEntries(params)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const updateEntry = createAsyncThunk(
  'residualValueMatrixEntries/updateEntry',
  async ({ entryId, data }, { rejectWithValue }) => {
    try {
      const response = await residualValueMatrixEntriesApiService.updateEntry(entryId, data)
      return response.data
    } catch (e) {
      toast.error(e)
      return rejectWithValue(e)
    }
  },
)
