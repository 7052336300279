import React from 'react'
import { Box, useMediaQuery, Stack } from '@mui/material'
import Logo from '../assests/img/logo-blue.svg'
import { colorsConfig } from '../config/themeConfig'
import ButtonLinkSecondary from '../ui/Buttons/ButtonLinkSecondary'
import TextNormal from '../ui/Text/TextNormal'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UITextLabelMedium from '../ui/UIText/UITextLabelMedium'
import NoTranslate from '../NoTranslate'
import UITextBodySmall from '../ui/UIText/UITextBodySmall'
import {
  REGION_LOCALE,
  COMPANY_ADDRESS,
  COMPANY_NAME,
  MAIN_EMAIL,
  COMPANY_PHONE,
} from '../utils/constants/global.constants'

const Footer2 = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      sx={{
        px: '16px',
        py: '30px',
        background: colorsConfig.bgLightBlue,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: '22px',
        }}
      >
        <img style={{ width: '132px' }} src={Logo} alt="" />
      </Box>
      <Box
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '4px',
          mb: '40px',
          '&.xs-only': {
            gap: '8px',
          },
        }}
      >
        <ButtonLinkSecondary
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              p: 0,
              pl: '8px',
              '&::after': {
                left: 0,
                width: '2px',
              },
            },
          }}
          onClick={() => {
            navigate('/dashboard')
          }}
        >
          {t('footer_dashboard')}
        </ButtonLinkSecondary>
        <ButtonLinkSecondary
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              p: 0,
              pl: '8px',
              '&::after': {
                left: 0,
                width: '2px',
              },
            },
          }}
          onClick={() => {
            navigate('/virtual-garage')
          }}
        >
          {t('footer_virtual_garage')}
        </ButtonLinkSecondary>
        <ButtonLinkSecondary
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              p: 0,
              pl: '8px',
              '&::after': {
                left: 0,
                width: '2px',
              },
            },
          }}
          onClick={() => {
            navigate('/help')
          }}
        >
          {t('footer_fleatify_help')}
        </ButtonLinkSecondary>
      </Box>
      <Stack
        className={xsOnly ? 'xs-only' : ''}
        direction="row"
        gap="50px"
        justifyContent="center"
        sx={{
          pl: '60px',
          '&.xs-only': {
            display: 'none',
          },
        }}
      >
        <Stack gap="10px">
          <TextNormal
            component={'a'}
            href={`tel:${COMPANY_PHONE.number}`}
            sx={{
              textDecoration: 'none',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                color: colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
              },
            }}
          >
            {COMPANY_PHONE.text}
          </TextNormal>
          <TextNormal
            component={'a'}
            href={`mailto:${MAIN_EMAIL}`}
            sx={{
              textDecoration: 'none',
              transition: '0.25s all ease-in-out',
              '&:hover': {
                color: colorsConfig.mainBlue,
                transition: '0.25s all ease-in-out',
              },
            }}
          >
            {MAIN_EMAIL}
          </TextNormal>
        </Stack>
        <Stack gap="10px">
          <UITextLabelMedium>
            <NoTranslate>{COMPANY_NAME[REGION_LOCALE]}</NoTranslate>
          </UITextLabelMedium>
          <UITextBodySmall>{COMPANY_ADDRESS[REGION_LOCALE]}</UITextBodySmall>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: '30px',
        }}
      >
        <TextNormal
          sx={{
            fontSize: '12px',
            color: colorsConfig.lightGray,
          }}
        >
          © 2024 Insercle AG.{' '}
          <span style={{ textTransform: 'uppercase' }}>{t('footer_all_rights_reserved')}</span>.
        </TextNormal>
      </Box>
    </Box>
  )
}

export default Footer2
