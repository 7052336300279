import { createAsyncThunk } from '@reduxjs/toolkit'
import controlPointsApiService from '../../../api/appraisalService/controlPointsApiService'

export const updateControlPoints = createAsyncThunk(
  'car/updateControlPoints',
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const carId = getState().car.carData.id
      await controlPointsApiService.updateControlPoints(carId, data)
      return data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)
