import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid, Stack } from '@mui/material'
import ButtonLG from '../../../../../ui/Buttons/ButtonLG'
import FormDate from '../../../FormComponents/FormDate'
import FormAgreement from '../../../FormComponents/FormAgreement'
import CarWarrantyPackages from '../../../../../pages/Warranty/CarWarranty/components/CarWarrantyPackages'
import CarWarrantyConfirmationModal from '../../../../../pages/Warranty/CarWarranty/components/CarWarrantyConfirmationModal'
import { useSelector, useDispatch } from 'react-redux'
import { activateWarranty } from '../../../../../store/features/warrantyApplicationSlice/warrantyApplication.actions'
import AnimationFinancingModal from '../../../../Modals/AnimationFinancingModal'
import { getWarrantyDetailsFormInitialState } from './warrantyDetailsForm.constants'

const WarrantyDetailsForm = () => {
  const warrantyActivated = useSelector((state) => state.warrantyApplication.warrantyDetails.saved)
  const warrantyApplication = useSelector((state) => state.warrantyApplication.warrantyApplication)
  const isLoading = useSelector((state) => state.warrantyApplication.warrantyDetails.isLoading)
  const dispatch = useDispatch()
  const editingDisabled = warrantyApplication?.currentWorkflowState === 'issued'

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const handleSubmit = async (values) => {
    const applicationData = {
      startDate: values?.startDate,
    }
    const selectedProductData = {
      selectedProduct: values?.selectedProduct,
    }
    const res = await dispatch(
      activateWarranty({
        applicationData,
        selectedProductData,
      }),
    )
    if (activateWarranty.fulfilled.match(res)) {
      setConfirmationModal(false)
      setSuccessModal(true)
    }
  }

  return (
    <Formik
      initialValues={getWarrantyDetailsFormInitialState(warrantyApplication)}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {(props) => (
        <Form>
          <Grid container spacing="30px">
            <Grid item xs={12} md={4}>
              <FormDate
                id="startDate"
                name="startDate"
                label="Start date"
                disablePast={warrantyApplication?.currentWorkflowState !== 'issued'}
                disabled={isLoading || editingDisabled || warrantyActivated}
              />
            </Grid>
            <Grid item xs={12}>
              <CarWarrantyPackages
                name="selectedProduct"
                disabled={isLoading || editingDisabled || warrantyActivated}
              />
            </Grid>
            {!warrantyActivated ? (
              <Grid xs={12} item display="flex" justifyContent="flex-end">
                <Stack gap="10px">
                  <FormAgreement
                    id="consent"
                    name="consent"
                    label={
                      <span>
                        I consent to the processing <br />
                        of my personal data
                      </span>
                    }
                  />
                  <ButtonLG
                    disabled={isLoading || !props.isValid}
                    onClick={() => setConfirmationModal(true)}
                  >
                    Activate warranty
                  </ButtonLG>
                </Stack>
              </Grid>
            ) : null}
          </Grid>
          <CarWarrantyConfirmationModal
            open={confirmationModal}
            setOpen={setConfirmationModal}
            handleSubmit={() => handleSubmit(props.values)}
          />
          <AnimationFinancingModal open={successModal} setOpen={setSuccessModal} />
        </Form>
      )}
    </Formik>
  )
}

export default WarrantyDetailsForm
