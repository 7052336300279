import React from 'react'
import { Stack } from '@mui/material'
import { KeyboardDoubleArrowRightRounded } from '@mui/icons-material'
import UIBreadcrumbButton from './UIBreadcrumbButton'
import { colorsConfig } from '../../config/themeConfig'

const UIBreadcrumbs = ({ pages, ...props }) => {
  return (
    <Stack direction="row">
      {pages.map((page, index) => {
        return (
          <Stack key={page?.name ?? index} direction="row" alignItems="center" gap="3px">
            <UIBreadcrumbButton text={page?.name} prefixIcon={page?.icon} link={page?.link} />
            {index !== pages.length - 1 ? (
              <KeyboardDoubleArrowRightRounded
                sx={{ fill: colorsConfig.lightGray, fontSize: '16px' }}
              />
            ) : null}
          </Stack>
        )
      })}
    </Stack>
  )
}

export default UIBreadcrumbs
