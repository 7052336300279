import React, { useState, useEffect } from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useParams } from 'react-router-dom'
import TextH1 from '../../../../ui/Text/TextH1'
import CarDefaultPicture from '../../../../assests/img/car-not-found.png'
import TextNormal from '../../../../ui/Text/TextNormal'
import TextBoldNormal from '../../../../ui/Text/TextBoldNormal'
import { colorsConfig } from '../../../../config/themeConfig'
import ButtonSmall from '../../../../ui/Buttons/ButtonSmall'
import { privateRequest } from '../../../../utils/request'
import { sumDelimiter } from '../../../../utils/sumDelimiter'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../../../config/default'
import { toast } from 'react-toastify'
import ConfirmationModal from './ConfirmationModal'
import TextFieldSmall from '../../../../ui/TextFieldSmall'
import ButtonDangerSmall from '../../../../ui/Buttons/ButtonDangerSmall'
import DealTransportationForm from './DealTransportationForm'

const DealSummary = () => {
  const [photo, setPhoto] = useState(CarDefaultPicture)

  const largeOnly = useMediaQuery('(min-width: 1350px)')

  const params = useParams()

  const [deal, setDeal] = useState('')
  const [dealAmount, setDealAmount] = useState(0)
  const [sellerName, setSellerName] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [dealOpened, setDealOpened] = useState('')
  const [carTitle, setCarTitle] = useState('')
  const [currentWorkflowState, setCurrentWorkflowState] = useState('')
  const [rejectionReason, setRejectionReason] = useState('')

  const [modalMoneyReceived, setModalMoneyReceived] = useState(false)
  const [modalMoneyPayed, setModalMoneyPayed] = useState(false)
  const [modalSendToTransportation, setModalSendToTransportation] = useState(false)
  const [modalTransported, setModalTransported] = useState(false)
  const [invoiceSentModal, setInvoiceSentModal] = useState(false)
  const [cancelDealModal, setCancelDealModal] = useState(false)

  const getFormattedDate = (date) => {
    const endTimeDate = new Date(date)
    const endYear = endTimeDate.getFullYear()
    let endMonth = endTimeDate.getMonth() + 1
    let endDay = endTimeDate.getDate()
    if (endDay < 10) endDay = '0' + endDay
    if (endMonth < 10) endMonth = '0' + endMonth
    const formattedEndDate = endDay + '.' + endMonth + '.' + endYear
    return formattedEndDate
  }

  const getLastDeal = () => {
    privateRequest.get(`/cars/${params.id}/last-deal`).then((response) => {
      setDeal(`/deals/${response.data.id}`)
      if (response.data.value) {
        setDealAmount(response.data.value)
      }
      if (response.data.car.mainForeshortening) {
        setPhoto(`${CDN_BASE_URL}${response.data.car.mainForeshortening.photo.path}`)
      }
      getCompany(response.data.seller['@id'], 'seller')
      getCompany(response.data.buyer['@id'], 'buyer')
      const createdAt = new Date(response.data.createdAt)
      const referenceNumber = response.data.car.referenceNumber
        ? response.data.car.referenceNumber
        : response.data.car.id
      const make = response.data.car.make.name
      const model = response.data.car.model.name
      setCurrentWorkflowState(response.data.currentWorkflowState)
      setCarTitle(`#${referenceNumber} ${make} ${model}`)
      setDealOpened(getFormattedDate(createdAt))
    })
  }

  const getCompany = (id, role) => {
    privateRequest.get(id).then((response) => {
      if (role === 'seller') setSellerName(response.data.name)
      if (role === 'buyer') setBuyerName(response.data.name)
    })
  }

  const workflowChangeInvoiceSent = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.invoiceSent}`, {})
      .then(() => {
        setCurrentWorkflowState('invoice_sent')
        setInvoiceSentModal(false)
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  const workflowChangeMoneyReceived = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.moneyReceived}`, {})
      .then(() => {
        setCurrentWorkflowState('money_received')
        setModalMoneyReceived(false)
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  const workflowChangeMoneyPayed = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.moneyPayed}`, {})
      .then(() => {
        setCurrentWorkflowState('money_payed')
        setModalMoneyPayed(false)
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  const workflowChangeSendToTransportation = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.sendToTransportation}`, {})
      .then(() => {
        setCurrentWorkflowState('transportation_in_progress')
        setModalSendToTransportation(false)
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  const workflowChangeTransported = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.soldAndDelivered}`, {})
      .then(() => {
        setCurrentWorkflowState('succeed')
        setModalTransported(false)
        toast.success("The car has been transported and moved to the buyer's virtual garage")
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  const handleCancelDeal = () => {
    privateRequest
      .post(`${deal}${API_ENDPOINTS.dealWorkflowTransition.cancel}`, {
        cancellationReason: rejectionReason,
      })
      .then(() => {
        setCurrentWorkflowState('cancel')
        setCancelDealModal(false)
        toast.success('The deal has been cancelled')
      })
      .catch(() => {
        toast.error('An error occurred, please try again later')
      })
  }

  useEffect(() => {
    getLastDeal()
  }, [])

  return (
    <Box>
      <TextH1
        sx={{
          mb: '25px',
        }}
      >
        {carTitle}
      </TextH1>
      <Grid container spacing={3} columns={10}>
        <Grid item md={4}>
          <Box
            sx={{
              height: '298px',
            }}
          >
            <img
              src={photo}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '6px',
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                mb: '32px',
              }}
            >
              <Box
                sx={{
                  mr: '40px',
                }}
              >
                <TextNormal
                  sx={{
                    mb: '2px',
                  }}
                >
                  Seller
                </TextNormal>
                <TextBoldNormal>{sellerName}</TextBoldNormal>
              </Box>
              <Box>
                <TextNormal
                  sx={{
                    mb: '2px',
                  }}
                >
                  Buyer
                </TextNormal>
                <TextBoldNormal>{buyerName}</TextBoldNormal>
              </Box>
            </Box>
            <Box
              sx={{
                mb: '29px',
              }}
            >
              <TextNormal
                sx={{
                  mb: '2px',
                }}
              >
                Deal opened
              </TextNormal>
              <TextBoldNormal>{dealOpened}</TextBoldNormal>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  px: '14.47px',
                  py: '11.2px',
                  border: '1px solid',
                  borderColor: colorsConfig.mainBlack,
                  borderRadius: '4px',
                  mb: '41px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TextNormal sx={{ mr: '10px' }}>Deal Amount:</TextNormal>
                <TextBoldNormal>{sumDelimiter(dealAmount).replace("'", ' ')} AED</TextBoldNormal>
              </Box>
            </Box>
            {currentWorkflowState === 'cancel' ? (
              <Box>
                <TextBoldNormal
                  sx={{
                    color: colorsConfig.mainRed,
                  }}
                >
                  The deal has been cancelled
                </TextBoldNormal>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: largeOnly ? '765px' : 'auto',
                  flexWrap: largeOnly ? '' : 'wrap',
                }}
              >
                <ButtonSmall
                  sx={{
                    mr: '8px',
                    my: largeOnly ? '' : '4px',
                  }}
                  className={
                    currentWorkflowState && currentWorkflowState !== 'pending' ? 'success' : ''
                  }
                  onClick={() => {
                    setInvoiceSentModal(true)
                  }}
                >
                  Invoice Sent
                </ButtonSmall>
                <ButtonSmall
                  sx={{
                    mr: '8px',
                    my: largeOnly ? '' : '4px',
                  }}
                  className={
                    currentWorkflowState &&
                    currentWorkflowState !== 'pending' &&
                    currentWorkflowState !== 'invoice_sent'
                      ? 'success'
                      : ''
                  }
                  disabled={currentWorkflowState === 'pending'}
                  onClick={() => {
                    setModalMoneyReceived(true)
                  }}
                >
                  Money received
                </ButtonSmall>
                <ButtonSmall
                  sx={{
                    mr: '8px',
                    my: largeOnly ? '' : '4px',
                  }}
                  className={
                    currentWorkflowState &&
                    currentWorkflowState !== 'pending' &&
                    currentWorkflowState !== 'invoice_sent' &&
                    currentWorkflowState !== 'money_received'
                      ? 'success'
                      : ''
                  }
                  disabled={
                    currentWorkflowState === 'pending' || currentWorkflowState === 'invoice_sent'
                  }
                  onClick={() => {
                    setModalMoneyPayed(true)
                  }}
                >
                  Money payed
                </ButtonSmall>
                <ButtonSmall
                  sx={{
                    mr: '8px',
                    my: largeOnly ? '' : '4px',
                  }}
                  className={
                    currentWorkflowState &&
                    currentWorkflowState !== 'pending' &&
                    currentWorkflowState !== 'invoice_sent' &&
                    currentWorkflowState !== 'money_received' &&
                    currentWorkflowState !== 'money_payed'
                      ? 'success'
                      : ''
                  }
                  disabled={
                    currentWorkflowState === 'pending' ||
                    currentWorkflowState === 'money_received' ||
                    currentWorkflowState === 'invoice_sent'
                  }
                  onClick={() => {
                    setModalSendToTransportation(true)
                  }}
                >
                  Initiate transportation
                </ButtonSmall>
                <ButtonSmall
                  sx={{
                    my: largeOnly ? '' : '4px',
                  }}
                  className={currentWorkflowState === 'succeed' ? 'success' : ''}
                  disabled={
                    currentWorkflowState === 'pending' ||
                    currentWorkflowState === 'money_received' ||
                    currentWorkflowState === 'money_payed' ||
                    currentWorkflowState === 'invoice_sent'
                  }
                  onClick={() => {
                    setModalTransported(true)
                  }}
                >
                  Transported
                </ButtonSmall>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {currentWorkflowState === 'money_payed' ||
      currentWorkflowState === 'transportation_in_progress' ? (
        <DealTransportationForm deal={deal} />
      ) : null}
      {currentWorkflowState !== 'cancel' ? (
        <Box
          sx={{
            mt: '40px',
            position: 'relative',
            minHeight: '159px',
          }}
        >
          <TextFieldSmall
            fullWidth
            label={'Write the reason of rejection'}
            type="textarea"
            multiline
            sx={{
              height: 'auto',
            }}
            rows={7}
            maxRows={10}
            value={rejectionReason}
            onChange={(e) => {
              setRejectionReason(e.target.value)
            }}
          />
          <ButtonDangerSmall
            sx={{
              position: 'absolute',
              bottom: '16px',
              right: '15px',
            }}
            onClick={() => {
              setCancelDealModal(true)
            }}
            disabled={rejectionReason === '' || currentWorkflowState === 'succeed'}
          >
            Reject the deal
          </ButtonDangerSmall>
        </Box>
      ) : null}
      <ConfirmationModal
        open={modalMoneyReceived}
        setOpen={setModalMoneyReceived}
        title={'Are you sure that payment was transferred to Fleatify account?'}
        confirmButton={'Money received'}
        confirmAction={() => {
          workflowChangeMoneyReceived()
        }}
      />
      <ConfirmationModal
        open={modalMoneyPayed}
        setOpen={setModalMoneyPayed}
        title={"Are you sure that payment was transferred to dealer's account?"}
        confirmButton={'Money payed'}
        confirmAction={() => {
          workflowChangeMoneyPayed()
        }}
      />
      <ConfirmationModal
        open={modalSendToTransportation}
        setOpen={setModalSendToTransportation}
        title={'Are you sure the transportation is booked for this deal?'}
        confirmButton={'Transportation initiated'}
        confirmAction={() => {
          workflowChangeSendToTransportation()
        }}
      />
      <ConfirmationModal
        open={modalTransported}
        setOpen={setModalTransported}
        title={'Are you sure the car has been transported to its buyer?'}
        confirmButton={'Car transported'}
        confirmAction={() => {
          workflowChangeTransported()
        }}
      />
      <ConfirmationModal
        open={invoiceSentModal}
        setOpen={setInvoiceSentModal}
        title={'Are you sure the invoice has been sent to the buyer?'}
        confirmButton={'Invoice sent'}
        confirmAction={() => {
          workflowChangeInvoiceSent()
        }}
      />
      <ConfirmationModal
        open={cancelDealModal}
        setOpen={setCancelDealModal}
        title={'Are you sure you want to reject the deal?'}
        confirmButton={'Reject the deal'}
        confirmAction={() => {
          handleCancelDeal()
        }}
      />
    </Box>
  )
}

export default DealSummary
