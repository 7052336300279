import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import localization from './json/localization.json'
import { buttons_de, buttons_en, buttons_fr, buttons_it } from './translations/TranslationsButtons'
import { inputs_de, inputs_en, inputs_fr, inputs_it } from './translations/TransaltionsInputs'
import { sidebar_de, sidebar_en, sidebar_fr, sidebar_it } from './translations/TranslationsSidebar'
import { enums_de, enums_en, enums_fr, enums_it } from './translations/TransaltionsEnums'
import { titles_de, titles_en, titles_fr, titles_it } from './translations/TranslationsTitles'
import { p_de, p_en, p_fr, p_it } from './translations/TranslationsParagraphs'
import {
  landing_page_de,
  landing_page_en,
  landing_page_fr,
  landing_page_it,
} from './translations/TranslationsLandingPage'
import translationsEN from './translations_en.json'
import translationsDE from './translations_de.json'
import translationsFR from './translations_fr.json'
import translationsIT from './translations_it.json'

// const resources = {
//   en: {
//     translation: {
//       sidebar: sidebar_en,
//       virtual_garage: {
//         title: 'Virtual Garage',
//         my_fleet: 'My fleet',
//         banners: {
//           banner_green: 'Helping the world getting greener',
//           banner_green_description:
//             'We are dedicated to promoting eco-friendly cars and raising awareness about the importance of green transportation',
//           banner_auction: 'Sell your car with the highest price',
//           banner_appraisal: 'Assess condition of your vehicles',
//           banner_insurance: 'Insurance with Fleetify',
//           banner_finance: 'Finance with Fleetify',
//           banner_car_warranty: 'Car warranty with Fleetify',
//           banner_car_subscription: 'Car subscription with Fleetify',
//         },
//       },
//       buttons: buttons_en,
//       tables: {
//         number: 'Number',
//         image: 'Image',
//         brand_model: 'Brand/Model',
//         location: 'Location',
//         days_in_stock: 'Days in stock',
//         status: 'Status',
//         action: 'Action',
//         values: {
//           your_garage: 'Your garage',
//         },
//       },
//       time: {
//         day: 'day',
//         days: 'days',
//       },
//       input: inputs_en,
//       enums: enums_en,
//       titles: titles_en,
//       p: p_en,
//       common: {
//         all: 'All',
//       },
//       promo: landing_page_en,
//       ...translationsEN,
//     },
//   },
//   de: {
//     translation: {
//       sidebar: sidebar_de,
//       virtual_garage: {
//         title: 'Virtuelle Garage',
//         my_fleet: 'Meine Flotte',
//         banners: {
//           banner_green: 'Der Welt helfen, zu bekommen grüner',
//           banner_green_description:
//             'Wir widmen uns der Förderung umweltfreundliche Autos und Erziehung Bewusstsein für die Bedeutung des umweltfreundlichen Transportwesens',
//           banner_auction: 'Verkaufen Sie Ihr Auto zum höchsten Preis',
//           banner_appraisal: 'Bewerten Sie den Zustand Ihrer Fahrzeuge',
//           banner_insurance: 'Versicherung mit Fleetify',
//           banner_finance: 'Finanzieren mit Fleetify',
//           banner_car_warranty: 'Autogarantie mit Fleetify',
//           banner_car_subscription: 'Abonnement voiture avec Fleetify',
//         },
//       },
//       buttons: buttons_de,
//       tables: {
//         number: 'Nummer',
//         image: 'Bild',
//         brand_model: 'Markenmodell',
//         location: 'Standort',
//         days_in_stock: 'Tage auf Lager',
//         status: 'Status',
//         action: 'Aktion',
//         values: {
//           your_garage: 'Ihre Garage',
//         },
//       },
//       time: {
//         day: 'Tag',
//         days: 'Tage',
//       },
//       input: inputs_de,
//       enums: enums_de,
//       titles: titles_de,
//       p: p_de,
//       common: {
//         all: 'Alle',
//       },
//       promo: landing_page_de,
//       ...translationsDE,
//     },
//   },
//   fr: {
//     translation: {
//       sidebar: sidebar_fr,
//       virtual_garage: {
//         title: 'Garage virtuel',
//         my_fleet: 'Ma flotte',
//         banners: {
//           banner_green: 'Aider le monde à obtenir plus vert',
//           banner_green_description:
//             "Nous nous engageons à promouvoir voitures écologiques et élevage prise de conscience de l'importance du transport vert",
//           banner_auction: 'Vendez votre voiture au prix le plus élevé',
//           banner_appraisal: "Évaluez l'état de vos véhicules",
//           banner_insurance: 'Assurance avec Fleetify',
//           banner_finance: 'Financer avec Fleetify',
//           banner_car_warranty: 'Garantie automobile avec Fleetify',
//           banner_car_subscription: 'Auto-Abo mit Fleetify',
//         },
//       },
//       buttons: buttons_fr,
//       tables: {
//         number: 'Nombre',
//         image: 'Image',
//         brand_model: 'Modèle de marque',
//         location: 'Emplacement',
//         days_in_stock: 'Jours en stock',
//         status: 'Statut',
//         action: 'Action',
//         values: {
//           your_garage: 'Votre garage',
//         },
//       },
//       time: {
//         day: 'jour',
//         days: 'jours',
//       },
//       input: inputs_fr,
//       enums: enums_fr,
//       titles: titles_fr,
//       p: p_fr,
//       common: {
//         all: 'Tous',
//       },
//       promo: landing_page_fr,
//       ...translationsFR,
//     },
//   },
//   it: {
//     translation: {
//       sidebar: sidebar_it,
//       virtual_garage: {
//         title: 'Garage virtuale',
//         my_fleet: 'La mia flotta',
//         banners: {
//           banner_green: 'Aiutare il mondo a ottenere più verde',
//           banner_green_description:
//             'Ci dedichiamo alla promozione auto ecologiche e allevamenti consapevolezza dell’importanza dei trasporti verdi',
//           banner_auction: 'Vendi la tua auto al prezzo più alto',
//           banner_appraisal: 'Valuta le condizioni dei tuoi veicoli',
//           banner_insurance: 'Assicurazione con Fleetify',
//           banner_finance: 'Finanzia con Fleetify',
//           banner_car_warranty: 'Garanzia auto con Fleetify',
//           banner_car_subscription: 'Abbonamento auto con Fleetify',
//         },
//       },
//       buttons: buttons_it,
//       tables: {
//         number: 'Numero',
//         image: 'Immagine',
//         brand_model: 'Marca/Modello',
//         location: 'Posizione',
//         days_in_stock: 'Giorni in magazzino',
//         status: 'Stato',
//         action: 'Azione',
//         values: {
//           your_garage: 'Il tuo garage',
//         },
//       },
//       time: {
//         day: 'giorno',
//         days: 'giorni',
//       },
//       input: inputs_it,
//       enums: enums_it,
//       titles: titles_it,
//       p: p_it,
//       common: {
//         all: 'Tutti',
//       },
//       promo: landing_page_it,
//       ...translationsIT,
//     },
//   },
// }

const resources = localization

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
