import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import workflowsService from '../../../api/workflowsService/workflowsService'
import { APIErrors } from '../../../api/APIErrors'
import { getLocalWorkflowsState } from '../../../utils/localStorage.helpers'

export const getWorkflowsStates = createAsyncThunk(
  'workflows/getWorkflows',
  async ({}, { rejectWithValue }) => {
    const localWorkflows = await getLocalWorkflowsState()
    if (localWorkflows && localWorkflows?.insuranceApplications) {
      return localWorkflows?.insuranceApplications
    }
    try {
      const response = await workflowsService.getInsuranceApplicationWorkflowStates()
      return response.data['hydra:member']
    } catch (e) {
      if (!e.response) toast.error(APIErrors.NETWORK_ERROR)
      return rejectWithValue(e.response.data)
    }
  },
)
