import { createAsyncThunk } from '@reduxjs/toolkit'
import referencesApiService from '../../../api/referencesApiService/referencesApiService'

export const getEnumDetails = createAsyncThunk(
  'references/getEnumDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await referencesApiService.getEnumDetails()
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
