import { createSlice } from '@reduxjs/toolkit'
import getEnumDetailsBuilder from './builders/getEnumDetails.builder'

const initialState = {
  enumDetails: {
    isLoading: false,
    error: null,
    data: [],
  },
}

const referencesSlice = createSlice({
  name: 'referencesReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getEnumDetailsBuilder(builder)
  },
})

export default referencesSlice.reducer
