import React from 'react'
import { IconButton } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'
import IconWA from '../../../assests/img/icons/icon-wa.svg'

const ButtonLinkWA = ({ title = 'Share via WhatsApp', message, sx, ...props }) => {
  return (
    <Tooltip title={title}>
      <IconButton
        component="a"
        href={`https://wa.me/?text=${encodeURIComponent(message)}`}
        target="_blank"
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '6px',
          ...sx,
        }}
        {...props}
      >
        <img
          src={IconWA}
          alt="WhatsApp"
          style={{
            width: '80%',
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default ButtonLinkWA
