import React from 'react'
import { CircularProgress, Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import UITextRegular from '../UIText/UITextRegular'

const UIPreloader = ({ sx, size, text, isExtended, ...props }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        zIndex: 10,
        ...(isExtended && {
          top: '-10px',
          left: '-10px',
          width: 'calc(100% + 20px)',
          height: 'calc(100% + 20px)',
        }),
        ...sx,
      }}
      {...props}
    >
      <CircularProgress
        size={size ?? 30}
        sx={{
          '.css-176wh8e-MuiCircularProgress-circle': {
            color: colorsConfig.mainBlue,
          },
        }}
      />
      {text ? <UITextRegular text={text} /> : null}
    </Box>
  )
}

export default UIPreloader
