import React, { useState } from 'react'
import { colorsConfig } from '../../../config/themeConfig'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import { ExpandMoreRounded } from '@mui/icons-material'
import IconAttention from '../../../assests/img/icons/icon-attention-sm.svg'
import BankResponseItem from './BankResponseItem'
import { useSelector, useDispatch } from 'react-redux'
import { setBankResponsesOpened } from '../../../store/features/financeCar'
import ButtonHollow from '../../../ui/Buttons/ButtonHollow'
import ButtonLG from '../../../ui/Buttons/ButtonLG'
import IconAnchor from '../../../assests/img/icons/icon-anchor.svg'
import { patchRequest, privateRequest } from '../../../utils/request'
import { API_FINANCE_ENDPOINTS } from '../../../config/default'
import { toast } from 'react-toastify'
import FinanceLinkModal from '../../../components/Modals/FinanceLinkModal'

const BankResponses = () => {
  const dispatch = useDispatch()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const expanded = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].bankResponsesOpened,
  )
  const disabled = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].bankResponsesDisabled,
  )
  const loanDeals = useSelector((state) => state.carFinance.loanApplications[selectedTab].loanDeals)
  const loanApplicationId = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].id,
  )
  const loanApplication = useSelector((state) => state.carFinance.loanApplications[selectedTab])

  const [linkModal, setLinkModal] = useState(false)

  const handleSendEmailToCustomer = () => {
    Promise.all(
      loanDeals.map((item) =>
        patchRequest.patch(
          API_FINANCE_ENDPOINTS.loanDealByLoanApplication(item.id, loanApplicationId),
          { isDealerSelected: item.isDealerSelected },
        ),
      ),
    ).then((result) => {
      privateRequest
        .post(API_FINANCE_ENDPOINTS.sendCustomerApprovalLink(loanApplicationId), {})
        .then(() => {
          toast.success('The approval link has been sent to the customer')
        })
    })
  }

  const handleOpenLinkModal = () => {
    Promise.all(
      loanDeals.map((item) =>
        patchRequest.patch(
          API_FINANCE_ENDPOINTS.loanDealByLoanApplication(item.id, loanApplicationId),
          { isDealerSelected: item.isDealerSelected },
        ),
      ),
    ).then(async (result) => {
      setLinkModal(true)
    })
  }

  return (
    <Box
      className={disabled ? 'disabled' : ''}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: colorsConfig.bgLightBlue,
        mt: '30px',
        '&.disabled': {
          pointerEvents: 'none',
          opacity: 0.5,
          transition: '0.25s all ease-in-out',
        },
      }}
    >
      <Box
        className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
        sx={{
          height: '79px',
          py: '17px',
          px: '29px',
          border: '1px solid #DCDCDC',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          zIndex: 2,
          '&.xs-only': {
            height: 'auto',
            py: '10px',
            px: '16px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box>
          <TextBoldNormal
            sx={{
              color: colorsConfig.mainBlue,
              fontSize: '18px',
            }}
          >
            Bank responses{' '}
            <span style={{ color: colorsConfig.mainGreen }}>
              (
              {
                loanDeals.filter(
                  (loanDeal) =>
                    loanDeal.currentWorkflowState !== 'new' &&
                    loanDeal.currentWorkflowState !== 'bank_sent',
                ).length
              }
              )
            </span>
          </TextBoldNormal>
          <TextNormal>Choose the best option for you and your customer</TextNormal>
        </Box>
        <Box>
          <IconButton
            className={expanded ? 'open' : ''}
            sx={{
              position: 'relative',
              mr: '-15px',
              transition: '0.25s all ease-in-out',
              '&.open': {
                transform: 'rotate(180deg)',
                transition: '0.25s all ease-in-out',
              },
            }}
            onClick={() => {
              dispatch(setBankResponsesOpened(!expanded))
            }}
          >
            <ExpandMoreRounded
              sx={{
                fontSize: '23px',
                width: '40px',
                height: '40px',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={[
          expanded ? '' : 'collapsed',
          xsOnly ? 'xs-only' : '',
          tabletOnly ? 'tablet-only' : '',
        ].join(' ')}
        sx={{
          paddingTop: '119px',
          mt: '-79px',
          position: 'relative',
          px: '29px',
          pb: '35px',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: colorsConfig.buttonDisabled,
          background: colorsConfig.bgLightBlue,
          transition: '0.3s all ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          '&.collapsed': {
            mt: '-70%',
            display: 'none',
            opacity: '0',
            pointerEvents: 'none',
            transition: '0.3s all ease-in-out',
          },
          '&.xs-only': {
            mt: '-96px',
            px: '16px',
          },
          '&.tablet-only': {
            px: '16px',
          },
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            background: '#E1F0FF',
            px: '24px',
            py: '13px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              alignItems: 'flex-start',
              '& > img': {
                mt: '12px',
              },
            },
          }}
        >
          <img src={IconAttention} alt="" />
          <Box
            sx={{
              ml: '11px',
            }}
          >
            <TextBoldNormal>Dealer commission is visible only for you</TextBoldNormal>
            <TextNormal>
              When you send a link to a client, the dealer's commission is not displayed to them
            </TextNormal>
          </Box>
        </Box>
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            background: '#D6FFDE',
            px: '24px',
            py: '13px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              alignItems: 'flex-start',
              '& > img': {
                mt: '12px',
              },
            },
          }}
        >
          <img src={IconAttention} alt="" />
          <Box
            sx={{
              ml: '11px',
            }}
          >
            <TextBoldNormal>
              Select the best programs for your customer and send it for approval
            </TextBoldNormal>
            <TextNormal>
              Mark the most compelling offers and share them with the client via a link or email
            </TextNormal>
          </Box>
        </Box>
        {Boolean(
          loanDeals.filter(
            (loanDeal) =>
              loanDeal.currentWorkflowState !== 'new' &&
              loanDeal.currentWorkflowState !== 'bank_sent',
          )[0],
        )
          ? loanDeals.map((item, index) => {
              if (
                item.currentWorkflowState !== 'approved' &&
                item.currentWorkflowState !== 'bank_declined' &&
                item.currentWorkflowState !== 'bank_request_info'
              )
                return null
              return <BankResponseItem key={index} index={index} />
            })
          : null}
        {!loanDeals.filter((item) => item.isCustomerSelected)[0] ? (
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              '&.xs-only': {
                display: 'block',
              },
            }}
          >
            <ButtonHollow
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                mr: '12px',
                width: '193px',
                px: 0,
                '&.xs-only': {
                  width: '100%',
                  mb: '8px',
                },
              }}
              disabled={!loanDeals.filter((item) => item.isDealerSelected)[0]}
              onClick={() => {
                handleSendEmailToCustomer()
              }}
            >
              Send by email
            </ButtonHollow>
            <ButtonLG
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: '193px',
                px: 0,
                '&.Mui-disabled': {
                  background: colorsConfig.buttonDisabled,
                  color: 'white',
                },
                '&.xs-only': {
                  width: '100%',
                },
              }}
              disabled={!loanDeals.filter((item) => item.isDealerSelected)[0]}
              onClick={() => {
                handleOpenLinkModal()
              }}
            >
              <img
                src={IconAnchor}
                alt=""
                style={{
                  marginRight: '6px',
                }}
              />
              Copy approval link
            </ButtonLG>
          </Box>
        ) : null}
      </Box>
      <FinanceLinkModal
        open={linkModal}
        setOpen={setLinkModal}
        customerName={loanApplication?.customer?.person?.firstName}
        link={`https://sb-auction.insercle.com/finance/customer/loan-applications/${loanApplication.secureHash}`}
      />
    </Box>
  )
}

export default BankResponses
