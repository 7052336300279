import React from 'react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../../config/themeConfig'
import BankLogo from '../../../assests/img/bank-example.svg'
import TextBoldNormal from '../../../ui/Text/TextBoldNormal'
import TextNormal from '../../../ui/Text/TextNormal'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import IconFileWithCounter from '../../../assests/img/icons/icon-file-with-counter.svg'
import { downloadFileFromPrivateBucket } from '../../../utils/downloadFile'
import { useSelector, useDispatch } from 'react-redux'
import SimpleCheckbox from '../../../ui/SimpleCheckbox'
import { setIsDealerSelected } from '../../../store/features/financeCar'
import BankResponseItemRequestInfo from './BankResponseItemRequestInfo'
import { isNaN } from 'lodash'

const BankResponseItem = (props) => {
  const { index } = props

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const dispatch = useDispatch()

  const banks = useSelector((state) => state.carFinance.banks)
  const selectedTab = useSelector((state) => state.carFinance.selectedTab)
  const loanDeal = useSelector(
    (state) => state.carFinance.loanApplications[selectedTab].loanDeals[index],
  )
  const loanApplication = useSelector((state) => state.carFinance.loanApplications[selectedTab])

  const hasCustomerSelectedOffer = Boolean(
    loanApplication.loanDeals.filter((item) => item.isCustomerSelected)[0],
  )

  const getDownPaymentPercentage = () => {
    const carPrice = parseFloat(loanApplication?.carPrice)
    if (!carPrice || isNaN(carPrice)) return '-'
    const value = parseFloat(loanDeal?.approvedParamsData?.dp)
    const fallBackValue = parseFloat(loanApplication?.dp)
    if ((!value || isNaN(value)) && (!fallBackValue || isNaN(fallBackValue))) return '-'
    const finalValue = !value || isNaN(value) ? fallBackValue : value
    return `${((finalValue / carPrice) * 100).toFixed(2)} %`
  }

  const getMonthlyInstallment = () => {
    const value = loanDeal?.approvedParamsData?.monthlyInstallment
    if (!value || !parseFloat(value)) return '-'
    return `${sumDelimiter(parseFloat(value).toFixed(2))} AED`
  }

  const getDealerCommission = () => {
    const value = loanDeal?.approvedParamsData?.dealerCommission
    if (!value || !parseFloat(value)) return '-'
    const sumInAED = `${sumDelimiter(parseFloat(value).toFixed(2)).replace("'", ' ')} AED`
    const percentage = `${((value / loanApplication?.carPrice) * 100).toFixed(2)} %`
    return `${sumInAED} (${percentage})`
  }

  const getLoanTerm = () => {
    const value = loanDeal?.approvedParamsData?.loanTerm
    if (!value || !parseFloat(value)) return `${loanApplication?.term} months`
    return `${value} months`
  }

  const getResidualValue = () => {
    const value = parseFloat(loanDeal?.approvedParamsData?.rvWarranty)
    if (!value || isNaN(value)) return '-'
    return `${value.toFixed(2)} %`
  }

  const getInterestRate = () => {
    const value = parseFloat(loanDeal?.approvedParamsData?.interestRate)
    if (!value || isNaN(value)) return '-'
    return `${value.toFixed(2)} %`
  }

  const getTotalAmount = () => {
    const value = parseFloat(loanDeal?.approvedParamsData?.loanAmount)
    if (!value || isNaN(value)) return '-'
    return `${sumDelimiter(value.toFixed(2))} AED`
  }

  const handleDownloadFiles = (files) => {
    files.forEach((item, index) => {
      if (files.length > 1) {
        downloadFileFromPrivateBucket(item.attachment.signedUrl, `Bank document ${index + 1}`)
        return
      }
      downloadFileFromPrivateBucket(item.attachment.signedUrl, `Bank document`)
    })
  }

  return (
    <Box
      className={[
        loanDeal.currentWorkflowState,
        hasCustomerSelectedOffer && !loanDeal.isCustomerSelected ? 'faded' : '',
      ].join(' ')}
      sx={{
        borderRadius: '6px',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        overflow: 'hidden',
        p: '15px',
        position: 'relative',
        background: 'white',
        '&::after': {
          content: '" "',
          pointerEvents: 'none',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '50px',
          top: 0,
          left: 0,
        },
        '&.approved': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(77, 237, 109, 0.15) 0%, rgba(77, 237, 109, 0.00) 100%)',
          },
        },
        '&.bank_request_info': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(245, 189, 79, 0.25) 0%, rgba(245, 189, 79, 0.00) 100%)',
          },
        },
        '&.bank_declined': {
          '&::after': {
            background:
              'linear-gradient(180deg, rgba(172, 0, 0, 0.15) 0%, rgba(172, 0, 0, 0.00) 100%)',
          },
        },
        '&.faded': {
          opacity: 0.6,
        },
      }}
    >
      {hasCustomerSelectedOffer ? (
        <Box
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {loanDeal.isCustomerSelected ? (
            <TextBoldNormal
              sx={{
                color: colorsConfig.mainGreen,
              }}
            >
              Customer choice
            </TextBoldNormal>
          ) : null}
        </Box>
      ) : loanDeal.currentWorkflowState === 'approved' ? (
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SimpleCheckbox
            value={loanDeal.isDealerSelected}
            setValue={(newVal) => {
              dispatch(
                setIsDealerSelected({
                  loanDeal: loanDeal.id,
                  value: newVal,
                }),
              )
            }}
          />
          <TextNormal
            sx={{
              ml: '2px',
            }}
          >
            Add
          </TextNormal>
        </Box>
      ) : null}
      {xsOnly ? (
        <TextBoldNormal
          className={loanDeal.currentWorkflowState}
          sx={{
            height: '24px',
            px: '10px',
            borderRadius: '100px',
            color: 'white',
            fontSize: '14px',
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '10px',
            '&.approved': {
              background: colorsConfig.mainGreen,
            },
            '&.bank_request_info': {
              background: colorsConfig.buttonYellow,
            },
            '&.bank_declined': {
              background: colorsConfig.mainRed,
            },
          }}
        >
          {loanDeal.currentWorkflowState === 'approved' ? 'Approved' : ''}
          {loanDeal.currentWorkflowState === 'bank_declined' ? 'Refused' : ''}
          {loanDeal.currentWorkflowState === 'bank_request_info' ? 'Bank request' : ''}
        </TextBoldNormal>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&.xs-only': {
              flexWrap: 'wrap',
            },
          }}
        >
          <img
            src={
              banks.filter((item) => item.id.toString() === loanDeal.bankId)[0]?.logo || BankLogo
            }
            alt=""
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
            }}
          />
          <TextBoldNormal sx={{ ml: '10px' }}>{loanDeal.bankTitle}:</TextBoldNormal>
          <TextBoldNormal
            sx={{
              ml: '6px',
              color: colorsConfig.mainBlue,
            }}
          >
            {loanDeal.approvedParamsData?.offerName || ''}
          </TextBoldNormal>
          {!xsOnly ? (
            <TextBoldNormal
              className={loanDeal.currentWorkflowState}
              sx={{
                ml: '10px',
                height: '24px',
                px: '10px',
                borderRadius: '100px',
                color: 'white',
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                '&.approved': {
                  background: colorsConfig.mainGreen,
                },
                '&.bank_request_info': {
                  background: colorsConfig.buttonYellow,
                },
                '&.bank_declined': {
                  background: colorsConfig.mainRed,
                },
              }}
            >
              {loanDeal.currentWorkflowState === 'approved' ? 'Approved' : ''}
              {loanDeal.currentWorkflowState === 'bank_declined' ? 'Refused' : ''}
              {loanDeal.currentWorkflowState === 'bank_request_info' ? 'Bank request' : ''}
            </TextBoldNormal>
          ) : null}
        </Box>
      </Box>
      <Box
        className={tabletOnly ? 'tablet-only' : ''}
        sx={{
          mt: '24px',
          display: 'flex',
          flexDirection: 'row',
          gap: '24px',
          flexWrap: 'wrap',
          '&.tablet-only': {
            gap: '18px',
            '& p': {
              fontSize: '14px',
              lineHeight: 'normal',
              mb: '4px',
              mt: 0,
              '&.title-highlight': {
                fontSize: '18px',
                mb: 0,
              },
            },
          },
        }}
      >
        <Box>
          <TextBoldNormal
            className={'title-highlight'}
            sx={{
              fontSize: '24px',
            }}
          >
            {getMonthlyInstallment()}
          </TextBoldNormal>
          <TextNormal>Total monthly instalment</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            className={'title-highlight'}
            sx={{
              fontSize: '24px',
            }}
          >
            {getDealerCommission()}
          </TextBoldNormal>
          <TextNormal>Total dealer commission</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getLoanTerm()}
          </TextBoldNormal>
          <TextNormal>Loan term</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getDownPaymentPercentage()}
          </TextBoldNormal>
          <TextNormal>Down Payment</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getResidualValue()}
          </TextBoldNormal>
          <TextNormal>Residual value</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getInterestRate()}
          </TextBoldNormal>
          <TextNormal>Interest rate</TextNormal>
        </Box>
        <Box>
          <TextBoldNormal
            sx={{
              mt: '4px',
              mb: '6px',
            }}
          >
            {getTotalAmount()}
          </TextBoldNormal>
          <TextNormal>Total amount</TextNormal>
        </Box>
      </Box>
      {loanDeal?.comments?.length > 0 ? (
        <Box
          sx={{
            mt: '30px',
          }}
        >
          <TextBoldNormal sx={{ mb: '6px' }}>Note from the bank:</TextBoldNormal>
          <TextNormal>{loanDeal?.comments?.[0]?.comment}</TextNormal>
        </Box>
      ) : null}
      {loanDeal.currentWorkflowState === 'bank_request_info' ? (
        <BankResponseItemRequestInfo loanDealIndex={index} />
      ) : null}
      {loanDeal.attachments.length > 0 ? (
        <Box sx={{ mt: '25px' }}>
          <TextBoldNormal sx={{ mb: '2px' }}>Bank provided documents:</TextBoldNormal>
          <TextNormal>Here are some documents to consider</TextNormal>
          <Button
            sx={{
              textTransform: 'none',
            }}
            onClick={() => {
              handleDownloadFiles(loanDeal.attachments)
            }}
          >
            <Box
              sx={{
                verticalAlign: 'top',
                position: 'relative',
              }}
            >
              <img
                src={IconFileWithCounter}
                alt=""
                style={{
                  verticalAlign: 'top',
                }}
              />
              <TextNormal
                sx={{
                  width: '15px',
                  height: '15px',
                  fontSize: '10px',
                  color: colorsConfig.mainBlue,
                  position: 'absolute',
                  bottom: '0.5px',
                  right: '1.5px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {loanDeal.attachments.length}
              </TextNormal>
            </Box>
            <Typography
              sx={{
                fontFamily: '"HelveticaNeue", sans-serif',
                fontSize: '16px',
                fontWeight: 500,
                color: colorsConfig.mainBlack,
                lineHeight: 'normal',
                ml: '6px',
              }}
            >
              Download
            </Typography>
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default BankResponseItem
