import { createAsyncThunk } from '@reduxjs/toolkit'
import companyAddressesApiService from '../../../api/companyAddressesService/companyAddressesApiService'

export const getCompanyAddresses = createAsyncThunk(
  'companyAddresses/getCompanyAddresses',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        pagination: false,
        isMain: true,
      }
      const response = await companyAddressesApiService.getCompanyAddresses(params)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e)
    }
  },
)
