import React from 'react'
import { colorsConfig } from '../../../../config/themeConfig'
import { Tab, Tabs } from '@mui/material'

const BankTabs = (props) => {
  const { tab, setTab, tabs } = props

  return (
    <Tabs
      value={tab}
      onChange={(e, newVal) => {
        setTab(newVal)
      }}
      variant="scrollable"
      sx={{
        width: '100%',
        '& .MuiTabs-indicator': {
          background: colorsConfig.mainGreen,
        },
        '& .MuiTab-textColorPrimary': {
          color: colorsConfig.mainBlack,
          textTransform: 'none',
          fontFamily: '"HelveticaNeue", sans-serif',
          fontSize: '16px',
          fontWeight: 500,
        },
        '& .MuiButtonBase-root': {
          background: 'transparent',
        },
        '& .Mui-selected': {
          color: 'transparent',
        },
      }}
    >
      {tabs.map((item, index) => {
        return <Tab key={index} label={item.name} />
      })}
    </Tabs>
  )
}

export default BankTabs
