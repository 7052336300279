import React from 'react'
import { Button } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { useTranslation } from 'react-i18next'

const UITextButton = ({ text, children, sx, prefixIcon, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      sx={{
        color: colorsConfig.mainBlue,
        fontFamily: '"Helvetica", sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        px: '20px',
        borderRadius: '4px',
        gap: '8px',
        ...sx,
      }}
      {...props}
    >
      {prefixIcon ?? null}
      {text ? t(text) : children}
    </Button>
  )
}

export default UITextButton
