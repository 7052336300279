import { createSlice } from '@reduxjs/toolkit'
import getWorkflowsStatesBuilder from './builders/getWorkflowsStates.builder'

const initialState = {
  loaded: false,
  carWorkflowStates: [],
  auctionWorkflowStates: [],
  dealWorkflowStates: [],
  insuranceApplicationWorkflowStates: [],
  loading: false,
  error: null,
}

export const workflowsReducer = createSlice({
  name: 'workflows',
  initialState: initialState,
  reducers: {
    setCarWorkflows: (state, action) => {
      state.carWorkflowStates = action.payload
      if (state.auctionWorkflowStates.length > 0 && state.dealWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
    setAuctionWorkflows: (state, action) => {
      state.auctionWorkflowStates = action.payload
      if (state.carWorkflowStates.length > 0 && state.dealWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
    setDealWorkflows: (state, action) => {
      state.dealWorkflowStates = action.payload
      if (state.carWorkflowStates.length > 0 && state.auctionWorkflowStates.length > 0) {
        state.loaded = true
      }
    },
  },
  extraReducers: (builder) => {
    getWorkflowsStatesBuilder(builder)
  },
})

export const { setCarWorkflows, setAuctionWorkflows, setDealWorkflows } = workflowsReducer.actions

export default workflowsReducer.reducer
