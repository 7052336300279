import * as Yup from 'yup'
import { formErrors } from '../../formErrors'

export const initialWarrantyCarDetailsFormState = (car) => {
  return {
    modelYear: car?.modelYear ?? '',
    carState: car?.state ?? '',
    enginePower: car?.hp ?? '',
    firstRegistrationDate: car?.firstRegistrationDate ?? '',
    mileage: car?.mileage ?? '',
    modelDetails: car?.details ?? '',
    isModified: car?.isModified ? 'yes' : 'no',
  }
}

export const warrantyCarDetailsFormValidationSchema = Yup.object().shape({
  modelYear: Yup.string().required(formErrors.required),
  carState: Yup.string().required(formErrors.required),
  enginePower: Yup.string().required(formErrors.required),
  firstRegistrationDate: Yup.string().required(formErrors.required),
  mileage: Yup.string().required(formErrors.required),
})
