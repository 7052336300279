import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Tabs, Tab, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import CarTable from '../VirtualGarage/CarTable'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { useSelector, useDispatch } from 'react-redux'
import { privateRequest } from '../../utils/request'
import { useNavigate } from 'react-router-dom'
import { setCurrentPageTitle } from '../../store/features/authSlice'
import { useTranslation } from 'react-i18next'
import BoxShadowed from '../../ui/Layout/BoxShadowed'
import { operatorAppraisalsTableHeadCells } from './OperatorPanelTableData'

const OperatorAppraisals = () => {
  const { t } = useTranslation()

  const extraLarge = useMediaQuery('(min-width: 1400px)')
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const actions = [
    {
      title: 'Finish quality check',
      conditions: [
        {
          role: 'OPERATOR',
          status: 'car_workflow.quality_check',
        },
      ],
      actions: [
        {
          type: 'navigate',
          link: '/cars/:id',
        },
      ],
    },
  ]

  const [tab, setTab] = useState(0)

  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const role = useSelector((state) => state.auth.role)
  const dispatch = useDispatch()

  const [cars, setCars] = useState([])
  const [appraisedCars, setAppraisedCars] = useState([])
  const [statuses, setStatuses] = useState([])
  const [filterStatus, setFilterStatus] = useState(null)
  const [sort, setSort] = useState('&order[lastUpdatedAt]=desc')
  const [searchLine, setSearchLine] = useState('')
  const [archivedCarsCount, setArchivedCarsCount] = useState(0)
  const [displayMode, setDisplayMode] = useState('table')

  const sections = [
    {
      id: 1,
      name: `Waiting for appraisal (${cars.length})`,
      component: (
        <Box
          sx={{
            position: 'absolute',
            top: '8px',
            right: '3px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle cx="5" cy="5" r="5" fill="#AC0000" />
          </svg>
        </Box>
      ),
    },
    {
      id: 2,
      name: `Appraised cars (${appraisedCars.length})`,
    },
  ]

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear()
    let mm = date.getMonth() + 1
    let dd = date.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    return dd + '.' + mm + '.' + yyyy
  }

  const getMarkerColor = (createdAt) => {
    const createdAtTime = new Date(createdAt).getTime()
    const currentTime = new Date().getTime()
    const timeDifferenceInHours = (currentTime - createdAtTime) / 3600000

    if (timeDifferenceInHours > 24) {
      return '#AC0000' // Red color
    } else if (timeDifferenceInHours > 20) {
      return '#F5BD4F' // Yellow color
    } else {
      return '#4DED6D' // Green color
    }
  }

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const getActionList = (carStatus, isArchived, isChecksDone) => {
    if (isArchived) {
      return []
    }
    const availableActions = actions.filter((item) => {
      if (item.specialConditions === 'appraisalChecks' && !isChecksDone) {
        return false
      }
      return item.conditions.filter(
        (condition) => role === condition.role && carStatus === condition.status,
      )[0]
    })
    return availableActions
  }

  const handleSetFilters = (data, mode) => {
    if (mode === 'cars') {
      let filteredData
      if (role === 'OPERATOR') {
        filteredData = data.filter(
          (item) =>
            (item.name === 'car_workflow.quality_check' ||
              item.name === 'car_workflow.requested_appraisal_compound' ||
              item.name === 'car_workflow.awaiting_appraisal_compound') &&
            item.count !== 0,
        )
      }
      setStatuses(
        filteredData.map((item, index) => {
          return {
            id: index + 1,
            systemName: item.name,
            label: getCarStatus(item.name),
            count: item.count,
          }
        }),
      )
    }
  }

  const getCars = () => {
    let link
    if (role === 'OPERATOR') {
      if (!filterStatus) {
        link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=car_workflow.requested_appraisal_compound&combinedStatus[]=car_workflow.awaiting_appraisal_compound&combinedStatus[]=car_workflow.quality_check`
      } else {
        link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
      }
      if (searchLine) {
        link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
      }
      if (sort) {
        link = `${link}${sort}`
      }
    }

    privateRequest.get(link).then((response) => {
      setCars(
        response.data['hydra:member'].map((item) => {
          let link = `/cars/${item.id}`

          const cells = [
            {
              type: 'text',
              value: item.referenceNumber ? item.referenceNumber : item.id,
            },
            {
              type: 'image',
              value: handleSetCarPhoto(item.mainForeshortening),
            },
            {
              type: 'text-with-caption',
              value: `${item.make.name} ${item.model.name}`,
              caption: `VIN ${item.vin}`,
            },
            {
              type: 'text',
              value: item.company.name,
            },
            {
              type: 'text',
              value: getCarStatus(item.combinedStatus, item.isArchived),
            },
            {
              type: 'text-with-marker',
              value: getFormattedDate(new Date(item.createdAt)),
              //value: formattedDate.split('/').map(item => item.length < 2 ? `0${item}` : item).join('.'),
              color: getMarkerColor(item.createdAt),
            },
            {
              type: 'actions',
              value: getActionList(
                item.combinedStatus,
                item.isArchived,
                item.isAppraisalChecksDone,
              ),
            },
          ]

          return {
            id: item.id,
            link: link,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: cells,
          }
        }),
      )
    })
  }

  const getAppraisedCars = () => {
    let link
    if (role === 'OPERATOR') {
      if (!filterStatus) {
        link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=car_workflow.ready_for_sale`
      } else {
        link = `${API_ENDPOINTS.cars.companyCars}&combinedStatus[]=${filterStatus}`
      }
      if (searchLine) {
        link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[combinedStatus]=${searchLine}&or[id]=${searchLine}`
      }
      if (sort) {
        link = `${link}${sort}`
      }
    }

    privateRequest.get(link).then((response) => {
      setAppraisedCars(
        response.data['hydra:member'].map((item) => {
          let link = `/cars/${item.id}`

          const cells = [
            {
              type: 'text',
              value: item.referenceNumber ? item.referenceNumber : item.id,
            },
            {
              type: 'image',
              value: handleSetCarPhoto(item.mainForeshortening),
            },
            {
              type: 'text-with-caption',
              value: `${item.make.name} ${item.model.name}`,
              caption: `VIN ${item.vin}`,
            },
            {
              type: 'text',
              value: item.company.name,
            },
            {
              type: 'text',
              value: getCarStatus(item.combinedStatus, item.isArchived),
            },
            {
              type: 'text-with-marker',
              value: getFormattedDate(new Date(item.createdAt)),
              //value: formattedDate.split('/').map(item => item.length < 2 ? `0${item}` : item).join('.'),
              color: getMarkerColor(item.createdAt),
            },
            {
              type: 'actions',
              value: getActionList(
                item.combinedStatus,
                item.isArchived,
                item.isAppraisalChecksDone,
              ),
            },
          ]

          return {
            id: item.id,
            link: link,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: cells,
          }
        }),
      )
    })
  }

  const getCarStatusCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.statusCount).then((response) => {
      const data = response.data['hydra:member']
      handleSetFilters(data, 'cars')
    })
  }

  const getArchivedCarsCount = () => {
    privateRequest.get(API_ENDPOINTS.cars.archivedCars).then((response) => {
      setArchivedCarsCount(response.data['hydra:totalItems'])
    })
  }

  useEffect(() => {
    if (workflowsLoaded) {
      getCars()
      getAppraisedCars()
      getCarStatusCount()
      getArchivedCarsCount()
    }
  }, [workflowsLoaded, filterStatus, searchLine, sort, tab])

  useEffect(() => {
    dispatch(setCurrentPageTitle('Appraisals'))
  }, [])

  useEffect(() => {
    if (xsOnly) {
      setDisplayMode('list')
    }
  }, [xsOnly])

  const hasRequestsOver24Hours = (tab) => {
    const requests = cars
    const currentTime = new Date()
    const twentyFourHoursAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000)

    return requests.some((request) => {
      const requestTime = new Date(request.cells[request.cells.length - 2].value)
      return requestTime < twentyFourHoursAgo
    })
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'block',
      }}
    >
      {xsOnly ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            width: 'calc(100% + 4px)',
            margin: '20px 0',
          }}
        >
          {sections.map((section, index) => {
            return (
              <Button
                key={index}
                className={index === tab ? 'active' : ''}
                sx={{
                  width: '100%',
                  background: colorsConfig.bgLightBlue,
                  color: colorsConfig.mainBlack,
                  position: 'relative',
                  height: '48px',
                  textTransform: 'none',
                  fontFamily: '"Helvetica", sans-serif',
                  fontSize: '16px',
                  fontWeight: 500,
                  mb: '4px',
                  mr: '4px',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  '&::after': {
                    content: '" "',
                    display: 'block',
                    width: '100%',
                    height: '3px',
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    background: colorsConfig.mainGreen,
                    transformOrigin: '50% 50%',
                    transform: 'scale(0)',
                    transition: '0.25s all ease-in-out',
                  },
                  '&.active': {
                    background: colorsConfig.bgLightBlue,
                    color: colorsConfig.mainBlack,
                    '&::after': {
                      transform: 'scale(1)',
                      transition: '0.25s all ease-in-out',
                    },
                  },
                  '&.Mui-disabled > div > p': {
                    color: 'rgba(0, 0, 0, 0.38) !important',
                  },
                }}
                onClick={() => {
                  setTab(index)
                }}
              >
                {section.name}
                {hasRequestsOver24Hours(index) && section.component && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    style={{ margin: '0 0 6px 2px' }}
                  >
                    <circle cx="5" cy="5" r="5" fill="#AC0000" />
                  </svg>
                )}
              </Button>
            )
          })}
        </Box>
      ) : (
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal)
          }}
          variant="scrollable"
          sx={{
            display: xsOnly ? 'flex' : 'block',
            position: 'relative',
            width: '100%',
            marginBottom: '40px',
            '& .MuiTabs-indicator': {
              background: colorsConfig.mainGreen,
            },
            '& .MuiTab-textColorPrimary': {
              color: colorsConfig.mainBlack,
              textTransform: 'none',
              fontFamily: '"Helvetica", sans-serif',
              fontSize: '16px',
              fontWeight: 500,
              paddingLeft: '15px',
            },
            '& .MuiButtonBase-root': {
              background: colorsConfig.bgLightBlue,
              width: xsOnly ? '50%' : 'initial',
            },
            '& .Mui-selected': {
              color: colorsConfig.mainBlack,
            },
            ' .MuiButtonBase-root': {
              height: '41px',
            },
          }}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={index}
                label={
                  <Box>
                    {section.name}
                    {hasRequestsOver24Hours(index) && section.component && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        style={{ margin: '0 0 6px 2px' }}
                      >
                        <circle cx="5" cy="5" r="5" fill="#AC0000" />
                      </svg>
                    )}
                  </Box>
                }
                sx={{
                  fontSize: '13px',
                }}
              />
            )
          })}
        </Tabs>
      )}
      <Box
        sx={{
          position: xsOnly ? 'relative' : 'absolute',
          top: xsOnly ? '' : 0,
          right: xsOnly ? '' : 0,
          height: !xsOnly && '48px',
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: !xsOnly && '40px',
          paddingLeft: xsOnly && '16px',
          order: xsOnly ? 1 : 0,
        }}
      >
        <Box
          sx={{
            marginTop: xsOnly ? '25px' : '',
            display: 'flex',
            gap: xsOnly ? '12px' : '24px',
            flexDirection: xsOnly ? 'column' : 'row',
            '& .MuiBox-root': {
              display: 'flex',
              gap: xsOnly ? '2px' : '6px',
              alignItems: 'center',
              '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
                // fontSize: xsOnly ? '14px' : '16px'
                fontSize: '16px',
              },
            },
          }}
        >
          <Box>
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#4DED6D',
                mr: '6px',
              }}
            />
            <Typography>—New request</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#F5BD4F',
                mr: '6px',
              }}
            />
            <Typography>—Approaching the deadline</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                width: '18px',
                minWidth: '18px',
                height: '18px',
                borderRadius: '50%',
                background: '#AC0000',
                mr: '6px',
              }}
            />
            <Typography>—Action required</Typography>
          </Box>
        </Box>
      </Box>
      <BoxShadowed
        className={xsOnly ? 'xs-only' : ''}
        sx={{
          px: '40px',
          py: '37px',
          mt: xsOnly && role !== 'COMPANY' ? '50px' : 0,
          '&.xs-only': {
            boxShadow: 'none',
            p: 0,
            borderRadius: 0,
          },
          '> * > :first-of-type > :last-of-type': {
            display: tab === 1 && 'none',
          },
        }}
      >
        {tab === 0 ? (
          <CarTable
            type={'cars'}
            tableCells={operatorAppraisalsTableHeadCells}
            tableTitle={'Waiting for appraisal'}
            content={cars}
            filters={statuses}
            setFilterStatus={setFilterStatus}
            searchLine={searchLine}
            setSearchLine={setSearchLine}
            sort={sort}
            setSort={setSort}
            cars={cars}
            setCars={setCars}
            getActionList={getActionList}
            archivedCount={0}
          />
        ) : null}

        {tab === 1 ? (
          <CarTable
            type={'cars'}
            tableCells={operatorAppraisalsTableHeadCells}
            tableTitle={'Appraised cars'}
            content={appraisedCars}
            filters={statuses}
            setFilterStatus={setFilterStatus}
            searchLine={searchLine}
            setSearchLine={setSearchLine}
            sort={sort}
            setSort={setSort}
            cars={appraisedCars}
            setCars={setAppraisedCars}
            getActionList={getActionList}
            archivedCount={0}
          />
        ) : null}
      </BoxShadowed>
    </Box>
  )
}

export default OperatorAppraisals
