import { privateRequest, publicRequest } from '../../utils/request'

class LoanDealService {
  #api

  #apiPublic

  #endPoints

  constructor({ api, apiPublic }) {
    this.#api = api
    this.#apiPublic = apiPublic
    this.#endPoints = {
      customerApprove: (secureHash, loanDealId) =>
        `/finance/customer/loan-applications/${secureHash}/loan-deals/${loanDealId}/approve`,
    }
  }

  customerApprove = async (secureHash, loanDealId) => {
    const response = await this.#apiPublic.put(
      this.#endPoints.customerApprove(secureHash, loanDealId),
      {},
    )
    return response
  }
}

const loanDealService = new LoanDealService({ api: privateRequest, apiPublic: publicRequest })

export default loanDealService
