export const TABLE_CELL_TYPE = {
  TEXT: 'text',
  COMPONENT: 'component',
}

export const policiesOperatorTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },
  {
    title: 'Start date',
  },
  {
    title: 'End date',
  },
  {
    title: 'Status',
  },
  {
    title: 'Actions',
  },
]

export const awaitingRestitutionPoliciesHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer',
  },

  // {
  //   title: 'Location',
  // },
  {
    title: 'Mileage',
  },
  {
    title: 'Vehicle expertise',
  },
  {
    title: 'Status',
  },
  {
    title: 'Restitution date',
  },
  {
    title: 'Actions',
  },
]

export const policiesTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'table_col_brand_model',
  },
  {
    title: 'table_col_start_date',
  },
  {
    title: 'table_col_end_date',
  },
  {
    title: 'table_col_status',
  },
  {
    title: '',
  },
]

export const policyImportsTableHead = [
  {
    title: 'File name',
  },
  {
    title: 'Uploaded at',
  },
  {
    title: 'Status',
  },
  {
    title: 'Error message',
  },
  {
    title: 'Amount of policies',
  },
  {
    title: '',
  },
]

export const importedPoliciesTableHead = [
  {
    title: 'Policy number',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Dealer name',
  },
  {
    title: 'Select dealer',
  },
]

export const companiesTableHead = [
  {
    title: 'ID',
  },
  {
    title: 'Name',
  },
  {
    title: 'E-mail',
  },
  {
    title: 'Landline number',
  },
  {
    title: 'Created at',
  },
  {
    title: 'Actions',
  },
]

export const customerAppraisalsTableHead = [
  {
    title: 'Image',
  },
  {
    title: 'Brand/Model',
  },
  {
    title: 'Customer',
  },
  {
    title: 'Status',
  },
  {
    key: 'createdAt',
    title: 'Created at',
    sortable: true,
  },
  {
    title: 'Action',
  },
]
