// const fillColor = "rgba(255, 255, 255, .3)"
// const strokeColor = "#1976d2"

const strokeColor = '#23b223'
const fillColor = 'rgba(63,211,76,0.3)'

export const carPartsFrontRight = [
  {
    id: '1',
    title: 'Hood',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      363, 161, 365, 168, 366, 175, 373, 182, 382, 186, 392, 188, 407, 192, 425, 197, 439, 203, 465,
      215, 487, 230, 491, 234, 492, 235, 639, 245, 647, 246, 659, 246, 835, 247, 977, 247, 985, 247,
      1035, 238, 1037, 236, 1033, 230, 1025, 222, 1008, 211, 987, 203, 980, 201, 966, 189, 961, 185,
      954, 182, 945, 178, 937, 174, 924, 170, 910, 167, 894, 164, 870, 161, 861, 160, 860, 159, 831,
      161, 765, 159, 646, 156, 568, 156, 505, 155, 472, 155, 406, 153, 370, 151, 361, 151, 362, 152,
      361, 154,
    ],
  },
  {
    id: '2',
    title: 'Windscreen',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      315, 38, 323, 56, 331, 78, 339, 99, 346, 119, 353, 139, 356, 150, 383, 151, 423, 153, 482,
      154, 545, 155, 650, 156, 691, 156, 755, 158, 812, 160, 844, 160, 819, 138, 794, 116, 763, 90,
      728, 63, 707, 47, 659, 40, 602, 35, 523, 31, 476, 30, 426, 29, 384, 30, 352, 30, 324, 33, 312,
      34, 313, 34,
    ],
  },
  {
    id: '3',
    title: 'Left Headlight',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      987, 257, 1005, 280, 1015, 295, 1017, 298, 1041, 288, 1051, 280, 1039, 245, 1039, 238, 1016,
      243, 991, 246, 984, 248, 984, 253, 986, 255,
    ],
  },
  {
    id: '4',
    title: 'Right Headlight',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      459, 279, 502, 287, 555, 293, 611, 299, 615, 300, 658, 257, 656, 248, 651, 247, 647, 246, 569,
      240, 519, 237, 504, 235, 471, 235, 446, 235, 440, 236, 439, 241, 439, 245, 440, 250, 445, 259,
      451, 269, 455, 275, 457, 278,
    ],
  },
  {
    id: '5',
    title: 'Vent',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      659, 254, 968, 254, 985, 255, 1015, 295, 989, 325, 947, 326, 889, 327, 841, 327, 769, 328,
      717, 329, 677, 329, 647, 317, 615, 300, 639, 276, 658, 256, 659, 256,
    ],
  },
  {
    id: '6',
    title: 'Front Right Fog Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      489, 346, 489, 371, 491, 390, 494, 396, 499, 397, 506, 398, 532, 400, 574, 400, 594, 394, 610,
      383, 610, 360, 607, 355, 573, 340, 525, 337, 498, 336, 491, 338, 490, 340, 489, 343,
    ],
  },
  {
    id: '7',
    title: 'Front Left Fog Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      1036, 352, 1036, 374, 1043, 384, 1049, 389, 1061, 388, 1069, 384, 1071, 382, 1072, 376, 1072,
      347, 1073, 332, 1069, 329, 1052, 331, 1046, 336, 1039, 345, 1037, 349,
    ],
  },
  {
    id: '8',
    title: 'Front Bumper',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      418, 312, 424, 302, 457, 278, 479, 284, 522, 290, 582, 297, 614, 300, 676, 329, 773, 328, 845,
      328, 939, 326, 989, 325, 1014, 299, 1044, 286, 1051, 280, 1057, 292, 1060, 304, 1063, 311,
      1073, 324, 1076, 328, 1076, 363, 1075, 403, 1077, 406, 1077, 419, 1076, 422, 1075, 428, 1074,
      434, 1073, 445, 1074, 447, 1075, 450, 1066, 462, 1048, 482, 1031, 484, 1000, 487, 976, 490,
      922, 494, 824, 497, 727, 502, 565, 503, 547, 507, 452, 506, 452, 457, 447, 425, 443, 388, 437,
      363, 432, 346, 427, 330, 422, 320,
    ],
  },
  {
    id: '9',
    title: 'Front Right Fender',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      503, 235, 493, 225, 479, 215, 448, 201, 429, 193, 398, 185, 365, 179, 363, 155, 363, 152, 363,
      151, 356, 150, 348, 150, 326, 158, 322, 173, 314, 179, 302, 223, 303, 226, 299, 265, 297, 302,
      294, 361, 294, 391, 294, 407, 302, 407, 302, 373, 302, 361, 304, 351, 305, 346, 306, 338, 307,
      331, 310, 323, 312, 314, 315, 305, 318, 297, 323, 288, 328, 281, 333, 274, 343, 266, 356, 260,
      368, 261, 375, 263, 380, 265, 385, 269, 391, 272, 396, 279, 405, 290, 414, 303, 418, 311, 423,
      303, 435, 294, 448, 284, 457, 278, 445, 261, 440, 251, 439, 248, 439, 241, 439, 236, 445, 234,
      467, 234, 495, 235, 502, 235,
    ],
  },
  {
    id: '10',
    title: 'Front Right Door Handle',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      170, 217, 175, 217, 180, 218, 185, 220, 191, 225, 193, 226, 192, 234, 189, 237, 182, 240, 175,
      240, 169, 239, 164, 238, 159, 234, 159, 231, 159, 226, 164, 221, 166, 219, 168, 218,
    ],
  },
  {
    id: '11',
    title: 'Front Right Door',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      313, 181, 306, 207, 302, 224, 303, 225, 302, 234, 300, 258, 298, 283, 296, 316, 295, 341, 294,
      379, 294, 407, 285, 407, 277, 415, 209, 404, 170, 397, 153, 395, 152, 378, 155, 365, 157, 355,
      158, 352, 157, 320, 156, 279, 158, 231, 158, 219, 159, 216, 159, 206, 168, 186, 174, 171, 175,
      168, 184, 161, 210, 162, 235, 166, 258, 167, 284, 169, 292, 169, 293, 170, 289, 177, 312, 178,
      315, 178, 313, 180, 313, 181,
    ],
  },
  {
    id: '12',
    title: 'Front Right Door',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      313, 181, 306, 207, 302, 224, 303, 225, 302, 234, 300, 258, 298, 283, 296, 316, 295, 341, 294,
      379, 294, 407, 285, 407, 277, 415, 209, 404, 170, 397, 153, 395, 152, 378, 155, 365, 157, 355,
      158, 352, 157, 320, 156, 279, 158, 231, 158, 219, 159, 216, 159, 206, 168, 186, 174, 171, 175,
      168, 184, 161, 210, 162, 235, 166, 258, 167, 284, 169, 292, 169, 293, 170, 289, 177, 312, 178,
      315, 178, 313, 180, 313, 181,
    ],
  },
  {
    id: '13',
    title: 'Rear Right Door Handle',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      63, 231, 70, 231, 81, 227, 84, 222, 84, 216, 79, 211, 71, 210, 63, 210, 56, 215, 53, 220, 54,
      224, 56, 227, 59, 229, 61, 230,
    ],
  },
  {
    id: '14',
    title: 'Rear Right Door',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      44, 255, 44, 246, 53, 214, 62, 194, 76, 177, 91, 160, 184, 162, 178, 169, 172, 176, 169, 183,
      165, 190, 161, 196, 159, 204, 157, 227, 156, 250, 156, 279, 158, 357, 156, 365, 152, 383, 152,
      394, 84, 382, 84, 367, 77, 321, 70, 300, 63, 283, 55, 270, 45, 262,
    ],
  },
  {
    id: '15',
    title: 'Right Side Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      307, 115, 319, 157, 326, 157, 323, 172, 307, 172, 288, 169, 247, 166, 219, 163, 210, 161, 203,
      154, 199, 144, 198, 126, 212, 121, 229, 114, 247, 111, 261, 109, 275, 112, 286, 119, 296, 127,
      300, 132, 299, 138, 293, 151, 292, 156, 297, 156, 297, 152, 300, 140, 304, 126,
    ],
  },
  {
    id: '16',
    title: 'Front Right Window Pane',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      307, 114, 297, 83, 288, 62, 278, 46, 265, 41, 261, 38, 238, 39, 228, 39, 221, 40, 215, 59,
      204, 88, 200, 103, 193, 124, 188, 146, 187, 157, 206, 158, 199, 146, 198, 134, 198, 127, 206,
      123, 216, 118, 234, 113, 255, 109, 266, 109, 287, 118, 299, 129, 300, 133, 295, 146, 292, 154,
      296, 155, 300, 139, 306, 117, 307, 115,
    ],
  },
  {
    id: '17',
    title: 'Rear Right Window Pane',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      120, 113, 130, 97, 148, 68, 152, 61, 178, 50, 195, 46, 207, 44, 215, 42, 221, 41, 216, 57,
      206, 83, 202, 99, 198, 112, 192, 130, 189, 147, 186, 158, 149, 156, 121, 156, 109, 156, 95,
      156, 99, 146, 105, 137, 110, 129,
    ],
  },
  {
    id: '18',
    title: 'Right Side Skirt',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      318, 480, 270, 474, 189, 462, 131, 453, 101, 449, 94, 435, 160, 445, 227, 454, 293, 464, 317,
      467, 317, 475,
    ],
  },
  {
    id: '19',
    title: 'Right Roof Course',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      327, 157, 350, 149, 356, 148, 347, 121, 334, 87, 323, 55, 313, 35, 310, 30, 307, 27, 298, 22,
      285, 18, 267, 15, 256, 15, 235, 17, 223, 20, 196, 28, 183, 33, 165, 40, 146, 49, 132, 59, 128,
      62, 123, 74, 122, 76, 120, 78, 97, 116, 72, 150, 66, 159, 91, 159, 116, 119, 136, 86, 152, 61,
      152, 59, 171, 53, 184, 49, 201, 44, 217, 41, 241, 38, 259, 38, 263, 39, 267, 40, 272, 42, 275,
      44, 277, 47, 280, 50, 283, 54, 288, 61, 297, 83, 306, 109, 312, 129, 318, 151, 319, 157, 326,
      157, 325, 157,
    ],
  },
  {
    id: '20',
    title: 'Front Right Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    lineWidth: '4',
    coords: [
      342, 551, 350, 562, 360, 570, 367, 574, 375, 572, 386, 566, 394, 553, 406, 520, 411, 479, 410,
      444, 404, 404, 394, 373, 377, 351, 367, 347, 354, 348, 341, 364, 332, 387, 326, 412, 322, 446,
      322, 470, 323, 486, 319, 486, 317, 447, 320, 410, 329, 365, 344, 332, 358, 317, 376, 311, 395,
      305, 410, 336, 417, 351, 423, 377, 431, 408, 438, 438, 443, 463, 448, 487, 461, 505, 500, 509,
      493, 539, 488, 559, 475, 581, 462, 597, 452, 602, 375, 605, 367, 603, 355, 594, 342, 576, 335,
      560, 327, 537, 322, 511, 319, 485, 323, 485, 325, 497, 331, 521, 338, 543, 342, 551,
    ],
  },
  {
    id: '21',
    title: 'Rear Right Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      136, 453, 135, 464, 132, 480, 128, 493, 123, 505, 118, 514, 109, 523, 105, 524, 92, 525, 78,
      525, 56, 526, 47, 527, 40, 524, 33, 517, 27, 505, 20, 485, 16, 467, 14, 457, 13, 443, 12, 429,
      12, 424, 13, 424, 14, 435, 16, 450, 19, 465, 23, 479, 28, 490, 33, 498, 38, 502, 45, 502, 50,
      497, 55, 489, 59, 479, 63, 465, 65, 454, 66, 442, 67, 428, 67, 414, 66, 392, 64, 376, 60, 358,
      56, 344, 52, 336, 45, 328, 39, 326, 33, 328, 28, 334, 24, 343, 20, 355, 17, 367, 15, 384, 14,
      400, 14, 413, 14, 425, 12, 424, 11, 409, 11, 392, 13, 373, 15, 361, 18, 344, 22, 328, 29, 314,
      37, 305, 42, 302, 56, 301, 63, 317, 67, 332, 72, 350, 77, 373, 78, 381, 83, 405, 84, 408, 84,
      412, 88, 421, 95, 437, 101, 449, 120, 451, 129, 452,
    ],
  },
  {
    id: '22',
    title: 'Front Left Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      136, 453, 135, 464, 132, 480, 128, 493, 123, 505, 118, 514, 109, 523, 105, 524, 92, 525, 78,
      525, 56, 526, 47, 527, 40, 524, 33, 517, 27, 505, 20, 485, 16, 467, 14, 457, 13, 443, 12, 429,
      12, 424, 13, 424, 14, 435, 16, 450, 19, 465, 23, 479, 28, 490, 33, 498, 38, 502, 45, 502, 50,
      497, 55, 489, 59, 479, 63, 465, 65, 454, 66, 442, 67, 428, 67, 414, 66, 392, 64, 376, 60, 358,
      56, 344, 52, 336, 45, 328, 39, 326, 33, 328, 28, 334, 24, 343, 20, 355, 17, 367, 15, 384, 14,
      400, 14, 413, 14, 425, 12, 424, 11, 409, 11, 392, 13, 373, 15, 361, 18, 344, 22, 328, 29, 314,
      37, 305, 42, 302, 56, 301, 63, 317, 67, 332, 72, 350, 77, 373, 78, 381, 83, 405, 84, 408, 84,
      412, 88, 421, 95, 437, 101, 449, 120, 451, 129, 452,
    ],
  },
  {
    id: '23',
    title: 'Front Right Rim',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      368, 573, 372, 573, 376, 572, 382, 569, 387, 563, 393, 553, 400, 539, 405, 525, 408, 510, 409,
      492, 411, 477, 411, 466, 410, 444, 407, 420, 402, 396, 397, 381, 391, 369, 384, 359, 374, 349,
      362, 347, 350, 353, 342, 365, 335, 379, 331, 393, 326, 411, 324, 428, 323, 443, 323, 457, 323,
      466, 324, 477, 325, 492, 328, 508, 334, 530, 341, 549, 348, 560, 357, 569, 362, 572, 366, 573,
      368, 573,
    ],
  },
  {
    id: '24',
    title: 'Rear Right Rim',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      39, 326, 44, 328, 49, 334, 53, 339, 57, 349, 61, 361, 63, 371, 65, 385, 66, 398, 67, 410, 67,
      424, 67, 435, 65, 449, 63, 465, 57, 485, 50, 497, 44, 502, 37, 502, 29, 493, 24, 481, 20, 468,
      17, 456, 16, 446, 14, 435, 14, 422, 14, 410, 14, 398, 15, 382, 17, 367, 20, 355, 24, 343, 28,
      334, 33, 329, 36, 327,
    ],
  },
  {
    id: '25',
    title: 'Front Left Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      1050, 482, 1048, 494, 1046, 503, 1043, 513, 1039, 524, 1033, 535, 1028, 544, 1021, 552, 1013,
      560, 1006, 565, 999, 569, 994, 570, 987, 571, 965, 572, 945, 573, 931, 574, 925, 574, 912,
      572, 901, 565, 892, 556, 880, 543, 875, 532, 869, 521, 865, 512, 863, 504, 861, 498, 861, 496,
      875, 496, 887, 496, 902, 495, 919, 494, 931, 493, 942, 492, 953, 492, 961, 491, 967, 491, 976,
      491, 990, 490, 1012, 487, 1024, 485, 1031, 484, 1032, 482, 1047, 481, 1051, 481, 1050, 481,
    ],
  },
  {
    id: '26',
    title: 'Left Side Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      813, 127, 823, 136, 836, 148, 848, 159, 859, 159, 864, 159, 865, 149, 865, 143, 852, 135, 839,
      128, 830, 125, 825, 123, 822, 122, 817, 124, 814, 125,
    ],
  },
  {
    id: '27',
    title: 'Rear Right Fender',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      45, 263, 44, 246, 48, 226, 58, 194, 73, 176, 90, 161, 65, 160, 46, 183, 36, 190, 30, 207, 26,
      220, 20, 237, 18, 240, 17, 251, 14, 265, 12, 276, 9, 287, 7, 298, 6, 309, 5, 316, 5, 331, 4,
      348, 5, 359, 6, 335, 9, 311, 13, 291, 19, 277, 26, 267, 34, 262, 41, 261, 42, 261,
    ],
  },
  {
    id: '28',
    title: 'Front Roof',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      316, 34, 346, 31, 374, 30, 401, 30, 434, 30, 469, 30, 496, 31, 530, 32, 561, 33, 594, 35, 622,
      37, 646, 39, 669, 42, 690, 45, 703, 48, 707, 48, 686, 37, 659, 31, 638, 27, 618, 24, 561, 17,
      519, 13, 489, 11, 462, 10, 395, 8, 365, 9, 332, 10, 305, 11, 281, 13, 257, 15, 243, 16, 230,
      19, 255, 17, 272, 17, 285, 18, 295, 20, 306, 26, 313, 34, 314, 34,
    ],
  },
  //Gas Cap
]

export const carPartsBackLeft = [
  {
    id: '1',
    title: 'Rear Window',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      548, 92, 603, 190, 701, 190, 801, 186, 911, 181, 991, 177, 1005, 175, 989, 161, 966, 140, 913,
      90, 911, 67, 909, 64, 902, 59, 893, 56, 871, 53, 829, 50, 799, 50, 751, 50, 713, 51, 681, 53,
      651, 55, 620, 58, 600, 60, 589, 62, 567, 68, 537, 79, 538, 80,
    ],
  },
  {
    id: '2',
    title: 'Right Tail Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      1008, 227, 1017, 240, 1027, 251, 1047, 250, 1053, 251, 1063, 262, 1068, 262, 1067, 243, 1067,
      226, 1067, 218, 1070, 211, 1060, 211, 1047, 203, 1030, 206, 1018, 209, 1014, 211, 1013, 217,
      1007, 224, 1007, 225,
    ],
  },
  {
    id: '3',
    title: 'Left Tail Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      549, 236, 561, 265, 573, 291, 597, 291, 620, 293, 622, 292, 677, 291, 701, 275, 711, 272, 740,
      270, 760, 268, 775, 249, 783, 236, 781, 230, 767, 225, 760, 224, 751, 223, 709, 223, 665, 223,
      632, 223, 605, 222, 578, 222, 570, 222, 557, 224, 547, 228, 547, 232,
    ],
  },
  {
    id: '4',
    title: 'Trunk Lid',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      602, 191, 606, 193, 615, 203, 631, 222, 659, 223, 672, 232, 677, 291, 678, 309, 678, 322, 713,
      355, 721, 364, 727, 365, 734, 368, 799, 365, 896, 353, 1002, 342, 1040, 335, 1049, 323, 1057,
      290, 1063, 278, 1064, 263, 1062, 236, 1060, 221, 1061, 212, 1059, 210, 1007, 175, 971, 179,
      913, 180, 850, 183, 775, 187, 722, 188, 676, 190, 624, 190, 606, 190,
    ],
  },

  {
    id: '5',
    title: 'Rear Right Fog Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      1054, 361, 1068, 396, 1071, 397, 1074, 394, 1074, 388, 1074, 386, 1080, 364, 1083, 354, 1072,
      358, 1059, 359, 1054, 361,
    ],
  },
  {
    id: '6',
    title: 'Rear Left Fog Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      615, 440, 626, 414, 637, 399, 647, 396, 745, 393, 732, 408, 710, 427, 697, 439, 693, 441, 684,
      442, 663, 443, 640, 443, 621, 443, 615, 443, 614, 442,
    ],
  },
  {
    id: '7',
    title: 'Rear Bumper & Rear Skirt',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      572, 290, 519, 344, 511, 357, 520, 369, 532, 394, 539, 414, 551, 453, 551, 465, 555, 471, 565,
      495, 565, 501, 591, 544, 656, 542, 702, 537, 718, 536, 731, 530, 741, 526, 761, 523, 770, 525,
      810, 518, 813, 516, 833, 513, 840, 514, 850, 514, 882, 508, 888, 505, 914, 503, 947, 500, 974,
      494, 1007, 491, 1033, 489, 1044, 486, 1078, 446, 1078, 441, 1081, 430, 1083, 411, 1083, 398,
      1079, 394, 1084, 373, 1088, 356, 1090, 338, 1089, 331, 1080, 312, 1071, 294, 1068, 285, 1067,
      284, 1070, 268, 1069, 262, 1063, 263, 1064, 277, 1058, 293, 1055, 304, 1045, 331, 1039, 337,
      987, 345, 929, 350, 868, 355, 793, 363, 733, 366, 727, 366, 704, 348, 688, 332, 678, 323, 677,
      300, 676, 291, 635, 292, 607, 292, 588, 291, 573, 291, 572, 289,
    ],
  },
  {
    id: '8',
    title: 'Rear Left Fender + C-pillar',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      512, 357, 520, 343, 573, 291, 546, 230, 559, 223, 582, 221, 632, 223, 606, 194, 604, 192, 602,
      191, 576, 189, 532, 98, 529, 93, 524, 89, 516, 82, 494, 74, 474, 69, 462, 66, 474, 156, 478,
      182, 476, 193, 473, 203, 459, 222, 451, 241, 443, 272, 440, 289, 439, 309, 454, 311, 468, 316,
      480, 325, 490, 332, 500, 343, 507, 350,
    ],
  },
  {
    id: '9',
    title: 'Rear Left Door Handle',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      393, 261, 401, 264, 412, 265, 425, 263, 433, 260, 437, 251, 437, 247, 429, 242, 416, 241, 405,
      241, 398, 243, 390, 247, 387, 249, 387, 255, 389, 258, 392, 259,
    ],
  },
  {
    id: '10',
    title: 'Front Left Door Handle',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      219, 248, 227, 252, 238, 253, 248, 249, 250, 240, 246, 236, 241, 232, 228, 231, 223, 232, 215,
      235, 212, 237, 212, 244, 217, 247, 218, 247,
    ],
  },
  {
    id: '11',
    title: 'Rear Left Door',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      476, 182, 472, 190, 475, 200, 458, 225, 441, 290, 438, 309, 425, 308, 412, 313, 404, 315, 394,
      324, 380, 340, 372, 356, 366, 374, 361, 399, 360, 416, 358, 420, 361, 453, 359, 456, 370, 482,
      258, 450, 243, 432, 240, 428, 241, 424, 240, 395, 241, 386, 253, 362, 251, 275, 252, 233, 257,
      228, 257, 223, 277, 185, 286, 177, 374, 179, 446, 181, 466, 182,
    ],
  },
  {
    id: '12',
    title: 'Front Left Door',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      117, 405, 261, 450, 243, 433, 240, 428, 241, 423, 241, 394, 240, 387, 253, 361, 253, 325, 252,
      281, 254, 247, 255, 238, 258, 230, 258, 223, 276, 185, 286, 178, 159, 179, 151, 179, 144, 179,
      133, 180, 120, 184, 114, 196, 109, 218, 105, 274, 104, 291, 103, 312, 105, 362, 105, 365, 107,
      384, 107, 387, 111, 397, 114, 401,
    ],
  },
  {
    id: '13',
    title: 'Rear Left Window Pane',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      287, 177, 290, 173, 305, 129, 317, 101, 328, 79, 339, 56, 363, 56, 410, 58, 431, 60, 446, 63,
      461, 65, 470, 129, 477, 182, 411, 179, 338, 178, 295, 177,
    ],
  },
  {
    id: '14',
    title: 'Front Left Window Pane',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      286, 176, 164, 179, 167, 174, 175, 134, 213, 98, 236, 78, 256, 68, 278, 61, 299, 58, 322, 57,
      340, 56, 329, 77, 318, 100, 303, 135, 296, 155, 290, 176,
    ],
  },
  {
    id: '15',
    title: 'Left Side Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      178, 132, 167, 172, 183, 177, 160, 179, 150, 181, 134, 180, 126, 178, 113, 175, 108, 164, 106,
      160, 105, 149, 119, 142, 136, 134, 147, 132, 159, 136, 157, 150, 157, 153, 166, 144, 174, 136,
    ],
  },
  {
    id: '16',
    title: 'Left Side Skirt',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      106, 402, 258, 449, 382, 485, 384, 497, 327, 479, 253, 457, 153, 427, 113, 415, 109, 413, 105,
      402,
    ],
  },
  {
    id: '17',
    title: 'Front Left Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      43, 276, 63, 276, 69, 286, 75, 297, 80, 311, 84, 324, 90, 343, 93, 361, 94, 367, 97, 380, 98,
      387, 105, 403, 110, 417, 139, 423, 135, 439, 132, 449, 125, 461, 119, 468, 114, 472, 111, 473,
      88, 475, 63, 478, 55, 479, 46, 474, 35, 464, 27, 450, 21, 432, 17, 420, 15, 410, 13, 396, 12,
      384, 14, 384, 16, 395, 19, 410, 23, 423, 28, 435, 34, 445, 39, 451, 46, 457, 51, 457, 56, 457,
      62, 452, 68, 444, 72, 434, 76, 419, 77, 406, 78, 396, 77, 379, 75, 364, 73, 348, 68, 334, 64,
      324, 60, 317, 58, 312, 52, 305, 49, 303, 43, 301, 42, 300, 38, 300, 30, 303, 26, 310, 20, 322,
      18, 331, 16, 341, 15, 352, 15, 360, 15, 370, 15, 381, 14, 384, 12, 384, 11, 370, 11, 358, 11,
      344, 12, 334, 14, 321, 18, 308, 22, 297, 27, 289, 31, 284, 37, 279, 40, 277,
    ],
  },
  {
    id: '18',
    title: 'Rear Left Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      393, 489, 384, 488, 383, 461, 385, 437, 389, 417, 393, 405, 405, 380, 423, 362, 442, 355, 469,
      354, 480, 355, 489, 366, 498, 378, 508, 394, 518, 413, 528, 442, 535, 462, 541, 478, 544, 493,
      545, 498, 555, 517, 568, 532, 577, 541, 584, 544, 582, 556, 569, 586, 557, 602, 544, 613, 535,
      616, 467, 627, 453, 624, 435, 614, 421, 599, 412, 586, 402, 566, 393, 540, 388, 516, 385, 502,
      384, 493, 384, 488, 392, 489, 395, 508, 400, 529, 404, 543, 411, 559, 419, 573, 429, 584, 442,
      594, 454, 598, 472, 595, 485, 583, 492, 571, 496, 561, 500, 549, 503, 534, 504, 517, 504, 498,
      504, 486, 502, 471, 495, 451, 491, 438, 482, 419, 471, 403, 458, 392, 441, 386, 427, 388, 412,
      400, 402, 418, 397, 431, 394, 448, 392, 469, 392, 478, 392, 489,
    ],
  },
  {
    id: '19',
    title: 'Front Left Rim',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      41, 299, 45, 300, 51, 304, 57, 311, 63, 321, 68, 334, 72, 347, 74, 358, 77, 371, 77, 381, 78,
      395, 77, 404, 76, 420, 72, 434, 67, 445, 63, 451, 59, 455, 55, 457, 47, 457, 41, 453, 34, 446,
      28, 435, 24, 425, 21, 415, 19, 405, 17, 398, 16, 389, 15, 380, 15, 371, 15, 348, 17, 337, 19,
      327, 22, 318, 26, 310, 31, 304, 35, 301,
    ],
  },
  {
    id: '20',
    title: 'Rear Left Rim',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      438, 386, 447, 387, 457, 391, 466, 398, 473, 407, 481, 416, 486, 425, 490, 435, 496, 450, 498,
      460, 500, 467, 502, 475, 503, 481, 504, 489, 505, 500, 505, 509, 505, 522, 503, 534, 501, 544,
      498, 557, 494, 565, 489, 576, 483, 585, 475, 591, 468, 596, 459, 598, 448, 596, 438, 591, 429,
      584, 423, 577, 415, 565, 408, 552, 403, 540, 399, 528, 396, 516, 394, 502, 393, 488, 393, 475,
      393, 461, 394, 450, 395, 441, 399, 426, 405, 413, 410, 405, 416, 397, 425, 391, 432, 387, 435,
      386,
    ],
  },
  {
    id: '21',
    title: 'Left Roof Course',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      158, 153, 179, 131, 199, 111, 215, 96, 229, 84, 245, 73, 261, 66, 280, 61, 294, 59, 313, 57,
      329, 57, 367, 57, 397, 57, 413, 58, 437, 61, 463, 65, 490, 72, 504, 77, 523, 87, 530, 95, 535,
      104, 543, 118, 556, 145, 564, 163, 574, 183, 578, 189, 580, 191, 604, 191, 591, 168, 575, 139,
      563, 119, 549, 93, 536, 79, 565, 69, 589, 61, 588, 56, 567, 52, 450, 37, 396, 35, 361, 35,
      315, 35, 292, 39, 267, 48, 249, 56, 229, 73, 208, 91, 189, 109, 174, 124, 163, 135, 160, 138,
      158, 141, 157, 151, 157, 152,
    ],
  },
  {
    id: '22',
    title: 'Antenna',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      669, 27, 675, 28, 684, 28, 695, 28, 689, 22, 689, 7, 689, 2, 684, 3, 679, 10, 675, 20, 668,
      20, 664, 21, 662, 25, 665, 26,
    ],
  },
  {
    id: '23',
    title: 'Rear Roof',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      538, 79, 569, 68, 589, 61, 629, 57, 666, 54, 711, 51, 764, 50, 808, 50, 847, 52, 883, 55, 902,
      58, 901, 55, 899, 53, 868, 48, 836, 42, 800, 36, 774, 33, 749, 30, 727, 28, 711, 26, 696, 25,
      692, 25, 695, 29, 678, 29, 661, 27, 659, 25, 662, 22, 643, 22, 589, 20, 522, 20, 487, 20, 460,
      22, 438, 22, 418, 25, 384, 27, 361, 30, 331, 34, 294, 40, 355, 36, 406, 35, 437, 38, 488, 46,
      515, 53, 523, 58, 532, 69,
    ],
  },
  {
    id: '24',
    title: 'Front Left Fender',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      11, 267, 7, 263, 19, 234, 25, 216, 29, 207, 39, 198, 55, 192, 72, 187, 130, 184, 111, 220,
      107, 288, 106, 321, 105, 355, 104, 362, 100, 361, 95, 323, 89, 307, 80, 281, 71, 265, 61, 253,
      51, 244, 41, 241, 32, 241, 22, 247, 17, 255, 14, 260,
    ],
  },
  {
    id: '25',
    title: 'Rear Right Tyre',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      888, 548, 900, 552, 909, 553, 931, 552, 954, 548, 976, 544, 990, 537, 1001, 527, 1013, 512,
      1022, 494, 1023, 490, 1003, 490, 979, 492, 955, 496, 930, 501, 896, 505, 860, 510, 850, 513,
      857, 523, 867, 534, 878, 543, 883, 546, 885, 547,
    ],
  },
]

export const carPartsTop = [
  {
    id: '1',
    title: 'Windscreen',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      471, 431, 462, 405, 455, 366, 451, 337, 450, 319, 448, 270, 448, 244, 449, 220, 452, 188, 456,
      163, 459, 142, 464, 121, 469, 111, 471, 105, 458, 103, 442, 101, 377, 89, 320, 79, 299, 79,
      288, 93, 286, 93, 275, 114, 268, 127, 262, 148, 257, 166, 251, 193, 248, 219, 246, 247, 246,
      270, 246, 295, 249, 324, 254, 359, 263, 391, 273, 421, 284, 438, 297, 454, 301, 457, 323, 457,
      391, 444, 455, 434, 470, 431,
    ],
  },
  {
    id: '2',
    title: 'Hood',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      299, 79, 297, 74, 296, 73, 288, 71, 269, 65, 256, 64, 229, 66, 188, 66, 108, 72, 84, 77, 73,
      94, 57, 117, 48, 130, 33, 151, 30, 179, 27, 196, 25, 245, 24, 277, 25, 302, 27, 333, 30, 358,
      34, 379, 35, 388, 48, 405, 61, 426, 80, 451, 86, 460, 112, 464, 158, 468, 207, 471, 261, 472,
      268, 471, 298, 461, 299, 457, 294, 450, 282, 436, 274, 420, 265, 398, 259, 376, 253, 351, 250,
      330, 248, 312, 245, 271, 246, 241, 250, 210, 253, 182, 261, 150, 270, 125, 279, 105, 289, 90,
      296, 82,
    ],
  },
  {
    id: '3',
    title: 'Front Roof',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      471, 431, 463, 407, 456, 375, 452, 343, 449, 313, 448, 278, 447, 268, 449, 228, 451, 188, 458,
      147, 463, 125, 470, 105, 478, 107, 496, 109, 531, 112, 573, 114, 601, 115, 667, 117, 706, 118,
      702, 417, 625, 420, 548, 424, 491, 429,
    ],
  },
  {
    id: '4',
    title: 'Antenna',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      827, 262, 826, 267, 826, 270, 827, 272, 829, 275, 851, 275, 852, 269, 852, 267, 851, 260, 836,
      260, 832, 260, 828, 261, 827, 261,
    ],
  },
  {
    id: '5',
    title: 'Rear Roof',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      928, 414, 918, 412, 886, 412, 706, 417, 703, 117, 929, 123, 936, 135, 943, 153, 948, 173, 952,
      187, 956, 216, 958, 247, 958, 275, 957, 294, 956, 312, 954, 331, 953, 350, 947, 369, 943, 382,
      939, 394, 935, 404,
    ],
  },
  {
    id: '7',
    title: 'Rear Window',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      894, 104, 898, 106, 913, 113, 924, 119, 929, 123, 934, 131, 940, 144, 945, 159, 949, 174, 951,
      182, 954, 202, 956, 219, 957, 243, 958, 259, 958, 277, 957, 301, 956, 319, 953, 341, 949, 363,
      944, 379, 939, 396, 931, 409, 927, 414, 923, 417, 910, 424, 895, 431, 911, 433, 939, 438, 977,
      444, 991, 446, 1000, 438, 1009, 428, 1018, 416, 1023, 405, 1028, 393, 1032, 379, 1036, 356,
      1039, 330, 1040, 310, 1041, 284, 1041, 275, 1044, 271, 1045, 251, 1044, 231, 1042, 211, 1040,
      198, 1037, 184, 1035, 172, 1030, 151, 1026, 138, 1020, 123, 1013, 113, 1006, 105, 1002, 100,
      996, 94, 990, 89, 981, 91, 936, 98,
    ],
  },
  {
    id: '8',
    title: 'Left Tail Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      1073, 370, 1067, 380, 1053, 416, 1045, 430, 1034, 444, 1026, 451, 1012, 458, 999, 463, 985,
      468, 977, 472, 970, 477, 987, 477, 1008, 477, 1028, 469, 1036, 465, 1042, 456, 1050, 444,
      1054, 435, 1059, 423, 1063, 411, 1066, 400, 1069, 390, 1071, 381, 1072, 381, 1072, 377,
    ],
  },
  {
    id: '9',
    title: 'Right Tail Light',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      969, 59, 976, 64, 1019, 81, 1029, 87, 1040, 98, 1048, 110, 1055, 125, 1068, 158, 1071, 163,
      1073, 165, 1069, 149, 1064, 130, 1059, 113, 1054, 98, 1046, 86, 1040, 76, 1036, 72, 1028, 67,
      1017, 62, 1011, 59, 1004, 58, 988, 58, 972, 58, 968, 58, 969, 59,
    ],
  },
  {
    id: '10',
    title: 'Left Side Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      347, 469, 397, 462, 398, 474, 390, 474, 391, 477, 401, 481, 422, 532, 410, 532, 403, 529, 396,
      525, 391, 518, 384, 508, 378, 496, 374, 489, 371, 482, 369, 480, 363, 477, 353, 475, 349, 474,
      340, 474, 337, 472, 343, 469,
    ],
  },
  {
    id: '11',
    title: 'Right Side Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      339, 63, 341, 65, 391, 63, 391, 59, 402, 55, 422, 4, 407, 4, 398, 10, 393, 14, 377, 39, 373,
      49, 371, 53, 369, 55, 362, 59, 354, 61, 348, 62, 339, 62,
    ],
  },
]

export const carPartsFrontLeft = [
  {
    id: '1',
    title: 'Windscreen',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      605.0170648464164, 262.6829268292683, 274.30034129692837, 251.00813008130083,
      365.7337883959044, 184.85094850948505, 427.98634812286696, 146.90785907859077,
      534.9829351535836, 145.9349593495935, 695.4778156996587, 151.77235772357722,
      669.2150170648465, 184.85094850948505, 626.4163822525596, 251.00813008130083,
    ],
    polygon: [
      [520.0646766169153, 393.0348258706467],
      [85.23880597014923, 378.6069651741293],
      [637, 479],
      [13.099502487562177, 478.10945273631836],
      [11.606965174129343, 438.3084577114427],
    ],
  },
  {
    id: '2',
    title: 'Hood',
    shape: 'poly',
    name: '2',
    fillColor: fillColor,
    strokeColor: '#1976d2',
    coords: [
      606.9, 267.5, 594.3, 307.4, 463, 349.27, 311.26, 358.02, 248.03, 419.31, 90.46, 400.83, 91.4,
      342.46, 87.54, 331.75, 103.1, 326.89, 167.3, 287.0, 270.40955631399316, 251.00813008130083,
    ],
  },
  {
    id: '3',
    title: 'Left Headlight',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      326.8259385665529, 361.91869918699183, 294.7269624573379, 406.6720867208672,
      357.9522184300341, 407.6449864498645, 455.22184300341297, 394.99728997289975,
      467.86689419795226, 356.0813008130081,
    ],
  },
  {
    id: '4',
    title: 'Right Headlight',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      87.54266211604096, 343.43360433604335, 81.70648464163823, 390.1327913279133,
      70.03412969283276, 385.26829268292687, 66.14334470989762, 376.5121951219512,
      82.67918088737201, 334.67750677506774,
    ],
  },
  {
    id: '5',
    title: 'Right Headlight',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      310.29010238907847, 476.7208672086721, 415.34129692832767, 475.7479674796748,
      400.75085324232083, 503.96205962059616, 329.7440273037543, 510.7723577235773,
    ],
  },
  {
    id: '6',
    title: 'Fog light front right',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      71.00682593856655, 455.3170731707317, 67.1160409556314, 484.5040650406504, 53.49829351535837,
      471.85636856368563, 53.49829351535837, 447.53387533875343,
    ],
  },
  {
    id: '7',
    title: 'Front Bumper',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      533.037542662116, 394.0243902439024, 513.5836177474403, 393.05149051490514, 461.0580204778157,
      395.9701897018971, 363.7883959044368, 409.590785907859, 290.8361774744027, 409.590785907859,
      323.90784982935156, 361.91869918699183, 307.3720136518771, 361.91869918699183,
      254.84641638225256, 418.3468834688347, 92.40614334470989, 404.7262872628726,
      82.67918088737201, 394.0243902439024, 69.06143344709898, 390.1327913279133, 64.19795221843003,
      378.4579945799458, 55.443686006825935, 395.9701897018971, 50.580204778157, 431.9674796747968,
      49.6075085324232, 493.260162601626, 74.89761092150171, 516.609756097561, 273.3276450511945,
      544.8238482384824, 357.9522184300341, 549.6883468834687, 459.1126279863481, 546.7696476964769,
      469.81228668941975, 543.8509485094851, 496.0750853242321, 467.9647696476965,
      510.6655290102389, 426.130081300813,
    ],
  },
  {
    id: '8',
    title: 'Left Fender',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      473.7030716723549, 348.29810298102984, 458.1399317406144, 393.05149051490514,
      520.3924914675767, 389.159891598916, 530.1194539249146, 389.159891598916, 556.3822525597269,
      373.5934959349593, 576.8088737201366, 369.7018970189701, 595.2901023890785,
      372.62059620596204, 608.9078498293516, 381.37669376693765, 620.5802047781569,
      393.05149051490514, 631.2798634812286, 412.50948509485096, 639.061433447099,
      443.64227642276427, 640.0341296928328, 473.8021680216802, 636.1433447098976,
      503.96205962059616, 632.2525597269625, 517.5826558265583, 654.6245733788395,
      513.6910569105692, 662.4061433447099, 423.2113821138212, 661.4334470989761, 360.9457994579946,
      661.4334470989761, 334.67750677506774, 657.542662116041, 310.3550135501355, 646.8430034129693,
      287.97831978319783, 647.8156996587031, 264.62872628726285, 629.3344709897611,
      257.81842818428186, 609.8805460750854, 267.54742547425474, 601.1262798634812,
      306.4634146341463, 544.7098976109214, 324.9485094850948,
    ],
  },
  {
    id: '9',
    title: 'Front Left Door',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      840.4095563139931, 286.0325203252033, 799.5563139931741, 290.8970189701897, 799.5563139931741,
      313.27371273712737, 841.382252559727, 310.3550135501355, 843.3276450511945, 353.1626016260163,
      835.5460750853242, 418.3468834688347, 837.4914675767918, 435.85907859078594,
      835.5460750853242, 455.3170731707317, 660.4607508532423, 488.39566395663957, 666.296928327645,
      415.4281842818428, 664.3515358361774, 373.5934959349593, 665.3242320819113, 337.5962059620596,
      657.542662116041, 306.4634146341463, 649.7610921501707, 283.1138211382114, 757.7303754266211,
      269.49322493224935, 830.6825938566553, 259.76422764227647,
    ],
  },
  {
    id: '10',
    title: 'Front left door handle',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      801.5017064846417, 291.869918699187, 801.5017064846417, 309.3821138211382, 839.4368600682594,
      309.3821138211382, 841.382252559727, 287.97831978319783,
    ],
  },
  {
    id: '11',
    title: 'Front left side mirror',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      678.9419795221843, 247.11653116531164, 669.2150170648465, 247.11653116531164,
      657.542662116041, 257.81842818428186, 650.7337883959044, 275.33062330623306,
      663.3788395904437, 280.1951219512195, 680.8873720136519, 278.24932249322495,
      688.6689419795222, 273.38482384823845, 713.9590443686006, 270.4661246612466,
      736.3310580204778, 266.57452574525746, 755.7849829351535, 265.6016260162602,
      760.6484641638225, 256.8455284552846, 760.6484641638225, 247.11653116531164, 753.839590443686,
      237.38753387533873, 738.2764505119454, 233.49593495934963, 722.7133105802048,
      230.57723577235774, 710.0682593856656, 229.60433604336043, 693.5324232081912,
      234.46883468834687,
    ],
  },
  {
    id: '12',
    title: 'Front left window pane',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      790.8020477815699, 140.09756097560975, 753.839590443686, 146.90785907859077,
      718.8225255972696, 162.4742547425474, 700.3412969283277, 190.68834688346882,
      677.9692832764505, 218.90243902439025, 665.3242320819113, 241.27913279132792,
      658.5153583617747, 251.98102981029808, 676.0238907849829, 245.1707317073171,
      696.4505119453925, 231.550135501355, 719.7952218430034, 227.65853658536585, 742.1672354948805,
      232.5230352303523, 759.6757679180887, 239.33333333333337, 763.5665529010238,
      250.03523035230353, 761.6211604095563, 261.71002710027096, 809.283276450512,
      253.92682926829272, 805.3924914675767, 200.41734417344176,
    ],
  },
  {
    id: '13',
    title: 'Rear left door',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      985.3412969283276, 266.57452574525746, 947.4061433447098, 269.49322493224935,
      942.542662116041, 287.00542005420056, 953.2423208191126, 295.76151761517616,
      972.6962457337885, 291.869918699187, 982.4232081911264, 287.97831978319783, 970.7508532423209,
      335.6504065040651, 949.3515358361775, 397.9159891598916, 932.8156996587031, 436.8319783197832,
      840.4095563139931, 453.37127371273715, 840.4095563139931, 432.94037940379405,
      840.4095563139931, 415.4281842818428, 847.2184300341297, 357.05420054200545,
      843.3276450511945, 284.08672086720867, 832.6279863481228, 257.81842818428186,
      958.1058020477815, 239.33333333333337, 963.9419795221843, 197.49864498644988,
      971.7235494880546, 205.28184281842817,
    ],
  },
  {
    id: '14',
    title: 'Rear left door handle',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      986.3139931740615, 268.520325203252, 950.3242320819113, 273.38482384823845, 946.4334470989762,
      285.059620596206, 955.1877133105801, 290.8970189701897, 982.4232081911264, 287.97831978319783,
    ],
  },
  {
    id: '15',
    title: 'Rear left window pane',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      960.0511945392492, 193.6070460704607, 955.1877133105801, 234.46883468834687,
      838.4641638225255, 251.98102981029808, 810.2559726962457, 143.01626016260164,
      845.2730375426622, 143.01626016260164, 877.3720136518772, 147.88075880758808,
      903.6348122866893, 154.6910569105691, 931.8430034129693, 173.1761517615176,
    ],
  },
  {
    id: '16',
    title: 'Side skirt left',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      644.8976109215016, 491.31436314363145, 639.061433447099, 516.609756097561, 942.542662116041,
      460.18157181571814, 951.296928327645, 434.8861788617886,
    ],
  },
  {
    id: '17',
    title: 'Front left wheel arch',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      631.2798634812286, 516.609756097561, 637.1160409556314, 518.5555555555557, 646.8430034129693,
      442.66937669376694, 635.1706484641638, 393.05149051490514, 607.9351535836178,
      358.0271002710027, 578.7542662116042, 350.2439024390244, 552.4914675767918,
      352.18970189701895, 528.1740614334472, 369.7018970189701, 515.5290102389079,
      385.26829268292687, 503.85665529010237, 410.56368563685635, 493.15699658703073,
      432.94037940379405, 488.29351535836184, 459.20867208672087, 479.53924914675764,
      480.61246612466124, 475.6484641638225, 500.07046070460706, 470.78498293515355,
      516.609756097561, 469.81228668941975, 529.2574525745257, 473.7030716723549, 535.0948509485095,
      476.6211604095563, 524.3929539295393, 482.4573378839591, 503.96205962059616,
      490.23890784982933, 478.66666666666674, 497.0477815699659, 455.3170731707317,
      504.8293515358362, 438.7777777777778, 513.5836177474403, 417.3739837398374, 527.2013651877133,
      398.8888888888889, 536.9283276450512, 381.37669376693765, 554.4368600682593,
      372.62059620596204, 572.9180887372014, 369.7018970189701, 594.3174061433447,
      372.62059620596204, 615.716723549488, 386.2411924119241, 628.3617747440272, 407.6449864498645,
      633.2252559726962, 433.9132791327913, 634.19795221843, 452.3983739837399, 633.2252559726962,
      474.77506775067747, 632.2525597269625, 504.9349593495935, 636.1433447098976,
      519.5284552845529,
    ],
  },
  {
    id: '18',
    title: 'Rear left wheel arch',
    shape: 'poly',
    name: '3',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      944.4880546075085, 464.0731707317073, 951.296928327645, 462.12737127371275, 963.9419795221843,
      408.6178861788618, 975.6143344709898, 370.6747967479675, 987.2866894197953, 346.3523035230352,
      1012.5767918088736, 331.7588075880759, 1032.0307167235494, 333.70460704607046,
      1043.703071672355, 349.27100271002706, 1049.5392491467576, 366.78319783197827,
      1050.5119453924915, 397.9159891598916, 1046.6211604095563, 420.29268292682934,
      1042.730375426621, 432.94037940379405, 1048.566552901024, 431.9674796747968,
      1055.3754266211604, 406.6720867208672, 1056.3481228668943, 389.159891598916,
      1056.3481228668943, 368.7289972899729, 1055.3754266211604, 352.18970189701895,
      1049.5392491467576, 338.5691056910569, 1036.8941979522185, 325.92140921409214,
      1023.2764505119454, 317.16531165311653, 1008.6860068259385, 318.13821138211387,
      993.122866894198, 327.8672086720867, 980.4778156996587, 341.4878048780488, 972.6962457337885,
      358.0271002710027, 961.9965870307167, 379.4308943089431, 956.160409556314, 401.80758807588074,
      950.3242320819113, 424.18428184281845, 945.4607508532423, 446.5609756097561,
    ],
  },
]

export const carPartsBackRight = [
  {
    id: '469f9800-c45a-483f-b13e-bd24f3fb79f4',
    title: 'Rear Window',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      331.68941979522185, 146.90785907859077, 239.28327645051195, 198.47154471544715,
      182.8668941979522, 232.5230352303523, 249.9829351535836, 228.63143631436316, 303.481228668942,
      232.5230352303523, 388.1058020477816, 241.27913279132792, 438.6860068259386,
      245.1707317073171, 453.27645051194537, 249.0623306233062, 465.92150170648466,
      251.98102981029808, 574.863481228669, 160.52845528455285, 518.4470989761093,
      148.85365853658536, 428.9590443686007, 143.98915989159892,
    ],
  },
]

export const carPartsInterior = [
  {
    id: '1',
    title: 'Steering Wheel',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      206.99999999999997, 67, 196, 94, 196, 130, 214, 159, 231.99999999999997, 179, 258, 192, 302,
      193, 336, 167, 342, 122, 332, 85, 309, 62, 275, 47, 238, 48,
    ],
  },
  {
    id: '2',
    title: 'Rear Right Seat + Headrest',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      463, 480, 480.99999999999994, 668, 487, 699, 512, 795, 800, 776, 794, 754, 693, 674, 667, 679,
      675, 641, 660, 525, 632, 486.00000000000006,
    ],
  },
  {
    id: '3',
    title: 'Rear Center Seat',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      339, 480.99999999999994, 334, 536, 332, 608, 325, 669, 298, 731, 312, 761, 306, 793, 512, 795,
      496, 725, 476.99999999999994, 673, 468, 623, 461.00000000000006, 518, 454, 487,
    ],
  },
  {
    id: '4',
    title: 'Side Door Panel (Rear-Left)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      168, 425, 176, 449, 163, 475, 154, 496, 136, 547, 128, 587, 123, 616, 123, 640, 127, 659, 53,
      707, 7.000000000000001, 754, 4, 696, 2, 628, 4, 570, 2, 518, 1, 498.00000000000006,
      55.00000000000001, 307, 68, 260, 78, 242, 102, 301,
    ],
  },
  {
    id: '5',
    title: 'Side Door Panel (Rear-Right)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      721, 241, 702, 304, 673, 352, 637, 413.99999999999994, 631, 426, 642, 480.99999999999994, 659,
      514, 672, 563, 680, 609, 678, 639, 677, 658, 762, 714, 796, 750, 799, 665, 800, 593, 798, 515,
      799, 486.00000000000006,
    ],
  },
  {
    id: '6',
    title: "Driver's Seat + Headrest",
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      159, 178, 139, 206, 145, 250.99999999999997, 149, 283, 129, 293, 123, 333, 151, 372, 188, 394,
      231.99999999999997, 399, 287, 397, 334, 398, 356, 394, 360, 379, 367, 347, 369, 315, 364, 293,
      365, 265, 360, 234, 300, 220.00000000000003, 270, 221, 250, 194, 236, 180,
    ],
  },
  {
    id: '7',
    title: "Passenger's Seat + Headrest",
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      565, 182, 540, 206.99999999999997, 533, 222.00000000000003, 501, 216, 467, 225, 449, 229, 438,
      244, 433, 284, 436.00000000000006, 298, 432, 326, 434, 370, 443, 389, 454, 397,
      498.00000000000006, 400, 576, 399, 602, 400, 624, 396, 644, 369, 671, 343, 676, 311, 676, 295,
      649, 280, 654, 245.00000000000003, 658, 211, 653, 185,
    ],
  },
  {
    id: '8',
    title: 'Rear Center Console',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      368, 376, 371, 407, 369, 447, 361, 478, 401.99999999999994, 482, 441, 480, 433, 437, 429, 396,
      430, 375,
    ],
  },
  {
    id: '9',
    title: 'Front Center Console, Gearshift, Handbrake',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      362, 163, 375, 233, 375, 276, 372, 340, 370, 375, 430, 376, 430.99999999999994, 328, 430, 281,
      426, 239, 440.00000000000006, 170, 440.00000000000006, 166,
    ],
  },
  {
    id: '10',
    title: 'Side Door Panel (Front-Left)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      158, 27, 146, 41, 146, 55.00000000000001, 92, 193, 102, 239, 134, 282, 152, 281, 138,
      225.99999999999997, 143, 189, 152, 179, 165, 173, 185, 174, 183, 112.00000000000001, 169, 99,
      172, 86, 168, 61,
    ],
  },
  {
    id: '11',
    title: 'Side Door Panel (Front-Right)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      643, 24, 636, 28.999999999999996, 634, 59, 629, 91, 609, 132, 586, 154, 583, 171, 589, 178,
      629, 177, 650, 180, 663, 199, 662, 227.99999999999997, 657, 266, 656, 279, 674, 290, 698, 250,
      709, 204,
    ],
  },
  {
    id: '12',
    title: 'Radio / Multimedia / Navigation',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [350, 68, 346, 81, 358, 160, 433, 163, 451.99999999999994, 80, 449, 66],
  },
  {
    id: '13',
    title: 'Dashboard',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      170, 51, 174, 88, 200, 87, 221, 56.99999999999999, 265, 44, 311, 59, 332, 78, 340, 98, 350,
      87, 352, 69, 455, 65, 455.99999999999994, 83, 446, 135, 447, 146, 455, 140, 475, 95, 523, 91,
      585, 89, 624, 88, 632, 56.00000000000001, 632, 28.999999999999996, 592, 5, 465.00000000000006,
      5, 455.99999999999994, 20, 346, 23, 339, 1, 173, 1,
    ],
  },
  {
    id: '14',
    title: 'Rearview Mirror',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [345, 2, 347, 21, 454, 20, 455.99999999999994, 3],
  },
  {
    id: '15',
    title: 'Floor / Carpet (Back-Left)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [185, 399, 171, 475, 246, 474, 334, 475, 354, 478, 355, 405],
  },
  {
    id: '16',
    title: 'Floor / Carpet (Back-Right)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      447, 398, 446, 447, 450, 476, 566, 473.00000000000006, 629, 476.99999999999994, 612, 401,
    ],
  },
  {
    id: '17',
    title: 'Rear Left Seat + Headrest',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      166, 484, 135, 538, 121, 623, 130, 671, 4, 771, 299, 796, 313, 756, 289, 726, 315, 680, 331,
      482,
    ],
  },
  {
    id: '18',
    title: 'Floor / Carpet (Front-Left)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      355, 162, 359, 225, 286, 215, 262, 200, 231.99999999999997, 179, 195, 179, 200.99999999999997,
      153, 212, 164, 245.00000000000003, 182, 276, 197, 293, 197, 337, 167, 343, 155,
    ],
  },
  {
    id: '19',
    title: 'Floor / Carpet (Front-Right)',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      463.99999999999994, 156, 451, 195, 448.00000000000006, 221, 505.99999999999994,
      220.00000000000003, 529, 215, 558, 183, 565, 162, 558, 146,
    ],
  },
  {
    id: '20',
    title: 'Left Headliner',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [98, 2, 5, 254.99999999999997, 1, 208, 2, 3],
  },
  {
    id: '21',
    title: 'Right Headliner',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [682, 4, 762, 170, 797, 257, 799, 4],
  },
  {
    id: '22',
    title: 'Glove Box',
    shape: 'poly',
    name: '1',
    fillColor: fillColor,
    strokeColor: strokeColor,
    coords: [
      470, 112.00000000000001, 469.00000000000006, 127, 529, 129, 599, 128, 612, 97, 554, 96, 500,
      96, 484, 98,
    ],
  },
]
