import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import BoxShadowed from '../../../ui/Layout/BoxShadowed'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import ControlPointSubBlock from '../AppraisalBlock/ControlPointSubBlock'

const AppraisalMechanicalConditions = ({ readonly, hideGaps }) => {
  const testDrive = useSelector((state) => state.car.appraisal.mechanicalConditions?.testDrive)
  const visualCheck = useSelector((state) => state.car.appraisal.mechanicalConditions?.visualCheck)

  return (
    <BoxShadowed
      className={hideGaps ? 'hide-gaps' : ''}
      sx={{
        mt: '30px',
        '&.hide-gaps': {
          mt: 0,
          boxShadow: 'none',
        },
      }}
    >
      <Stack gap="30px">
        <Stack gap="20px">
          <UITextTitleSmall text="Visual check" sx={{ textAlign: 'center' }} />
          <Stack gap="8px">
            {visualCheck.map((item) => {
              return <ControlPointSubBlock key={item?.['@id']} data={item} readonly={!!readonly} />
            })}
          </Stack>
        </Stack>
        <Stack gap="20px">
          <UITextTitleSmall text="Test drive" sx={{ textAlign: 'center' }} />
          <Stack gap="8px">
            {testDrive.map((item) => {
              return <ControlPointSubBlock key={item?.['@id']} data={item} readonly={!!readonly} />
            })}
          </Stack>
        </Stack>
      </Stack>
    </BoxShadowed>
  )
}

export default AppraisalMechanicalConditions
