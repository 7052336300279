export const FONT = {
  PRIMARY: '"Helvetica", sans-serif',
}

export const UISize = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
}

export const UIVariant = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  attention: 'attention',
  danger: 'danger',
}
