import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { Close, MoreHoriz } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { privateRequest } from '../../utils/request'
import { toast } from 'react-toastify'
import StartAuctionModal from '../CarPage/StartAuctionModal'
import SubmitToQualityCheckConfirmModal from './SubmitToQualityCheckConfirmModal'
import { API_ENDPOINTS } from '../../config/default'
import ApplyForQualityCheckModal from '../../components/Modals/ApplyForQualityCheckModal'
import { useSelector, useDispatch } from 'react-redux'
import { setBalance } from '../../store/features/authSlice'
import AnimationSendToCompoundModal from '../../components/Modals/AnimationSendToCompoundModal'
import SendToCompoundModal from '../SendToCompound/SendToCompoundModal'
import { useTranslation } from 'react-i18next'
import RequestCoinsModal from '../../components/Modals/RequestCoinsModal'

const CarMiniAction = (props) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [openActionList, setOpenActionList] = useState(false)
  const [firstOpen, setFirstOpen] = useState(false)

  const { cell, carId, cars, setCars, getActionList, auctionCarId, car } = props

  const [startAuctionModal, setStartAuctionModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('')

  const [qualityCheckModal, setQualityCheckModal] = useState(false)
  const [qualityCheckFailedModal, setQualityCheckFailedModal] = useState(false)
  const [notEnoughCoinsModal, setNotEnoughCoinsModal] = useState(false)

  const [sendToCompoundModal, setSendToCompoundModal] = useState(false)
  const [animationModal, setAnimationModal] = useState(false)

  const actionList = useRef()

  const handleAction = (action) => {
    action.actions.forEach((item) => {
      switch (item.type) {
        case 'navigate':
          navigate(item.link.replace(':id', carId))
          break
        case 'navigate-auction-car':
          navigate(item.link.replace(':id', auctionCarId))
          break
        case 'workflowTransition':
          privateRequest.post(item.link.replace(':id', carId), {})
          break
        case 'extendedWorkflowTransition':
          privateRequest.post(item.link.replace(':id', carId), {}).then(() => {
            toast.success('The car has been submitted to quality check')
            setCars(
              cars.map((carItem) => {
                if (carItem.id === carId) {
                  carItem.cells[5].value = item.newStatus
                  carItem.cells[6].value = getActionList('car_workflow.quality_check', false)
                }
                return carItem
              }),
            )
          })
          break
        case 'extendedWorkflowTransitionMulti':
          privateRequest.post(item.link.replace(':id', carId), {}).then(() => {
            privateRequest.post(item.link2.replace(':id', carId), {}).then(() => {
              toast.success('The car has been submitted to quality check')
              setCars(
                cars.map((carItem) => {
                  if (carItem.id === carId) {
                    carItem.cells[5].value = item.newStatus
                    carItem.cells[6].value = getActionList('car_workflow.quality_check', false)
                  }
                  return carItem
                }),
              )
            })
          })
          break
        case 'archive':
          privateRequest.put(item.link.replace(':id', carId), {}).then(() => {
            setCars(
              cars.map((carItem) => {
                if (carItem.id === carId) {
                  carItem.cells[5].value = 'Archived'
                }
                return carItem
              }),
            )
          })
          break
        case 'modal':
          if (item.modalName === 'start_auction') {
            setCurrentStatus(item.status)
            setStartAuctionModal(true)
          }
          if (item.modalName === 'quality_check') {
            setQualityCheckModal(true)
          }
          if (item.modalName === 'quality_check_failed') {
            setQualityCheckFailedModal(true)
          }
          if (item.modalName === 'send_to_compound') {
            setSendToCompoundModal(true)
          }
          break
      }
    })
    setOpenActionList(false)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenActionList(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleSubmitToQualityCheck = () => {
    privateRequest
      .post(`/cars/${carId}${API_ENDPOINTS.completeOnSiteAppraisal}`, {})
      .then(() => {
        toast.success('The car has been submitted to quality check')
        privateRequest.get(API_ENDPOINTS.userInfo).then((res) => {
          dispatch(setBalance(res.data.manager.company.coinBalance.balance))
        })
        setCars(
          cars.map((carItem) => {
            if (carItem.id === carId) {
              carItem.cells[5].value = 'Quality Check'
              carItem.cells[6].value = getActionList('car_workflow.quality_check', false)
            }
            return carItem
          }),
        )
      })
      .catch((error) => {
        if (error.response.data['hydra:description'] === 'Not enough coins for withdraw') {
          setQualityCheckModal(false)
          setNotEnoughCoinsModal(true)
        }
      })
  }

  const handleSubmitToQualityCheckFailed = () => {
    privateRequest.post(`/cars/${carId}${API_ENDPOINTS.startOnSiteAppraisal}`, {}).then(() => {
      privateRequest.post(`/cars/${carId}${API_ENDPOINTS.completeOnSiteAppraisal}`, {}).then(() => {
        toast.success('The car has been submitted to quality check')
        setCars(
          cars.map((carItem) => {
            if (carItem.id === carId) {
              carItem.cells[5].value = 'Quality Check'
              carItem.cells[6].value = getActionList('car_workflow.quality_check', false)
            }
            return carItem
          }),
        )
      })
    })
  }

  const handleSendToCompound = (formData) => {
    privateRequest
      .post(`/cars/${carId}${API_ENDPOINTS.requestCompound}`, formData)
      .then(() => {
        setSendToCompoundModal(false)
        setAnimationModal(true)
        setTimeout(() => {
          setAnimationModal(false)
        }, 3000)
      })
      .catch(() => {
        toast.error('An error occurred while applying for pickup')
      })
  }

  useOutsideAlerter(actionList)

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: openActionList ? 3 : 2,
      }}
    >
      {openActionList && cell.value.length > 0 ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            background: colorsConfig.bgLightBlue,
            minWidth: '200px',
            paddingTop: '30px',
            paddingBottom: '10px',
            zIndex: 200,
          }}
          ref={actionList}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 10,
            }}
            onClick={() => {
              setOpenActionList(false)
            }}
          >
            <Close
              sx={{
                fontSize: '18px',
                color: colorsConfig.mainRed,
              }}
            />
          </IconButton>
          {cell.value.map((action, index) => {
            return (
              <Button
                fullWidth
                key={index}
                sx={{
                  textTransform: 'none',
                  background: colorsConfig.bgLightBlue,
                  transition: '0.25s all ease-in-out',
                  color: colorsConfig.mainBlack,
                  fontSize: '16px',
                  fontWeight: 400,
                  textAlign: 'left',
                  px: '30px',
                  whiteSpace: 'nowrap',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    background: 'white',
                    transition: '0.25s all ease-in-out',
                  },
                }}
                onClick={() => {
                  handleAction(action)
                }}
              >
                {action.translation ? t(action.translation) : action.title}
              </Button>
            )
          })}
        </Box>
      ) : null}

      <IconButton
        onClick={() => {
          if (!openActionList && !firstOpen) {
            setFirstOpen(true)
          }
          setOpenActionList(!openActionList)
        }}
        sx={{
          pointerEvents: cell.value.length > 0 ? 'auto' : 'none',
        }}
      >
        <MoreHoriz
          sx={{
            fontSize: '35px',
            color: cell.value.length > 0 ? colorsConfig.mainBlue : colorsConfig.lightGray,
            fill: cell.value.length > 0 ? colorsConfig.mainBlue : colorsConfig.lightGray,
          }}
        />
      </IconButton>
      {firstOpen ? (
        <StartAuctionModal
          carId={carId}
          startAuctionModal={startAuctionModal}
          setStartAuctionModal={setStartAuctionModal}
          fromVirtualGarage={true}
          refreshData={{
            setCars: setCars,
            cars: cars,
            getActionList: getActionList,
            currentStatus: currentStatus,
            auctionCarId: auctionCarId,
          }}
        />
      ) : null}
      <ApplyForQualityCheckModal
        open={qualityCheckModal}
        setOpen={setQualityCheckModal}
        handleSubmit={() => {
          handleSubmitToQualityCheck()
        }}
      />
      <RequestCoinsModal
        open={notEnoughCoinsModal}
        setOpen={setNotEnoughCoinsModal}
        title={'Not enough Fleetify Coins'}
        description={'Purchase Fleetify Coins in order to use that service'}
      />
      <SubmitToQualityCheckConfirmModal
        open={qualityCheckFailedModal}
        setOpen={setQualityCheckFailedModal}
        handleSubmit={() => {
          handleSubmitToQualityCheckFailed()
        }}
      />
      {sendToCompoundModal ? (
        <SendToCompoundModal
          open={sendToCompoundModal}
          setOpen={setSendToCompoundModal}
          fromVirtualGarage={true}
          car={car}
          onFinish={(requestCompleted) => {
            if (requestCompleted) {
              setCars(
                cars.map((carItem) => {
                  if (carItem.id === carId) {
                    carItem.cells[5].value = 'Request for appraisal on compound'
                    carItem.cells[6].value = getActionList(
                      'car_workflow.requested_appraisal_compound',
                      false,
                    )
                  }
                  return carItem
                }),
              )
            }
            setSendToCompoundModal(false)
          }}
        />
      ) : null}
      <AnimationSendToCompoundModal open={animationModal} setOpen={setAnimationModal} />
    </Box>
  )
}

export default CarMiniAction
