import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import VehicleDetailsSection from '../CarPage/CarPageSections/VehicleDetailsSection'
import DocumentsSection from '../CarPage/CarPageSections/DocumentsSection'
import PhotosSection from '../CarPage/CarPageSections/PhotosSection'
import DamagesSection from '../CarPage/CarPageSections/DamagesSection'
import WarningLights from '../Appraisal/WarningLights/WarningLights'
import { privateRequest } from '../../utils/request'
import {
  getCarParts,
  getControlPoints,
  getForeshortenings,
  getPilotLamps,
} from '../Appraisal/getAppraisalReferences'
import { setReferences } from '../../store/features/appraisalReferencesSlices'
import {
  resetCar,
  setAppraisal,
  setCarData,
  setControlPoints,
  setDamages,
  setPhotos,
  setPilotLamps,
} from '../../store/features/carSlice/carSlice'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import { Box, Tab, Tabs, Typography, Grid, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import AppraisalBlock from '../Appraisal/AppraisalBlock'
import Preloader from '../../components/Preloader'
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent'
import IconVirtualGarage from '../../assests/img/icons/icon-virtual-garage-home.svg'
import CarDefaultPicture from '../../assests/img/car-not-found.png'
import IconCarStatus from '../../assests/img/icons/icon-car-status.svg'
import TextNormal from '../../ui/Text/TextNormal'
import TextBoldNormal from '../../ui/Text/TextBoldNormal'
import { sumDelimiter } from '../../utils/sumDelimiter'
import ButtonHollowRegular from '../../ui/Buttons/ButtonHollowRegular'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import IconApple from '../../assests/img/icons/icon-apple-green.svg'
import CarPageTabs from '../CarPage/CarPageTabs'

const PurchasedCarPage = () => {
  const navigate = useNavigate()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  const [loading, setLoading] = useState(true)
  const [blocks, setBlocks] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const sections = [
    {
      id: 1,
      name: 'Vehicle Details',
      component: <VehicleDetailsSection />,
    },
    {
      id: 2,
      name: 'Documents',
      component: <DocumentsSection />,
    },
    {
      id: 3,
      name: 'Photos',
      component: <PhotosSection />,
    },
    {
      id: 4,
      name: 'Damages',
      component: <DamagesSection />,
    },
    {
      id: 5,
      name: 'Warning Lights',
      component: <WarningLights readonly={true} />,
    },
    {
      id: 6,
      name: 'Interior Conditions',
      component: blocks[1] ? blocks[1].component : <></>,
    },
    {
      id: 7,
      name: 'Mechanical Conditions',
      component: blocks[2] ? blocks[2].component : <></>,
    },
  ]

  const [car, setCar] = useState(null)
  const [carTitle, setCarTitle] = useState('')
  const [tab, setTab] = useState(0)

  const [currentWorkflow, setCurrentWorkflow] = useState(null)
  const [currentWorkflowSysName, setCurrentWorkflowSysName] = useState('')
  const [dealOpened, setDealOpened] = useState('')
  const [dealAmount, setDealAmount] = useState(0)
  const [deal, setDeal] = useState('')
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)
  const workflowsLoaded = useSelector((state) => state.workflows.loaded)
  const carData = useSelector((state) => state.car.carData)
  const appraisalReferences = useSelector((state) => state.references)
  const combinedStatus = useSelector((state) => state.car.carData.combinedStatus)

  const dispatch = useDispatch()

  const { id } = useParams()

  function compare(a, b) {
    if (a.direct < b.direct) {
      return -1
    }
    if (a.direct > b.direct) {
      return 1
    }
    return 0
  }

  const getReferences = () => {
    const referencePromises = [
      getForeshortenings(),
      getCarParts(),
      getPilotLamps(),
      getControlPoints(),
    ]
    Promise.all(referencePromises).then((result) => {
      dispatch(setReferences(result))
    })
  }

  useEffect(() => {
    getCar()
    getCarAppraisal()
    return () => {
      dispatch(resetCar())
    }
  }, [])

  useEffect(() => {
    if (!appraisalReferences.loaded) {
      getReferences()
    }
  }, [appraisalReferences.loaded])

  useEffect(() => {
    if (appraisalReferences.loaded && carData.loaded) {
      setControlPointsBlocks()
      dispatch(
        setDamages({
          carDamages: carData.damages,
          carParts: appraisalReferences.carParts,
        }),
      )
      dispatch(setPilotLamps(appraisalReferences.pilotLamps))
      dispatch(setControlPoints(appraisalReferences.controlPoints))
      dispatch(setPhotos(appraisalReferences.foreshortenings))
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
  }, [appraisalReferences.loaded, carData])

  useEffect(() => {
    if (car && workflowsLoaded && combinedStatus) {
      let findWorkflow
      if (combinedStatus.includes('car_workflow')) {
        findWorkflow = carWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('auction_workflow')) {
        findWorkflow = auctionWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
        )[0]
      }
      if (combinedStatus.includes('deal_workflow')) {
        findWorkflow = dealWorkflows.filter(
          (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
        )[0]
      }

      if (combinedStatus === 'car_workflow.sale_succeed') {
        setCurrentWorkflow('Purchased vehicle')
        return
      }

      if (findWorkflow) {
        setCurrentWorkflow(findWorkflow.value)
      }
    }
  }, [car, workflowsLoaded, combinedStatus])

  const getFormattedDate = (date) => {
    const endTimeDate = new Date(date)
    const endYear = endTimeDate.getFullYear()
    let endMonth = endTimeDate.getMonth() + 1
    let endDay = endTimeDate.getDate()
    if (endDay < 10) endDay = '0' + endDay
    if (endMonth < 10) endMonth = '0' + endMonth
    const formattedEndDate = endDay + '.' + endMonth + '.' + endYear
    return formattedEndDate
  }

  const getCar = () => {
    privateRequest.get(`/deals/${id}`).then((response) => {
      setCar(response.data.car)
      dispatch(setCarData(response.data.car))
      setDeal(response.data['@id'])
      setCarTitle(`${response.data.car.make.name} ${response.data.car.model.name}`)
      setCurrentWorkflowSysName(response.data.car.currentWorkflowState)
      const createdAt = new Date(response.data.createdAt)
      const createdAtDay = createdAt.getDay() < 10 ? `0${createdAt.getDay()}` : createdAt.getDay()
      const createdAtMonth =
        createdAt.getMonth() < 10 ? `0${createdAt.getMonth()}` : createdAt.getMonth()
      const createdAtYear = createdAt.getFullYear()
      console.log(`${createdAtDay}.${createdAtMonth}.${createdAtYear}`)
      setDealOpened(getFormattedDate(response.data.createdAt))
      if (response.data.value) {
        setDealAmount(response.data.value)
      }
    })
  }

  const getCarAppraisal = () => {
    privateRequest.get(`/car-appraisal-aggregate/deals/${id}`).then((response) => {
      dispatch(
        setAppraisal({
          foreshortenings: response.data.foreshortenings.sort(compare),
          damages: response.data.damages,
          pilotLamps: response.data.pilotLamps,
          controlPoints: response.data.controlPoints,
        }),
      )
    })
  }

  const setControlPointsBlocks = () => {
    const newBlocks = appraisalReferences.controlPoints.map((blockItem, index) => {
      return {
        id: index === 0 ? 3 : index + 5,
        title: blockItem.name,
        component: (
          <AppraisalBlock key={index === 0 ? 3 : index + 5} blockIndex={index} readonly={true} />
        ),
      }
    })
    setBlocks(newBlocks)
  }

  const handleObtainCar = () => {
    setButtonDisabled(true)
    privateRequest.post(`${deal}${API_ENDPOINTS.addPurchasedCar}`, {}).then((response) => {
      toast.success('Congratulations! Your new car is now in your virtual garage')
      navigate(response.data['@id'])
    })
  }

  return (
    <Box
      sx={{
        height: loading ? 'calc(100vh - 102px)' : 'auto',
        position: 'relative',
      }}
    >
      {loading ? (
        <Preloader type="gooey" text={'Loading the car...'} />
      ) : (
        <Box
          className={xsOnly ? 'xs-only' : ''}
          sx={{
            '&.xs-only': {
              px: '16px',
              maxWidth: '100vw',
              pb: '30px',
            },
          }}
        >
          <Box
            sx={{
              pt: {
                xs: '20px',
                sm: '30px',
              },
            }}
          >
            <BreadcrumbsComponent
              currentPage={{ name: carTitle }}
              parentPages={[
                {
                  name: 'Virtual Garage',
                  icon: <img src={IconVirtualGarage} alt="" />,
                  link: '/virtual-garage',
                },
              ]}
            />
          </Box>
          <Box
            className={xsOnly ? 'xs-only' : ''}
            sx={{
              mt: '13px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '&.xs-only': {
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              },
            }}
          >
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                width: '118px',
                height: '80px',
                mr: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                overflow: 'hidden',
                '&.xs-only': {
                  mr: '10px',
                },
              }}
            >
              {carData.mainPhoto && carData.mainPhoto.photo ? (
                <img
                  src={`${CDN_BASE_URL}${carData.mainPhoto.photo.path}`}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <img
                  src={CarDefaultPicture}
                  alt=""
                  style={{
                    width: '80%',
                  }}
                />
              )}
            </Box>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                mr: '15px',
                '&.xs-only': {
                  width: 'calc(100% - 128px)',
                  mr: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={xsOnly ? 'xs-only' : ''}
                  sx={{
                    fontWeight: 700,
                    color: colorsConfig.mainBlack,
                    fontSize: '40px',
                    lineHeight: 'normal',
                    '&.xs-only': {
                      fontSize: '24px',
                    },
                  }}
                >
                  #{carData.referenceNumber} {carTitle}
                </Typography>
                {carData.carMainSpecs?.engineType === 'electric' ||
                carData.carMainSpecs?.engineType === 'hybrid' ? (
                  <Box
                    sx={{
                      ml: '5px',
                      width: '28px',
                      position: 'relative',
                      mb: '-2.5px',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={IconApple}
                      alt=""
                    />
                    <TextNormal
                      sx={{
                        fontSize: '8px',
                        fontWeight: 700,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      ECO
                    </TextNormal>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              className={xsOnly ? 'xs-only' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '48px',
                borderRadius: '6px',
                border: '1px solid',
                borderColor: carData.isArchived
                  ? colorsConfig.buttonDisabled
                  : colorsConfig.mainGreen,
                paddingLeft: '44px',
                paddingRight: '44px',
                cursor: 'pointer',
                '&.xs-only': {
                  width: '100%',
                  mt: '20px',
                  px: 0,
                  py: 0,
                  height: '33px',
                },
              }}
            >
              <img
                src={IconCarStatus}
                style={{
                  marginRight: '10px',
                  filter: carData.isArchived ? 'brightness(20%)' : '',
                }}
                alt=""
              />
              <Typography
                sx={{
                  color: carData.isArchived ? colorsConfig.iconGray : colorsConfig.mainBlack,
                }}
              >
                {currentWorkflow}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: '30px',
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Box>
                  <TextNormal
                    sx={{
                      mb: '2px',
                    }}
                  >
                    Deal opened
                  </TextNormal>
                  <TextBoldNormal>{dealOpened}</TextBoldNormal>
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Box
                    sx={{
                      px: '14.47px',
                      py: '11.2px',
                      border: '1px solid',
                      borderColor: colorsConfig.mainBlack,
                      borderRadius: '4px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TextNormal sx={{ mr: '10px' }}>Deal Amount:</TextNormal>
                    <TextBoldNormal>
                      {sumDelimiter(dealAmount).replace("'", ' ')} AED
                    </TextBoldNormal>
                  </Box>
                </Box>
              </Grid>
              {combinedStatus === 'car_workflow.sale_succeed' ? (
                <Grid item xs={12}>
                  <Box>
                    <ButtonHollow
                      onClick={() => {
                        handleObtainCar()
                      }}
                      disabled={buttonDisabled}
                    >
                      Car received
                    </ButtonHollow>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
          <Box
            sx={{
              mt: '20px',
            }}
          >
            <Box
              sx={{
                mb: 2,
              }}
            >
              <CarPageTabs tab={tab} setTab={setTab} sections={sections} />
            </Box>
            {sections[tab].component}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PurchasedCarPage
