import React from 'react'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import TextFieldSmallEdible from '../../../ui/TextFieldSmallEdible'
import { colorsConfig } from '../../../config/themeConfig'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextBodySmall from '../../../ui/UIText/UITextBodySmall'

const FormInput = ({
  label,
  id,
  required,
  suffix,
  regExp,
  validateOnChange,
  maxLength,
  valueFormatter,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { t } = useTranslation()

  const handleChange = ({ target }) => {
    if (maxLength && target.value.length > maxLength) return
    if (regExp) {
      setValue(target.value.replace(regExp, ''))
      return
    }
    setValue(target.value)
  }

  return (
    <TextFieldSmallEdible
      fullWidth
      required={required}
      error={meta.error && meta.touched}
      label={t(label)}
      name={field.name}
      value={valueFormatter ? valueFormatter(field.value) : field.value}
      onChange={handleChange}
      helperText={meta.error && (meta.touched || validateOnChange) ? meta.error : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <UITextBodySmall sx={{ color: colorsConfig.lightGray }} text={t(suffix)} />
          </InputAdornment>
        ),
      }}
      // endAdornment={
      //   <InputAdornment position="end">
      //     <UITextRegular sx={{ color: colorsConfig.lightGray }} text={t(suffix)} />
      //   </InputAdornment>
      // }
      {...props}
    />
  )
}

export default FormInput
